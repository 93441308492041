import React, { Suspense } from 'react'
import Card from '../../../../../../components/Card'
import Body from './Body'
import List from './List'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import EditModel from './EditModal'
import useToggleState from 'src/hooks/useToggleState'

interface DetailsProps {
  id: string
}

const PositionDetails: React.FC<DetailsProps> = (props) => {
  const { id } = props
  const [isOpen, , handleToggle] = useToggleState()

  return (
    <Card
      title='Position / Distribution'
      primaryAction={{
        key: 'edit',
        icon: faPencil,
        onClick: handleToggle,
        permissionKey: 'CAT_EDIT'
      }}
    >
      <Suspense fallback={<List candidate={undefined} />}>
        <Body id={id} />
      </Suspense>
      <EditModel id={id} isOpen={isOpen} toggle={handleToggle} />
    </Card>
  )
}

export default PositionDetails
