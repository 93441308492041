import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query candidateDetailsById($id: MongoID!) {
    cat {
      candidate {
        byId(_id: $id) {
          _id
          location
          name {
            first
            last
          }
          email
          phone
          address {
            street
            city
            state
            zip
          }
          received_on
          type
          _type {
            name
          }
          clearance
          _clearance {
            name
          }
          reapply
          status
          _status {
            name
          }
          substatus
          _substatus {
            name
            color
          }
          rejection_date
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateDetailsById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['byId']>

const useCandidateDetailsById = (id: string): CandidateDetailsById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const candidate = data?.cat?.candidate?.byId ?? undefined
  return candidate
}

export { useCandidateDetailsById, document as candidateDetailsByIdDocument }
