import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React from 'react'
import { DatePicker, Dropdown } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { useCandidateRanks, useHiringManagers } from 'src/queries'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
  lookups?: {
    hiringManagers: ReturnType<typeof useHiringManagers>
    ranks: ReturnType<typeof useCandidateRanks>
  }
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave, lookups } = props
  const { handleSubmit } = useFormikContext()
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the candidate resume review.',
        show: hasError ?? false
      }}
      >
        <DatePicker name='resume.sent' label='Sent' />
        <DatePicker name='resume.reviewed' label='Reviewed' />
        <Dropdown name='resume.reviewed_by' label='Reviewed By' options={lookups?.hiringManagers ?? []} />
        <Dropdown name='rank' label='Rank' options={lookups?.ranks ?? []} />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
