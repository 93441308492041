import React, { useState } from 'react'

type UseToggleStateReturn = [boolean, React.Dispatch<React.SetStateAction<boolean>>, () => void]

const useToggleState = (defaultState?: boolean): UseToggleStateReturn => {
  const [state, setState] = useState<boolean>(defaultState ?? false)
  const handleToggle = (): void => setState(o => !o)

  return [
    state,
    setState,
    handleToggle
  ]
}

export default useToggleState
