import React, { useEffect } from 'react'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useCardContext } from 'src/components/Card/CardContext'
import { useOverviewState } from 'src/pages/cat/view/distributionoverview/useOverviewState'
import usePositions from 'src/pages/cat/view/distributionoverview/usePositions'

interface CardBodyProps {
  columns: ReturnType<typeof columns>
}

const Body: React.FC<CardBodyProps> = (props) => {
  const { columns } = props
  const { positionQueryEnabled } = useOverviewState()
  const positions = usePositions()
  const card = useCardContext()
  const count = positions.length
  useEffect(() => {
    if (positionQueryEnabled) {
      card.setTitle(`Positions (${count})`)
    }
  }, [count, positionQueryEnabled])
  if (!positionQueryEnabled) {
    return <h2 className='text-center m-5'>Please Select A Distribution Subcategory</h2>
  }
  return (
    <>
      <Table storageKey='cat-positions' options={{ data: positions, columns, initialState: { sorting: [{ id: 'status', desc: false }] } }} />
      {count === 0 && <h2 className='text-center m-5'>No Positions Found</h2>}
    </>
  )
}

export default Body
