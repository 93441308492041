import { Formik } from 'formik'
import React from 'react'
import {
  UseMutateCandidateByIdReturn,
  useMutateCandidateById,
  useCandidatePositionDetailsById,
  usePositions,
  useDistributionsByPositionId,
  candidatePositionDetailsByIdDocument,
  candidateRatesByIdDocument
} from 'src/queries'
import Form from './Form'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { MutateCandidateByIdMutation } from 'src/gql/graphql'
import { emptyStringToNull } from 'src/helpers/formHelpers'
import { isNumber, pick } from 'lodash'
import { DropdownOption } from 'src/components/Forms'

export type Values = Parameters<UseMutateCandidateByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const candidate = useCandidatePositionDetailsById(id)
  if (candidate === undefined) {
    throw new Error('Could not load candidate!')
  }
  const positions = usePositions()
  const positionLookup: DropdownOption[] = positions.map(p => {
    return { value: p._id, label: `(${p.id ?? ''}) ${p.title ?? ''} [${p._contract?.abbreviation ?? ''}]` }
  })
  useDistributionsByPositionId(candidate.position ?? undefined)
  const update = useMutateCandidateById()
  const initialState: Values = pick(candidate, [
    'position',
    'distribution'
  ])
  const invalidateCandidate = useInvalidateQueryByDocument(candidatePositionDetailsByIdDocument)
  const invalidateRates = useInvalidateQueryByDocument(candidateRatesByIdDocument)
  const handleSuccess = (data: MutateCandidateByIdMutation): void => {
    const id = data.cat?.candidate?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateCandidate({ id })
      void invalidateRates({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const record = { ...data }
        if (!isNumber(data.position)) {
          throw new Error('Invalid position value format.')
        }
        emptyStringToNull(record, 'distribution')

        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form positions={positionLookup} toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
