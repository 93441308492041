import { graphql } from '../../gql'
import { useInfiniteGraphQL } from '../../hooks/useInfinateGraphQL'
import { SearchQuery, SearchQueryVariables } from '../../gql/graphql'
import { UseInfiniteQueryResult } from '@tanstack/react-query'

const GlobalSearchDocument = graphql(/* graphql */`
  query Search($page: Int, $perPage: Int, $filter: GlobalSearchFilterArgs) {
    search(page: $page, perPage: $perPage, filter: $filter) {
      count
      items {
        _id
        badges {
          title
          value
        }
        category
        score
        title
        url
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`)

interface UseSearchDefaults {
  term: string
  perPage?: number
  page?: number
}

interface UseSearchReturnExtended {
  items: NonNullable<NonNullable<SearchQuery['search']>['items']>
  pages: SearchQuery[]
}

type UseSearchInfinateQuery = Omit<UseInfiniteQueryResult<SearchQuery, SearchQueryVariables>, 'data'>

export type UseSearchReturn = UseSearchReturnExtended & UseSearchInfinateQuery

const useSearch = (props: UseSearchDefaults): UseSearchReturn => {
  const { term, perPage, page } = props
  const {
    data,
    ...rest
  } = useInfiniteGraphQL(GlobalSearchDocument, {
    filter: { search: term },
    perPage,
    page
  },
  (variables) => {
    return { filter: variables?.filter, perPage: variables?.perPage }
  },
  {
    enabled: term !== null && term !== undefined && term !== '',
    getNextPageParam: (lastPage) => {
      const lastPageInfo = lastPage.search?.pageInfo
      if (lastPageInfo?.hasNextPage === true && lastPageInfo.currentPage !== undefined) {
        const next = {
          filter: { search: term },
          page: lastPageInfo?.currentPage + 1,
          perPage: lastPageInfo.perPage
        }
        return next
      }
      return undefined
    }
  })
  const items = data?.pages.flatMap(d => d.search?.items ?? []) ?? []

  return {
    items,
    pages: data?.pages ?? [],
    ...rest
  }
}

export default useSearch
