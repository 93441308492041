import { useQuery } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'

const useToken = (): string | undefined => {
  const token = useQuery(['apiToken'], async () => {
    const authorization = (await Auth.currentSession()).getAccessToken().getJwtToken()
    return authorization
  }, {
    refetchInterval: 15000,
    refetchIntervalInBackground: true
  })
  return token.data
}

export default useToken
