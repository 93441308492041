import React, { Suspense, useState } from 'react'
import Card from '../Card'
import { Skeleton, MenuActionProps } from './Attachment'
import List, { ListProps } from './List'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Create, Update, Remove } from './modals'

interface AttachmentsProps extends Omit<ListProps, keyof MenuActionProps> {
  title?: string
  model: string
  editPermissionKey?: string
}

const Attachments: React.FC<AttachmentsProps> = (props) => {
  const {
    title = 'Attachments',
    link,
    model,
    type,
    editPermissionKey
  } = props
  const [create, setCreate] = useState<boolean>(false)
  const [update, setUpdate] = useState<string | undefined>()
  const [remove, setRemove] = useState<{ id: string, filename: string } | undefined>()
  const toggleCreate = (): void => setCreate(o => !o)

  return (
    <Card
      title={title} primaryAction={{
        key: 'add-attachment',
        text: 'Add Attachment',
        icon: faPlus,
        onClick: toggleCreate,
        permissionKey: editPermissionKey
      }}
    >
      <Suspense fallback={<><Skeleton /><Skeleton /><Skeleton /></>}>
        <List {...props} onRemove={setRemove} onEdit={setUpdate} editPermissionKey={editPermissionKey} />
        <Create
          link={link}
          model={model}
          type={type}
          open={create}
          toggle={toggleCreate}
        />
        <Update
          id={update}
          toggle={() => setUpdate(undefined)}
          link={link}
          type={type}
        />
        <Remove
          attachment={remove}
          toggle={() => setRemove(undefined)}
          link={link}
          type={type}
        />
      </Suspense>
    </Card>
  )
}

export default Attachments
