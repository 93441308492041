import { isEmpty } from 'lodash'

interface FormatAddressProps {
  street?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
}

const FormatAddress: React.FC<FormatAddressProps> = (props) => {
  const address = props
  if (isEmpty(address)) {
    return null
  }
  const line1 = address.street
  const stateZip = [address.state, address.zip].filter(Boolean).join(' ')
  const line2 = [address.city, stateZip].filter(Boolean).join(', ')
  return (
    <>
      {line1}
      {line1 !== '' || line2 !== '' ? (<br />) : null}
      {line2}
    </>
  )
}

export default FormatAddress
