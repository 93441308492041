import React, { Suspense } from 'react'
import Card from 'src/components/Card'
import Body from './Body'

const UpdateDev: React.FC = (props) => {
  return (
    <>
      <Card
        title='Update Dev'
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Body />
        </Suspense>
      </Card>
    </>
  )
}

export default UpdateDev
