import { graphql } from 'src/gql'
import { SortFindManyHiring_ManagerInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query hiringManagers($sort: SortFindManyHiring_ManagerInput) {
    lookups {
      Hiring_Manager {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type HiringManagers = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Hiring_Manager']>['many']>

const useHiringManagers = useGraphQLDropdownOptions(
  document,
  'lookups.Hiring_Manager.many',
  { sort: SortFindManyHiring_ManagerInput.NameAsc }
)

export { useHiringManagers, document as hiringManagersDocument }
