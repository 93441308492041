import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { currency } from 'src/helpers/displayhelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { CandidateRatesById } from 'src/queries'

interface ListProps {
  candidate: CandidateRatesById | undefined
}

interface HigherWarningProps {
  candidate: number | undefined | null
  position: number | undefined | null
}

const HigherWarning: React.FC<HigherWarningProps> = (props) => {
  const candidate = Number(props.candidate)
  const position = Number(props.position)
  if (isNaN(candidate) || isNaN(position)) {
    return null
  }
  if (candidate > position) {
    return (
      <FontAwesomeIcon data-testid='high-rate-warning' className='ms-2 text-danger' icon={faTriangleExclamation} />
    )
  }
  return null
}

const List: React.FC<ListProps> = (props) => {
  const { candidate } = props
  const section1: Array<Omit<ItemProps, 'id'>> = [
    { title: 'Bill Rate (Min)', value: currency(candidate?.bill_rate?.min) },
    {
      title: 'Bill Rate (Max)',
      value: (
        <>
          {currency(candidate?.bill_rate?.max)}
          <HigherWarning
            candidate={candidate?.bill_rate?.max}
            position={candidate?._position?.bill_rate?.max}
          />
        </>
      )
    },
    { title: 'Salary (Min)', value: currency(candidate?.salary?.min) },
    {
      title: 'Salary (Max)',
      value: (
        <>
          {currency(candidate?.salary?.max)}
          <HigherWarning
            candidate={candidate?.salary?.max}
            position={candidate?._position?.salary?.max}
          />
        </>
      )
    }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: candidate === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section1.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
