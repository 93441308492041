import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import useSearchField from '../../../../GlobalSearch/useSearchField'
import SearchField from '../../../../GlobalSearch/SearchField'
import Results from './Results'

const GlobalSearch: React.FC = () => {
  const { inputProps, debouncedValue, focused, hasSearchValue } = useSearchField()
  const [hovered, setHovered] = useState(false)
  const isOpen = hasSearchValue && (focused || hovered)

  const handleMouseEnter: React.MouseEventHandler<HTMLElement> = (event) => {
    setHovered(true)
  }

  const handleMouseLeave: React.MouseEventHandler<HTMLInputElement> = (event) => {
    setHovered(false)
  }

  return (
    <Dropdown isOpen={isOpen} toggle={() => null} inNavbar className='w-50'>
      <DropdownToggle tag='span' aria-expanded={isOpen}>
        <SearchField {...inputProps} />
      </DropdownToggle>
      {isOpen
        ? (
          <DropdownMenu
            className='mt-2 w-100 searchMenu'
            style={{
              overflowY: 'auto',
              maxHeight: 'calc(-68px + 100vh)',
              transform: 'translate3d(0px, 38px, 0px) !important',
              scrollSnapType: 'y mandatory'
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Results term={debouncedValue} onClick={() => setHovered(false)} />
          </DropdownMenu>
          )
        : null}
    </Dropdown>
  )
}

export default GlobalSearch
