import React from 'react'
import { Col, Row } from 'reactstrap'

const NamesLoading: React.FC = (props) => {
  return (
    <Row className='align-items-center'>
      <Col><h4>Searching for duplicate candidates...</h4></Col>
    </Row>
  )
}

export default NamesLoading
