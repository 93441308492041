import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query positionJobDescById($id: Int!) {
    cat {
      position {
        byId(_id: $id) {
          _id
          job_desc_received
          job_desc_finalized
          job_desc_posted
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type PositionJobDescById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['position']>['byId']>

const usePositionJobDescById = (id: number): PositionJobDescById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'number'
  })
  const position = data?.cat?.position?.byId ?? undefined
  return position
}

export { usePositionJobDescById, document as positionJobDescByIdDocument }
