import { useEditor, EditorContent } from '@tiptap/react'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import StarterKit from '@tiptap/starter-kit'
import { Card, CardHeader, Col } from 'reactstrap'
import { Editor, JSONContent } from '@tiptap/core'
import Toolbar from './Toolbar'
import { useField, useFormikContext } from 'formik'
import Indent from './extensions/indent'
import { SUSPENDED_FORM_STATUS } from 'src/components/Forms/FormSuspense'

// define your extension array
const extensions = [
  StarterKit,
  Underline,
  Highlight.configure({
    HTMLAttributes: {
      class: 'rounded bg-warning-subtle'
    }
  }),
  Indent
]

export type OnRichTextEditorChange = (args: {
  editor: Editor
  html: string
  json: JSONContent
}) => void

interface RichTextEditorProps {
  name: string
  onChange?: OnRichTextEditorChange
}

const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  const { onChange, name } = props
  const [, meta, helpers] = useField({ type: 'text', name })
  const { status } = useFormikContext()
  const suspended = status === SUSPENDED_FORM_STATUS
  const editor = useEditor({
    extensions,
    content: meta.initialValue,
    editorProps: {
      attributes: {
        class: 'card-body rounded-bottom',
        'data-testid': 'editor'
      }
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML()
      if (typeof onChange === 'function') {
        onChange({
          editor,
          json: editor.getJSON(),
          html
        })
      }
      void helpers.setValue(html)
    },
    onCreate: ({ editor }) => {
      const html = editor.getHTML()
      if (typeof onChange === 'function') {
        onChange({
          editor,
          json: editor.getJSON(),
          html
        })
      }
      void helpers.setValue(html)
    }
  })

  if (editor === null) {
    return null
  }

  if (suspended) {
    return (
      <Col className='col-12 placeholder' data-testid='placeholder' />
    )
  }

  return (
    <>
      <Card>
        <CardHeader className='p-1 text-start align-items-center d-flex'>
          <Toolbar editor={editor} />
        </CardHeader>
        <EditorContent editor={editor} />
      </Card>
    </>
  )
}

export default RichTextEditor
