import React from 'react'
import { Badge, Table } from 'reactstrap'
import { useAllDataMigrations } from 'src/queries/management/migrations/useAllDataMigrations'

const Body: React.FC = () => {
  const migrations = useAllDataMigrations()
  return (
    <Table>
      <thead>
        <tr>
          <th>Status</th>
          <th>Name</th>
          <th>Path</th>
        </tr>
      </thead>
      <tbody>
        {migrations.map((migration) => {
          if (migration === null ||
            migration === undefined ||
            migration.name === null ||
            migration.name === undefined
          ) {
            return null
          }
          return (
            <tr key={migration.name}>
              <td>
                <Badge color={migration.status?.toLowerCase() === 'executed' ? 'success' : undefined}>
                  {migration.status}
                </Badge>
              </td>
              <td>{migration.name}</td>
              <td>{migration.path}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default Body
