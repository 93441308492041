import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query distributionSubcategoriesAndCategories($filter: FilterFindManyDistribution_SubcategoryInput, $sort: SortFindManyDistribution_SubcategoryInput) {
    lookups {
      Distribution_Subcategory{
        many(filter: $filter, sort: $sort) {
          _id
          name
          _category {
            _id
            name
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type DistributionSubcategoriesAndCategories = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Distribution_Subcategory']>['many']>

const useDistributionSubcategoriesAndCategories = (): DistributionSubcategoriesAndCategories => {
  const { data } = useGraphQL(document)
  return data?.lookups?.Distribution_Subcategory?.many ?? []
}

export { useDistributionSubcategoriesAndCategories, document as distributionSubcategoriesAndCategoriesDocument }
