import React, { useEffect } from 'react'
import { useCardContext } from 'src/components/Card/CardContext'
import List from './List'
import { useCandidateDetailsById } from 'src/queries'

interface BodyProps {
  id: string
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const candidate = useCandidateDetailsById(id)
  const color = candidate?._substatus?.color
  const cardContext = useCardContext()
  useEffect(() => {
    if (typeof color === 'string') {
      cardContext.setColor(color)
    }
  }, [color])
  return (
    <List candidate={candidate} />
  )
}

export default Body
