import React, { useState } from 'react'
import { Alert, Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply, faFaceFrown } from '@fortawesome/free-solid-svg-icons'

export interface ErrorProps {
  errorCode?: number
  errorDetails?: string
  onReturnHome: React.MouseEventHandler<HTMLButtonElement>
}

interface ErrorMessage {
  code?: number
  message: string
  text: string
}

const getErrorMessage = (code?: number): ErrorMessage => {
  const errorMap: ErrorMessage[] = [
    {
      code: 404,
      message: 'PAGE NOT FOUND',
      text: "It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. Here's a little tip that might help you get back on track."
    },
    {
      code: 300,
      message: 'UNAUTHORIZED',
      text: "Sorry you aren't allowed to view this page. Please contact an administrator if you think this is a mistake."
    }
  ]
  const defaultErrorMessage = {
    code: undefined,
    message: 'Woops!',
    text: 'We are sorry, this was probably our fault! It seems that something went wrong. Please return to the homepage and try again. If you continue to have issues, please contact support.'
  }
  const errorMessage = errorMap.find(e => {
    return e.code === code
  })
  return errorMessage ?? defaultErrorMessage
}

const Error: React.FC<ErrorProps> = (props) => {
  const { onReturnHome, errorDetails, errorCode } = props
  const errorMessage = getErrorMessage(errorCode)
  const [showDetails, setShowDetails] = useState(false)
  const toggleShowDetails = (): void => setShowDetails(s => !s)

  return (
    <Row className='text-center px-5'>
      <Col>
        <Row className='p-1'>
          <Col>
            <h1 className='display-1 fw-bold text-dark text-opacity-75' style={{ textShadow: '0.5rem 0.5rem 0.2rem rgba(0,0,0,.175)' }}>
              {errorMessage.code !== undefined
                ? errorMessage.code
                : (
                  <div>
                    <FontAwesomeIcon id='errorIcon' icon={faFaceFrown} size='2x' style={{ filter: 'drop-shadow(0.5rem 0.5rem 0.2rem rgba(0,0,0,.175))}' }} />
                  </div>
                  )}
            </h1>
          </Col>
        </Row>
        <Row className='p-1'>
          <Col><h3 className='text-danger fw-bold'>{errorMessage.message}</h3></Col>
        </Row>
        <Row className='p-1'>
          <Col>
            <p className='text-muted'>
              {errorMessage.text}{' '}
              {errorDetails !== undefined
                ? (
                  <>
                    <span
                      onClick={toggleShowDetails}
                      role='link'
                      className='text-decoration-underline cursor-pointer'
                    >
                      <small>
                        {showDetails ? 'Hide Details' : 'Show Details'}
                      </small>
                    </span>
                  </>
                  )
                : null}
            </p>
          </Col>
        </Row>
        {showDetails
          ? (
            <Row>
              <Col>
                <Alert color='warning'>
                  <em>{errorDetails}</em>
                </Alert>
              </Col>
            </Row>
            )
          : null}
        <Row className='p-2'>
          <Col>
            <Button onClick={onReturnHome} color='dark' className='text-white shadow-sm'>
              <FontAwesomeIcon icon={faReply} className='me-2' />
              Return to Home
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Error
