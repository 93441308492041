import { useFormikContext } from 'formik'
import React, { PropsWithChildren } from 'react'
import { Col, FormFeedback, FormGroup, Label } from 'reactstrap'
import { SUSPENDED_FORM_STATUS } from 'src/components/Forms/FormSuspense'

interface InputBaseProps extends PropsWithChildren {
  name?: string
  label: string
  error?: string
  required?: boolean
}

const InputBase: React.FC<InputBaseProps> = (props) => {
  const { name, label, children, error, required } = props
  const { status } = useFormikContext()
  const suspended = status === SUSPENDED_FORM_STATUS

  return (
    <FormGroup>
      <Label for={name} className='fw-bold'>
        {label}
        {required === true
          ? (
            <sup className='text-danger'>*</sup>
            )
          : null}
      </Label>
      {suspended
        ? (
          <Col className='col-12 placeholder' data-testid='placeholder' />
          )
        : (
          <>
            {children}
            <FormFeedback>
              {error}
            </FormFeedback>
          </>
          )}
    </FormGroup>
  )
}

export default InputBase
