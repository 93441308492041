import { ColumnDef, DeepKeys, SortingFn, createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { currency } from '../../../../helpers/displayhelpers'
import { Badge } from 'reactstrap'
import { Positions } from 'src/queries'

type TData = Positions[0]

const columnHelper = createColumnHelper<TData>()

type SortByArrayFn = (arr: string[]) => SortingFn<TData>

const sortByArray: SortByArrayFn = (arr) => (aRow, bRow, columnId) => {
  const aVal = aRow.getValue(columnId)
  const bVal = bRow.getValue(columnId)
  const a = arr.indexOf(String(aVal))
  const b = arr.indexOf(String(bVal))
  return a - b
}

type PositionColumns = (statuses?: string[]) => Array<ColumnDef<TData, DeepKeys<TData>>>

const statusColorMap: { [key: string]: string } = {
  Open: 'success',
  Paused: 'secondary'
}

const columns: PositionColumns = (statuses) => [
  columnHelper.accessor((r) => {
    return `(${r.id ?? ''}) ${r.title ?? ''}`
  }, {
    header: 'Title',
    cell: (info) => {
      const value = info.getValue()
      const id = info.row.original._id
      if (id !== undefined) {
        return (
          <Link to={`/cat/positions/${String(id)}`}>{value}</Link>
        )
      }
      return value
    }
  }),
  columnHelper.accessor('_status.name', {
    id: 'status',
    header: 'Status',
    sortingFn: statuses !== undefined ? sortByArray(statuses) : 'auto',
    cell: (info) => {
      const val = info.getValue()
      const color = statusColorMap[val] ?? 'light'
      const textColor = color === 'light' ? 'text-dark' : ''
      return (
        <Badge color={color} className={`${textColor} w-100`}>{val}</Badge>
      )
    }
  }),
  columnHelper.accessor('_contract.abbreviation', {
    header: 'Contract'
  }),
  columnHelper.accessor('_program_manager.name', {
    header: 'Program Manager'
  }),
  columnHelper.accessor((r) => currency(r.bill_rate?.min), {
    header: 'Bill Rate (Min)'
  }),
  columnHelper.accessor((r) => currency(r.bill_rate?.max), {
    header: 'Bill Rate (Max)'
  }),
  columnHelper.accessor((r) => currency(r.salary?.min), {
    header: 'Salary (Min)'
  }),
  columnHelper.accessor((r) => currency(r.salary?.max), {
    header: 'Salary (Max)'
  })
]

export { columns }
