import React from 'react'
import SplashPage from '../SplashPage'
import Error, { ErrorProps } from './Error'

const ErrorSplashPage: React.FC<ErrorProps> = (props) => {
  return (
    <SplashPage>
      <Error {...props} />
    </SplashPage>
  )
}

export default ErrorSplashPage
