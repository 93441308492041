import React from 'react'
import { UseSearchReturn } from './useSearch'
import { Badge, Col, Row } from 'reactstrap'

interface SearchItemProps {
  item: NonNullable<UseSearchReturn['items']>[0]
}

const SearchItem: React.FC<SearchItemProps> = (props) => {
  const { item } = props

  return (
    <Row>
      <Col>
        <Row className='flex-nowrap'>
          <Col className='text-truncate'>
            <b>{item.title}</b>
          </Col>
          <Col xs='auto'>
            <small>
              <Badge color='secondary'>{item.category}</Badge>
            </small>
          </Col>
        </Row>
        <Row>
          <Col>
            <small>
              {item.badges?.map((b, index) => {
                const value = b?.value ?? undefined
                if (b?.value !== undefined) {
                  return <Badge color='none' key={index} className='me-1 text-dark border border-secondary'>{value}</Badge>
                }
                return null
              })}
            </small>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SearchItem
