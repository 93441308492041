import parse from 'html-react-parser'

interface DisplayHtmlProps {
  value: string
}

const DisplayHtml: React.FC<DisplayHtmlProps> = (props) => {
  const { value } = props
  return <div style={{ whiteSpace: 'pre-wrap' }}>{parse(value)}</div>
}

export default DisplayHtml
