export interface OverviewState {
  position: number | undefined
  category: string | undefined
  subcategory: string | undefined
  status: string | undefined
  distributionCandidatesToggle: boolean
  otherCandidatesToggle: boolean
  positionQueryEnabled: boolean
  candidatesQueryEnabled: boolean
}

export type OverviewAction =
  | { type: 'setPosition', payload: number | undefined }
  | { type: 'setCategory', payload: string | undefined }
  | { type: 'setSubcategory', payload: string | undefined }
  | { type: 'setStatus', payload: string | undefined }
  | { type: 'toggleDistributionCandidates', payload?: boolean }
  | { type: 'toggleOtherCandidates', payload?: boolean }

const defaultState: OverviewState = {
  position: undefined,
  category: undefined,
  subcategory: undefined,
  status: undefined,
  distributionCandidatesToggle: false,
  otherCandidatesToggle: true,
  positionQueryEnabled: false,
  candidatesQueryEnabled: false
}

const trim = (str: string | undefined): string | undefined => {
  return str !== '' && str !== undefined ? str.trim() : undefined
}

const stateChecks = (newState: OverviewState): OverviewState => {
  const trimmedState: OverviewState = {
    ...newState,
    category: trim(newState.category),
    subcategory: trim(newState.subcategory),
    status: trim(newState.status)
  }
  const { category, subcategory, position } = trimmedState
  const correctedState = {
    ...trimmedState,
    positionQueryEnabled: category !== undefined && subcategory !== undefined,
    candidatesQueryEnabled: category !== undefined && subcategory !== undefined && position !== undefined,
    position: category === undefined || subcategory === undefined || position === undefined || isNaN(position) ? undefined : position
  }
  return correctedState
}

const reducer = (state: OverviewState, action: OverviewAction): OverviewState => {
  let newState: OverviewState
  switch (action.type) {
    case 'setPosition':
      newState = { ...state, position: isNaN(Number(action.payload)) ? undefined : Number(action.payload) }
      break
    case 'setCategory':
      newState = { ...state, position: undefined, subcategory: undefined, category: trim(action.payload) }
      break
    case 'setSubcategory':
      newState = { ...state, position: undefined, subcategory: trim(action.payload) }
      break
    case 'setStatus':
      newState = { ...state, position: undefined, status: trim(action.payload) }
      break
    case 'toggleDistributionCandidates':
      newState = { ...state, distributionCandidatesToggle: action.payload !== undefined ? action.payload : !state.distributionCandidatesToggle }
      break
    case 'toggleOtherCandidates':
      newState = { ...state, otherCandidatesToggle: action.payload !== undefined ? action.payload : !state.otherCandidatesToggle }
      break
    default:
      newState = state
  }
  return stateChecks(newState)
}

export { reducer, defaultState, stateChecks }
