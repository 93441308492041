import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import Error, { ErrorProps } from './Error'
import ErrorSplashPage from './ErrorSplashPage'

export interface ReactErrorBoundaryProps extends FallbackProps {
  splashpage?: boolean
}

const ReactErrorBoundary: React.FC<ReactErrorBoundaryProps> = (props) => {
  const { splashpage, error, resetErrorBoundary } = props
  const code = 'status' in error ? Number(error.status) : undefined
  const errorProps: ErrorProps = {
    errorCode: code,
    errorDetails: error.message,
    onReturnHome: () => resetErrorBoundary()
  }
  if (splashpage === true) {
    return (
      <ErrorSplashPage {...errorProps} />
    )
  }

  return (
    <Error {...errorProps} />
  )
}

export default ReactErrorBoundary
