import { useEffect, useRef } from 'react'
import { useAuth } from './useAuth'
import isEqual from 'lodash/isEqual'
import { useMutateAnalyticsCreateOne } from 'src/queries/analytics'
import { MutateAnalyticsCreateOneMutationVariables } from 'src/gql/graphql'

const getSize = (): { width: Number, height: Number } => ({
  width: document.documentElement.clientWidth,
  height: document.documentElement.clientHeight
})

type AnalyticsData = MutateAnalyticsCreateOneMutationVariables['record']

const useAnalytics = (): void => {
  const location = JSON.parse(JSON.stringify(window.location))
  const { isAuthenticated, app: { user } } = useAuth()

  const analytics = useMutateAnalyticsCreateOne()

  const dataRef = useRef<AnalyticsData | undefined>()
  useEffect(() => {
    if (isAuthenticated) {
      const data: AnalyticsData = {
        location,
        user,
        size: getSize(),
        agent: navigator.userAgent,
        appVersion: 2
      }
      if (!isEqual(data, dataRef.current)) {
        analytics.mutate({ record: data })
      }
      dataRef.current = data
    }
  }, [location, user])
}

export default useAnalytics
