import React from 'react'
import ErrorAlert, { ErrorAlertProps } from '../../Errors/ErrorAlert'
import { ModalBody } from 'reactstrap'
import { ErrorBoundary } from 'react-error-boundary'

export interface BodyProps {
  children: React.ReactNode
  error: ErrorAlertProps
}

const Body: React.FC<BodyProps> = (props) => {
  const {
    children,
    error: {
      error,
      details,
      show
    }
  } = props
  return (
    <ModalBody>
      <ErrorBoundary
        fallbackRender={(props) => (
          <div className='text-center'>
            <ErrorAlert
              error={error}
              details={props.error.message}
              show
            />
          </div>
        )}
      >
        <div className='text-center'>
          <ErrorAlert
            error={error}
            details={details}
            show={show}
          />
        </div>
        {children}
      </ErrorBoundary>
    </ModalBody>
  )
}

export default Body
