import React from 'react'
import Modal, { Header } from 'src/components/theme/Models/Modal'
import FormContext from './FormContext'
import FormSuspense from 'src/components/Forms/FormSuspense'
import Form from './Form'

interface EditModelProps {
  id: number
  isOpen: boolean
  toggle: () => void
}

const EditModel: React.FC<EditModelProps> = (props) => {
  const { id, isOpen, toggle } = props

  return (
    <Modal
      title='Edit Position Details'
      isOpen={isOpen}
      toggle={toggle}
    >
      <Header title='Edit Position Details' />
      <FormSuspense fallback={<Form toggle={toggle} disableSave />}>
        <FormContext id={id} toggle={toggle} />
      </FormSuspense>
    </Modal>
  )
}

export default EditModel
