import { Formik } from 'formik'
import React from 'react'
import { UseMutateDistributionByIdReturn, distributionDetailsByIdDocument, useMutateDistributionById, useDistributionDetailsById, useDistributionCategories, useDistributionSubcategories } from 'src/queries'
import Form from './Form'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { MutateDistributionByIdMutation } from 'src/gql/graphql'
import { emptyStringToNull } from 'src/helpers/formHelpers'
import { pick } from 'lodash'

export type Values = Parameters<UseMutateDistributionByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const distribution = useDistributionDetailsById(id)
  if (distribution === undefined) {
    throw new Error('Could not load distribution!')
  }
  const categories = useDistributionCategories()
  useDistributionSubcategories(distribution.category)
  const update = useMutateDistributionById()
  const initialState: Values = pick(distribution, [
    'category',
    'subcategory',
    'date_posted'
  ])
  const invalidateDistribution = useInvalidateQueryByDocument(distributionDetailsByIdDocument)
  const handleSuccess = (data: MutateDistributionByIdMutation): void => {
    const id = data.cat?.distribution?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateDistribution({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const record = { ...data }
        emptyStringToNull(record, 'category')
        emptyStringToNull(record, 'subcategory')

        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form categories={categories} toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
