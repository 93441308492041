import React from 'react'
import { Input } from 'reactstrap'
import { useOverviewState } from 'src/pages/cat/view/distributionoverview/useOverviewState'
import { useDistributionCategories } from 'src/queries'

const Dropdown: React.FC = (props) => {
  const { category, dispatch } = useOverviewState()
  const categories = useDistributionCategories()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({ type: 'setCategory', payload: e.target.value })
  }
  return (
    <Input type='select' bsSize='lg' onChange={handleChange} value={category} aria-label='Category'>
      <option value=''>Select a Category</option>
      {categories?.map((s, i) => (
        <option key={String(s.value)} value={s.value}>{s.label}</option>
      ))}
    </Input>
  )
}

export default Dropdown
