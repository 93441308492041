import React from 'react'
import Modal, { Header } from 'src/components/theme/Models/Modal'
import FormContext from './FormContext'
import FormSuspense from 'src/components/Forms/FormSuspense'
import Form from './Form'

interface AddCandidateModalProps {
  isOpen: boolean
  toggle: () => void
  position: number
  distribution?: string
}

const AddCandidateModal: React.FC<AddCandidateModalProps> = (props) => {
  const { isOpen, toggle, position, distribution } = props

  return (
    <Modal
      title='Add Candidate'
      isOpen={isOpen}
      toggle={toggle}
    >
      <Header title='Add Candidate' />
      <FormSuspense fallback={<Form toggle={toggle} disableSave next={() => null} />}>
        <FormContext toggle={toggle} position={position} distribution={distribution} />
      </FormSuspense>
    </Modal>
  )
}

export default AddCandidateModal
