import React, { useEffect } from 'react'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useCardContext } from '../../../../../../components/Card/CardContext'
import { useDistributionsByPositionId } from 'src/queries'

interface BodyProps {
  id: number
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const distributions = useDistributionsByPositionId(id)
  const card = useCardContext()
  const count = distributions.length
  useEffect(() => {
    card.setTitle(`Distributions (${count})`)
  }, [count])
  return (
    <Table storageKey='cat-position-distributions' options={{ data: distributions, columns }} />
  )
}

export default Body
