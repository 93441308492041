import React, { Suspense } from 'react'
import { Col, Row } from 'reactstrap'
import Candidates from './cards/Candidates'
import { useOverviewState } from 'src/pages/cat/view/distributionoverview/useOverviewState'
import Loading from 'src/pages/cat/view/distributionoverview/cards/Candidates/Loading'

const CandidateCards: React.FC = (props) => {
  const { distributionCandidatesToggle, otherCandidatesToggle, dispatch } = useOverviewState()
  const handleToggleDistributionCandidates = (): void => {
    dispatch({ type: 'toggleDistributionCandidates' })
  }
  const handleToggleOtherCandidates = (): void => {
    dispatch({ type: 'toggleOtherCandidates' })
  }

  return (
    <>
      <Row className='gy-4 mb-4'>
        <Col md='12'>
          <Suspense fallback={<Loading collapsed={distributionCandidatesToggle} onCollapse={handleToggleDistributionCandidates} />}>
            <Candidates candidateType='distribution' collapsed={distributionCandidatesToggle} onCollapse={handleToggleDistributionCandidates} />
          </Suspense>
        </Col>
      </Row>
      <Row className='gy-4'>
        <Col md='12'>
          <Suspense fallback={<Loading collapsed={otherCandidatesToggle} onCollapse={handleToggleOtherCandidates} />}>
            <Candidates candidateType='other' collapsed={otherCandidatesToggle} onCollapse={handleToggleOtherCandidates} />
          </Suspense>
        </Col>
      </Row>
    </>
  )
}

export default CandidateCards
