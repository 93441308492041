import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateContractByIdMutation, MutateContractByIdMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'
// import { contractCreatedUpdatedByIdDocument } from '../useContractCreatedUpdatedById'

const document = graphql(/* graphql */`
  mutation mutateContractById($id: MongoID!, $record: UpdateByIdCat_ContractInput!) {
    cat {
      contract {
        updateById(_id: $id, record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateContractByIdReturn = UseMutationResult<
MutateContractByIdMutation,
unknown,
MutateContractByIdMutationVariables
>

const useMutateContractById = (): UseMutateContractByIdReturn => {
  // const invalidateCreatedUpdated = useInvalidateQueryByDocument(contractCreatedUpdatedByIdDocument)
  return useGraphMutation(document, {
    onSuccess: (data) => {
      const id = data.cat?.contract?.updateById?.recordId
      if (typeof id === 'string') {
        // void invalidateCreatedUpdated({ id })
      }
    }
  })
}

export { useMutateContractById, document as mutateContractByIdDocument }
