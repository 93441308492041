import { createContext, useContext, useEffect, useReducer } from 'react'
import { OverviewAction, OverviewState, defaultState, reducer, stateChecks } from 'src/pages/cat/view/distributionoverview/useOverviewState/reducer'
import { usePositionStatuses } from 'src/queries'

export interface OverviewContextValue extends OverviewState {
  dispatch: React.Dispatch<OverviewAction>
}

const OverviewContext = createContext<OverviewContextValue | undefined>(undefined)

const STORAGE_KEY = 'cat-view-distribution-overview'

const OverviewContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const storage = localStorage.getItem(STORAGE_KEY)
  const baseState = storage !== null ? JSON.parse(storage) : defaultState
  const [state, dispatch] = useReducer(reducer, stateChecks(baseState))
  const { status } = state

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
  }, [state])

  const statuses = usePositionStatuses()

  useEffect(() => {
    if (statuses.length > 0 && status === undefined) {
      const open = statuses.find(s => s.name === 'Open')
      dispatch({ type: 'setStatus', payload: open?._id })
    }
  }, [statuses])

  const context: OverviewContextValue = {
    ...state,
    dispatch
  }

  return (
    <OverviewContext.Provider value={context}>
      {props.children}
    </OverviewContext.Provider>
  )
}

const useOverviewState = (): OverviewContextValue => {
  const context = useContext(OverviewContext)
  if (context == null) {
    throw new Error('useOverviewState must be used within a OverviewContextProvider')
  }
  return context
}

export { OverviewContextProvider, useOverviewState }
