import React from 'react'
import './App.css'
import { Navigate } from 'react-router'
import { useAuth } from 'src/hooks/useAuth'

const App: React.FC = () => {
  const { app } = useAuth()
  const defaultPage = app.user.defaultPage ?? app.user._role?.defaultPage
  if (typeof defaultPage === 'string' && defaultPage.length > 0) {
    return (
      <Navigate to={defaultPage} replace />
    )
  } else {
    return (
      <Navigate to='/cat/positions' replace />
    )
  }
}

export default App
