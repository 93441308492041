import { useAttachments } from 'src/queries/attachments'

interface UseDuplicatesArgs {
  link: string
  type: string
  name: string
}

interface UseDuplicatesReturn {
  hasDuplicates: boolean
  duplicates: ReturnType<typeof useAttachments>
}

const useDuplicates = (args: UseDuplicatesArgs): UseDuplicatesReturn => {
  const { link, type, name } = args

  const hasName = name !== undefined && name !== ''
  const duplicates = useAttachments(
    { filter: { link, type, filename: name } },
    { enabled: hasName, suspense: false }
  )
  const hasDuplicates = duplicates.length > 0

  return {
    hasDuplicates,
    duplicates
  }
}

export default useDuplicates
