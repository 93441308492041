import React, { useEffect } from 'react'
import List from './List'
import { usePositionDetailsById } from 'src/queries'
import { useCardContext } from '../../../../../../components/Card/CardContext'

interface BodyProps {
  id: number
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const position = usePositionDetailsById(id)
  const cardContext = useCardContext()
  const formatedId = position?.id
  useEffect(() => {
    if (formatedId !== undefined && formatedId !== null) {
      cardContext.setTitle(`Position - ${formatedId}`)
    } else {
      cardContext.setTitle('Position')
    }
  }, [formatedId])
  return (
    <List position={position} />
  )
}

export default Body
