import { Formik } from 'formik'
import React from 'react'
import { UseMutateContractByIdReturn, contractOnboardingByIdDocument, useMutateContractById, useContractOnboardingById } from 'src/queries'
import Form from './Form'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { MutateContractByIdMutation } from 'src/gql/graphql'
import { pick } from 'lodash'

export type Values = Parameters<UseMutateContractByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const contract = useContractOnboardingById(id)
  if (contract === undefined) {
    throw new Error('Could not load contract!')
  }
  const update = useMutateContractById()
  const initialState: Values = pick(contract, [
    'onboarding'
  ])
  const invalidateContract = useInvalidateQueryByDocument(contractOnboardingByIdDocument)
  const handleSuccess = (data: MutateContractByIdMutation): void => {
    const id = data.cat?.contract?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateContract({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const record = { ...data }

        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
