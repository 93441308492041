import React from 'react'
import useToken from './useToken'
import { Button, Card, CardBody } from 'reactstrap'

const Body: React.FC = (props) => {
  const token = useToken()
  return (
    <div>
      <Button
        block
        disabled={token === undefined}
        onClick={() => {
          if (token === undefined) {
            return
          }
          void navigator.clipboard.writeText(token)
        }}
        className='mb-3'
      >
        Copy Token
      </Button>
      <Card color='light'>
        <CardBody style={{ overflowWrap: 'anywhere' }}>
          <small>{token}</small>

        </CardBody>
      </Card>
    </div>
  )
}

export default Body
