import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query candidateResumeReviewById($id: MongoID!) {
    cat {
      candidate {
        byId(_id: $id) {
          _id
          resume {
            sent
            reviewed
            reviewed_by
            _reviewed_by {
              name
            }
          }
          rank
          _rank {
            name
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateResumeReviewById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['byId']>

const useCandidateResumeReviewById = (id: string): CandidateResumeReviewById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const candidate = data?.cat?.candidate?.byId ?? undefined
  return candidate
}

export { useCandidateResumeReviewById, document as candidateResumeReviewByIdDocument }
