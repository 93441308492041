import React, { useMemo } from 'react'
import { AppUserProvider, useAppUser, AppUserContextValue } from './useAppUser'
import { CognitoProvider, useCognito, CognitoContextValue } from './useCognito'

export interface AuthContextValue {
  app: AppUserContextValue
  cognito: CognitoContextValue
  isAuthenticated: boolean
  login: () => Promise<void>
  logout: () => Promise<void>
}

const AuthProvider: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <CognitoProvider>
      <AppUserProvider>
        {props.children}
      </AppUserProvider>
    </CognitoProvider>
  )
}

const useAuth = (): AuthContextValue => {
  const app = useAppUser()
  const cognito = useCognito()

  const login = async (): Promise<void> => {
    await cognito.login()
  }

  const logout = async (): Promise<void> => {
    app.logout()
    await cognito.logout()
  }

  const merged = useMemo(() => {
    return {
      app,
      cognito,
      login,
      logout,
      isAuthenticated: cognito.user.isAuthenticated && app.user.isAuthenticated
    }
  }, [app, cognito])
  return merged
}

export { AuthProvider, useAuth }
