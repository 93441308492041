import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'
import { AttachmentSubtypesQueryVariables, SortFindManyAttachmentInput } from 'src/gql/graphql'

const document = graphql(/* graphql */`
  query attachmentSubtypes($filter: FilterFindManyAttachment_SubtypeInput) {
    attachmentSubtypes {
      many(filter: $filter) {
        _id
        name
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type AttachmentSubtypes = NonNullable<NonNullable<NonNullable<TData>['attachmentSubtypes']>['many']>

const useAttachmentSubtypes = (filter?: AttachmentSubtypesQueryVariables['filter'], enabled: boolean = true): AttachmentSubtypes => {
  const { data } = useGraphQL(document, {
    filter,
    sort: SortFindManyAttachmentInput.CreatedatDesc
  })
  return data?.attachmentSubtypes?.many ?? []
}

export { useAttachmentSubtypes, document as attachmentSubtypesDocument }
