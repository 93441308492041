import { GraphQLClient } from 'graphql-request'
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

const apiPrefix = process.env.REACT_APP_API_URL ?? 'http://localhost:4000'
const url = `${apiPrefix}/graphql`
const client = new GraphQLClient(url, {
  requestMiddleware: async (request) => {
    let user: CognitoUserSession | undefined
    try {
      user = await Auth.currentSession()
    } catch (e) {
      if (e instanceof Error && e.message === 'No current user') {
        return request
      }
    }

    if (user === undefined) {
      throw new Error('User is not authenticated')
    }
    const token = user.getAccessToken().getJwtToken()
    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: token
      }
    }
  }
})

export function useInfiniteGraphQL<TResult, TVariables extends Record<string, any>> (
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
  getKeyParams?: (variables: TVariables | undefined) => Partial<TVariables>,
  options?: Parameters<typeof useInfiniteQuery<TResult, unknown, TResult>>[2]
): UseInfiniteQueryResult<TResult, any> {
  const keyParams = typeof getKeyParams === 'function' ? getKeyParams(variables) : undefined

  return useInfiniteQuery({
    queryKey: [(document.definitions[0] as any).name.value, keyParams] as any,
    queryFn: async (data) => {
      const params = data.pageParam ?? variables
      return await client.request<TResult, any>(
        document,
        params ?? undefined
      )
    },
    suspense: false,
    ...options
  })
}
