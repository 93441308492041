import { graphql } from 'src/gql'
import { ContractOnboardingByIdQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL, UseGraphqlOptions } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query contractOnboardingById($id: MongoID!) {
    cat {
      contract {
        byId(_id: $id) {
          _id
          onboarding {
            days
            clearanceReqs
            biProcessTimeframe
            gfeProvided
            imcEquipment
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractOnboardingById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['contract']>['byId']>

const useContractOnboardingById = (id: string, variables?: ContractOnboardingByIdQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): ContractOnboardingById | undefined => {
  const internalVariables: ContractOnboardingByIdQueryVariables = Object.assign({
    id
  }, variables)
  const { data } = useGraphQL(document, internalVariables, options)
  return data?.cat?.contract?.byId ?? undefined
}

export { useContractOnboardingById, document as contractOnboardingByIdDocument }
