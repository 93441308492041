import React, { Suspense, useMemo } from 'react'
import Card from 'src/components/Card'
import CardBody from './CardBody'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { usePositionStatuses } from 'src/queries'
import useToggleState from 'src/hooks/useToggleState'
import AddModal from './AddModal'

interface PositionListProps {
  contract: string
}

const PositionList: React.FC<PositionListProps> = (props) => {
  const { contract } = props
  const statuses = usePositionStatuses()
  const [isOpen, , handleToggle] = useToggleState()
  const statusLabels = statuses?.map(s => String(s.name))
  const memoColumns = useMemo(() => {
    return columns(statusLabels)
  }, [statusLabels])
  return (
    <>
      <Card
        title='Positions'
        primaryAction={[
          {
            key: 'add-position',
            icon: faPlus,
            text: 'Add Position',
            onClick: handleToggle,
            permissionKey: 'CAT_EDIT'
          }
        ]}
      >
        <Suspense fallback={<Table options={{ data: [], columns: memoColumns }} suspend={3} />}>
          <CardBody columns={memoColumns} contract={contract} />
        </Suspense>
      </Card>
      <AddModal isOpen={isOpen} toggle={handleToggle} contract={contract} />
    </>
  )
}

export default PositionList
