import { createDropdownOptions } from 'src/helpers/formHelpers'
import { useClearances, useContractTypes, useContracts, useHiringManagers, usePositionPriorities, usePositionStatuses, useProgramManagers } from 'src/queries'

export interface UseLookupsReturn {
  contracts: ReturnType<typeof createDropdownOptions>
  contractTypes: ReturnType<typeof useContractTypes>
  statuses: ReturnType<typeof createDropdownOptions>
  priorities: ReturnType<typeof usePositionPriorities>
  programManagers: ReturnType<typeof useProgramManagers>
  hiringManagers: ReturnType<typeof useHiringManagers>
  clearances: ReturnType<typeof useClearances>
}

const useLookups = (): UseLookupsReturn => {
  const contracts = useContracts()
  const statuses = usePositionStatuses()
  const contractTypes = useContractTypes()
  const priorities = usePositionPriorities()
  const programManagers = useProgramManagers()
  const hiringManagers = useHiringManagers()
  const clearances = useClearances()
  return {
    contracts: createDropdownOptions(contracts, { labelKey: 'abbreviation' }),
    statuses: createDropdownOptions(statuses),
    contractTypes,
    priorities,
    programManagers,
    hiringManagers,
    clearances
  }
}

export default useLookups
