import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { isValid } from 'date-fns'
import React from 'react'
import DateDisplay from 'src/components/DateDisplay'

interface DateRangeProps {
  start?: Date
  end?: Date
}

const parseDate = (date: string | Date | undefined): Date | undefined => {
  if (date === undefined) {
    return undefined
  }
  const newDate = typeof date === 'string' ? new Date(date) : date
  if (!isValid(newDate)) {
    return undefined
  }
  return newDate
}

const DateRange: React.FC<DateRangeProps> = (props) => {
  const { start, end } = props
  const today = new Date()
  const startDate = parseDate(start)
  const endDate = parseDate(end)
  const isCurrent = startDate !== undefined &&
    endDate !== undefined &&
    today >= startDate &&
    today <= endDate
  return (
    <span className={classNames({ 'fw-bold': isCurrent })}>
      <DateDisplay date={start} /> - <DateDisplay date={end} />
      {isCurrent
        ? (
          <FontAwesomeIcon className='ms-1' icon={faStar} />
          )
        : null}
    </span>
  )
}

export default DateRange
