import { createDropdownOptions } from 'src/helpers/formHelpers'
import { usePortfolioManagers, useProgramManagers } from 'src/queries'

export interface UseLookupsReturn {
  portfolioManagers: ReturnType<typeof createDropdownOptions>
  programManagers: ReturnType<typeof useProgramManagers>
}

const useLookups = (): UseLookupsReturn => {
  const portfolioManagers = usePortfolioManagers()
  const programManagers = useProgramManagers()
  return {
    portfolioManagers,
    programManagers
  }
}

export default useLookups
