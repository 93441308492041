import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { Candidates } from 'src/queries'

const columnHelper = createColumnHelper<Candidates[0]>()

const columns = [
  columnHelper.accessor((r) => {
    const name = [r.name?.last, r.name?.first].filter(v => typeof v === 'string').join(', ')
    return name ?? 'Unknown'
  }, {
    id: 'name',
    header: 'Name',
    cell: (info) => {
      const value = info.getValue()
      const id = info.row.original._id
      if (id !== undefined) {
        return (
          <Link to={`/cat/candidates/${String(id)}`}>{value}</Link>
        )
      }
      return value
    }
  }),
  columnHelper.accessor('_status.name', {
    header: 'Status'
  }),
  columnHelper.accessor('_substatus.name', {
    header: 'Substatus'
  }),
  columnHelper.accessor('_distribution._subcategory.name', {
    header: 'Distribution'
  })
]

export { columns }
