import React, { Suspense, useMemo } from 'react'
import Card from 'src/components/Card'
import CardBody from './CardBody'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { usePositionStatuses } from 'src/queries'
import { useOverviewState } from 'src/pages/cat/view/distributionoverview/useOverviewState'
import Status from 'src/pages/cat/view/distributionoverview/cards/Positions/Status'

const List: React.FC = (props) => {
  const statuses = usePositionStatuses()
  const statusLabels = statuses?.map(s => String(s.name))
  const { position, dispatch } = useOverviewState()
  const memoColumns = useMemo(() => {
    const handleChange = (position: number): void => dispatch({ type: 'setPosition', payload: position })
    return columns(statusLabels, handleChange, position)
  }, [statusLabels])
  return (
    <>
      <Card
        title='Positions'
        data-testid='positions-card'
        primaryAction={{
          key: 'status',
          render: () => {
            return <Status />
          }
        }}
      >
        <Suspense fallback={<Table options={{ data: [], columns: memoColumns }} suspend={3} />}>
          <CardBody columns={memoColumns} />
        </Suspense>
      </Card>
    </>
  )
}

export default List
