import { graphql } from 'src/gql'
import { SortFindManyProgram_ManagerInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query programManagers($sort: SortFindManyProgram_ManagerInput) {
    lookups {
      Program_Manager {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ProgramManagers = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Program_Manager']>['many']>

const useProgramManagers = useGraphQLDropdownOptions(
  document,
  'lookups.Program_Manager.many',
  { sort: SortFindManyProgram_ManagerInput.NameAsc }
)

export { useProgramManagers, document as programManagersDocument }
