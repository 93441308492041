import React from 'react'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useAttachments } from 'src/queries/attachments/useAttachments'

interface CardBodyProps {
  columns: typeof columns
}

const Body: React.FC<CardBodyProps> = (props) => {
  const { columns } = props
  const attachments = useAttachments({
    filter: {
      type: '6026a8985391d13c5088002c',
      subtype: '61a90af2689b26c27cbdd6cb'
    }
  })
  return (
    <Table storageKey='cat-jobdesc' options={{ data: attachments, columns, initialState: { sorting: [{ id: 'positionNumber', desc: true }] } }} />
  )
}

export default Body
