import React from 'react'
import { isValid } from 'date-fns'
import DateDisplay from '../../DateDisplay'
import { v4 as UUID } from 'uuid'

interface UploadedProps {
  date?: string | null
}

const Uploaded: React.FC<UploadedProps> = (props) => {
  const id = 'date-' + UUID()
  if (typeof props.date === 'string') {
    const date = new Date(props.date)
    if (!isValid(date)) {
      throw new Error('Invalid Upload Date.')
    }

    return (
      <span
        data-testid='attachment-uploaded-date'
        className='text-capitalize'
      >
        Uploaded: <DateDisplay id={id} date={date} display='distance' hover='system' />
      </span>
    )
  }
  return null
}

export default Uploaded
