import React, { Suspense } from 'react'
import Card from 'src/components/Card'
import Body from './Body'
import { useMutateDataMigrationDown } from 'src/queries/management/migrations/useMutateDataMigrationDown'
import { useMutateDataMigrationUp } from 'src/queries/management/migrations/useMutateDataMigrationUp'
import { allDataMigrationsDocument } from 'src/queries/management/migrations/useAllDataMigrations'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

const UpdateDev: React.FC = (props) => {
  const down = useMutateDataMigrationDown()
  const up = useMutateDataMigrationUp()
  const invalidateMigrations = useInvalidateQueryByDocument(allDataMigrationsDocument)
  return (
    <>
      <Card
        title='Data Migrations'
        primaryAction={
          {
            key: 'refresh',
            text: 'Refresh',
            icon: faRefresh,
            onClick: () => {
              void invalidateMigrations({})
            }
          }
        }
        secondaryActions={[
          {
            id: 'up',
            children: 'Up',
            onClick: () => {
              void up.mutate({}, {
                onSettled: () => {
                  void invalidateMigrations({})
                }
              })
            }
          },
          {
            id: 'down',
            children: 'Down',
            onClick: () => {
              void down.mutate({}, {
                onSettled: () => {
                  void invalidateMigrations({})
                }
              })
            }
          }
        ]}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Body />
        </Suspense>
      </Card>
    </>
  )
}

export default UpdateDev
