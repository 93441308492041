import React from 'react'
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import { Dropdown, Input } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { UseLookupsReturn } from './useLookups'
import YesNo from 'src/components/Forms/YesNo'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
  lookups?: UseLookupsReturn
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave } = props
  const {
    contracts,
    contractTypes,
    statuses,
    priorities,
    programManagers,
    hiringManagers,
    clearances
  } = props.lookups ?? {}
  const { handleSubmit } = useFormikContext()
  return (
    <>
      <Body error={{
        error: 'There was an issue adding the position.',
        show: hasError ?? false
      }}
      >
        <Input type='text' name='title' label='Title' required />
        <Dropdown name='contract' label='Contract' options={contracts ?? []} />
        <Dropdown name='contract_type' label='Contract Type' options={contractTypes ?? []} />
        <Dropdown name='status' label='Status' options={statuses ?? []} />
        <Dropdown name='priority' label='Priority' options={priorities ?? []} />
        <Dropdown name='program_manager' label='Program Manager' options={programManagers ?? []} />
        <Dropdown name='hiring_manager' label='Hiring Manager' options={hiringManagers ?? []} />
        <Dropdown name='required_clearance' label='Clearance' options={clearances ?? []} />
        <Input type='text' name='location' label='Location' />
        <YesNo name='direct_hire' label='Direct Hire' />
        <YesNo name='temp_to_perm' label='Temp To Perm' />
        <YesNo name='backfill' label='Backfill' />
        <Input type='text' name='keywords' label='Keywords' />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Create',
          icon: isLoading === true ? faSpinner : faPlus,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
