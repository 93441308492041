interface RouteErrorOptions extends ErrorOptions {
  status?: number
}

class RouteError extends Error {
  status: number | undefined
  constructor (message: string, options: RouteErrorOptions) {
    const { status, ...params } = options
    super(message, params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, RouteError)
    }

    this.name = 'RouteError'
    // Custom debugging information
    this.status = status
  }
}

export default RouteError
