import { graphql } from 'src/gql'
import { SortFindManyCandidate_RankInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query candidateRanks($sort: SortFindManyCandidate_RankInput) {
    lookups {
      Candidate_Rank {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateRanks = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Candidate_Rank']>['many']>

const useCandidateRanks = useGraphQLDropdownOptions(
  document,
  'lookups.Candidate_Rank.many',
  { sort: SortFindManyCandidate_RankInput.OrderAsc }
)

export { useCandidateRanks, document as candidateRanksDocument }
