import React from 'react'
import { useContractDetailsById } from 'src/queries'

interface BodyProps {
  id: string
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const Contract = useContractDetailsById(id)
  const status = Contract?._status?.name
  return (
    <h2 className='text-uppercase fw-bold'>{status}</h2>
  )
}

export default Body
