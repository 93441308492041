import React from 'react'
import useSearch from '../../components/GlobalSearch/useSearch'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import SearchItem from '../../components/GlobalSearch/SearchItem'
import PreloaderSplashScreen from '../../components/theme/PreloaderSplashScreen'

interface ResultsProps {
  term: string
}

const Results: React.FC<ResultsProps> = (props) => {
  const { term } = props
  const { items, status, isFetchingNextPage, hasNextPage, fetchNextPage } = useSearch({ term, page: 1, perPage: 15 })

  if (status === 'loading') {
    return <PreloaderSplashScreen />
  }

  if (status === 'success' && items.length === 0) {
    return (
      <Row>
        <Col className='text-center'>
          No Results Found
        </Col>
      </Row>
    )
  }

  const handleLoadMore = (): void => {
    void fetchNextPage()
  }

  return (
    <>
      <Row>
        <Col>
          {items?.map(item => {
            if (item.url === undefined || item.url === null) {
              return null
            }

            return (
              <Card className='my-3' key={item._id}>
                <CardBody className='p-2'>
                  <Link to={item.url} className='text-reset text-decoration-none'>
                    <SearchItem item={item} />
                  </Link>
                </CardBody>
              </Card>
            )
          })}
        </Col>
      </Row>
      <Row>
        <Col className='text-center'>
          {isFetchingNextPage
            ? 'Loading more...'
            : hasNextPage === true
              ? <Button color='link' onClick={handleLoadMore}>Load More</Button>
              : 'Nothing more to load'}
        </Col>
      </Row>
    </>
  )
}

export default Results
