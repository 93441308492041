import { graphql } from 'src/gql'
import { SortFindManyCandidate_StatusInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query candidateStatuses($sort: SortFindManyCandidate_StatusInput) {
    lookups {
      Candidate_Status {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateStatuses = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Candidate_Status']>['many']>

const useCandidateStatuses = useGraphQLDropdownOptions(
  document,
  'lookups.Candidate_Status.many',
  { sort: SortFindManyCandidate_StatusInput.OrderAsc }
)

export { useCandidateStatuses, document as candidateStatusesDocument }
