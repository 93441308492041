import { GraphQLClient } from 'graphql-request'
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { QueryKey, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

const apiPrefix = process.env.REACT_APP_API_URL ?? 'http://localhost:4000'
const url = `${apiPrefix}/graphql`
const client = new GraphQLClient(url, {
  requestMiddleware: async (request) => {
    let user: CognitoUserSession | undefined
    try {
      user = await Auth.currentSession()
    } catch (e) {
      if (e instanceof Error && e.message === 'No current user') {
        return request
      }
    }

    if (user === undefined) {
      throw new Error('User is not authenticated')
    }
    const token = user.getAccessToken().getJwtToken()
    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: token
      }
    }
  }
})

export type UseGraphqlOptions<Data> = Parameters<typeof useQuery<Data, unknown, Data>>[2]

export function useGraphQL<TResult, TVariables> (
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
  options?: UseGraphqlOptions<TResult>
): UseQueryResult<TResult, any> {
  return useQuery([(document.definitions[0] as any).name.value, variables], async ({ queryKey }) => {
    return await client.request<TResult, any>(
      document,
      queryKey[1] ?? undefined
    )
  },
  options
  )
}

export function getQueryKey<TResult, TVariables> (
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables
): QueryKey {
  return [(document.definitions[0] as any).name.value, variables]
}

type InvalidateQueryByDocument<TVariables> = (variables?: TVariables) => Promise<void>

export function useInvalidateQueryByDocument<TResult, TVariables> (
  document: TypedDocumentNode<TResult, TVariables>
): InvalidateQueryByDocument<TVariables> {
  const queryClient = useQueryClient()
  return async (variables?: TVariables) => {
    const queryKey = getQueryKey(document, variables)
    return await queryClient.invalidateQueries({ queryKey })
  }
}
