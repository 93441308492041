import React from 'react'
import RemoveModal from '../../../theme/Models/RemoveModal'
import { attachmentsDocument, useMutateRemoveAttachment } from 'src/queries/attachments'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'

interface RemoveProps {
  attachment?: {
    id: string
    filename: string
  } | undefined
  link?: any
  type?: string
  toggle: () => void
}

const Remove: React.FC<RemoveProps> = (props) => {
  const { attachment, toggle, link, type } = props
  const { id, filename } = attachment ?? {}
  const remove = useMutateRemoveAttachment()
  const listInvalidation = useInvalidateQueryByDocument(attachmentsDocument)

  const toggleWrapper = (): void => {
    toggle()
    remove.reset()
  }

  const onRemove = (): void => {
    remove.mutate({ id }, {
      onSuccess: () => {
        void listInvalidation({ filter: { link, type } })
      }
    })
    toggleWrapper()
  }

  return (
    <RemoveModal
      isOpen={id !== undefined}
      onRemove={onRemove}
      toggle={toggleWrapper}
      error={{
        error: 'There was an error removing the attachment! Please Try again.',
        details: String(remove.error) ?? '',
        show: remove.error !== null
      }}
      title='Delete Attachment'
    >
      <p>Are you sure you want to delete the following attachment?</p>
      <p><b>{filename}</b></p>
    </RemoveModal>
  )
}

export default Remove
export { Remove }
