import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query ContractNotesById($id: MongoID!) {
    cat {
      contract {
        byId(_id: $id) {
          _id
          notes
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractNotesById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['contract']>['byId']>

const useContractNotesById = (id: string): ContractNotesById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const Contract = data?.cat?.contract?.byId ?? undefined
  return Contract
}

export { useContractNotesById, document as ContractNotesByIdDocument }
