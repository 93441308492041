import React, { useEffect } from 'react'
import { GetRowProps, Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useCardContext } from 'src/components/Card/CardContext'
import { useCandidates, Candidates } from 'src/queries/cat/candidate/useCandidates'

interface BodyProps {
  id: number
}

const getRowProps: GetRowProps<Candidates[0]> = (row) => {
  const rowColor = row.original._substatus?.color ?? undefined
  const className = rowColor !== undefined ? `table-${rowColor}` : undefined
  return {
    className
  }
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const candidates = useCandidates({ position: id }, typeof id === 'number')
  const card = useCardContext()
  const count = candidates.length
  useEffect(() => {
    card.setTitle(`Candidates (${count})`)
  }, [count])
  return (
    <Table
      storageKey='detailed-position-candidates'
      options={{
        data: candidates,
        columns,
        initialState: {
          sorting: [{ id: 'name', desc: true }]
        },
        meta: { getRowProps }
      }}
    />
  )
}

export default Body
