import { graphql } from 'src/gql'
import { SortFindManyPosition_PriorityInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query positionPriorities($sort: SortFindManyPosition_PriorityInput) {
    lookups {
      Position_Priority {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type PositionPriorities = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Position_Priority']>['many']>

const usePositionPriorities = useGraphQLDropdownOptions(
  document,
  'lookups.Position_Priority.many',
  { sort: SortFindManyPosition_PriorityInput.NameAsc }
)

export { usePositionPriorities, document as positionPrioritiesDocument }
