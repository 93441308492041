import { graphql } from 'src/gql'
import { ContractsQueryVariables, SortFindManyCat_ContractInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL, UseGraphqlOptions } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query contracts($sort: SortFindManyCat_ContractInput, $withPositionCount: Boolean!) {
    cat {
      contract {
        many(sort: $sort) {
          _id
          name
          abbreviation
          _portfolio_manager {
            name
          }
          _program_manager {
            name
          }
          position_count @include(if: $withPositionCount)
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type Contracts = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['contract']>['many']>

const useContracts = (variables?: ContractsQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): Contracts => {
  const internalVariables: ContractsQueryVariables = Object.assign({
    withPositionCount: false,
    sort: SortFindManyCat_ContractInput.NameAsc,
    limit: 1000
  }, variables)
  const { data } = useGraphQL(document, internalVariables, options)
  return data?.cat?.contract?.many ?? []
}

export { useContracts, document as contractsDocument }
