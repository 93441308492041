import React from 'react'
import { Col } from 'reactstrap'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { ContractPositionCountsById } from 'src/queries'

interface ListProps {
  counts: ContractPositionCountsById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { counts } = props
  if (counts === undefined) {
    return (
      <div className='placeholder-glow'>
        <Col className='col-12 placeholder' data-testid='placeholder' />
        <Col className='col-12 placeholder' data-testid='placeholder' />
        <Col className='col-12 placeholder' data-testid='placeholder' />
      </div>
    )
  }
  const section: Array<Omit<ItemProps, 'id'>> = counts.map(item => {
    return {
      title: item?.status ?? '',
      value: item?.count ?? ''
    }
  }) ?? []
  const dlProps = {
    className: 'row-cols-1',
    suspended: counts === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
