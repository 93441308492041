import { uniqBy } from 'lodash'

const parseMultipleSpaces = (text: string): string => {
  const reg = / {2,}/g
  const lines = Array.from(text.matchAll(reg))
  const toReplace = uniqBy(lines, 0)
  toReplace.forEach((value) => {
    const rawValue = value['0']
    text = text.replaceAll(rawValue, '&nbsp;'.repeat(rawValue.length - 1) + ' ')
  })
  return text
}

const parseLineBreaks = (text: string): string => {
  const lines = text.split('\n')
  if (lines.length > 1) {
    text = lines.reduce((acc, line) => {
      return acc + '<p>' + line + '</p>'
    }, '')
  }
  return text
}

const parsePlainText = (text: string): string => {
  text = parseMultipleSpaces(text)
  text = parseLineBreaks(text)
  return text
}

export default parsePlainText
export { parseMultipleSpaces, parseLineBreaks }
