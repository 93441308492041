import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateAnalyticsCreateOneMutation, MutateAnalyticsCreateOneMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateAnalyticsCreateOne($record: CreateOneAnalyticsInput!) {
    analytics {
      createOne(record: $record) {
        recordId
      }
    }
  }
`)

export type UseMutateAnalyticsCreateOneReturn = UseMutationResult<
MutateAnalyticsCreateOneMutation,
unknown,
MutateAnalyticsCreateOneMutationVariables
>

const useMutateAnalyticsCreateOne = (): UseMutateAnalyticsCreateOneReturn => {
  return useGraphMutation(document)
}

export { useMutateAnalyticsCreateOne, document as mutatePositionByIdDocument }
