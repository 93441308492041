import * as Yup from 'yup'
import isValid from 'date-fns/isValid'

const parseDate = (date: string | Date | undefined): Date | undefined => {
  if (date === undefined) {
    return undefined
  }
  const newDate = typeof date === 'string' ? new Date(date) : date
  if (!isValid(newDate)) {
    return undefined
  }
  return newDate
}

const msgs = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
  startBeforeEnd: 'End date must be after start date.',
  optionOrder: 'Option dates must be listed in order!',
  startAfterPreviousEnd: (opt: 'base' | 'previous') => {
    const text = opt === 'base' ? 'base' : 'previous option'
    return `Start date must be after the ${text} ends.`
  }
}

const validateSchema = Yup.object().shape({
  pop: Yup.object().shape({
    base: Yup.object().shape({
      start: Yup.date().nullable().typeError(msgs.required).when('end', (keys, schema) => {
        const endDate = parseDate(keys[0])
        if (endDate !== undefined) {
          return schema.required(msgs.required)
        }
        return schema
      }).label('Start Date'),
      end: Yup.date().nullable().typeError(msgs.required).min(Yup.ref('start'), msgs.startBeforeEnd).label('End Date')
    }),
    options: Yup.array()
      .test('dateRanges', msgs.optionOrder, function (list: Array<{ start?: Date, end?: Date }> | undefined) {
        if (list === undefined || list.length === 0) {
          return true
        }
        const parsedList = list.map((item) => {
          const start = parseDate(item.start)
          const end = parseDate(item.end)
          return {
            start,
            end
          }
        })
        const errors = parsedList.map((item, index) => {
          let prev = parsedList[index - 1]
          if (index === 0) {
            prev = {
              start: parseDate(this.parent.base.start),
              end: parseDate(this.parent.base.end)
            }
          }
          const prevEndBeforeStart = item.start === undefined ||
            prev.end === undefined ||
            prev.end > item.start
          console.log({
            index,
            prev,
            item,
            prevEndBeforeStart
          })
          if (prevEndBeforeStart) {
            return new Yup.ValidationError(
              msgs.startAfterPreviousEnd(index === 0 ? 'base' : 'previous'),
              item.start,
            `pop.options[${index}].start`
            )
          }
          return null
        }).filter(Boolean)
        return this.createError({
          message: () => errors
        })
      })
      .of(
        Yup.object().shape({
          start: Yup.date().typeError(msgs.required).nullable().required(msgs.required).label('Start Date'),
          end: Yup.date().typeError(msgs.required).nullable().required(msgs.required).min(Yup.ref('start'), msgs.startBeforeEnd).label('End Date')
        })
      )
  })
})

export default validateSchema
