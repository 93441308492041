import React, { useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Card as BSCard, CardTitle, Col, Row } from 'reactstrap'
import CardError from './CardError'
import PrimaryAction, { PrimaryActionProps } from './PrimaryAction'
import SecondaryActions, { SecondaryActionsProps } from './SecondaryActions'
import CardContext, { Colors, useCardContext } from './CardContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from 'src/hooks/useAuth'

interface CustomPrimaryActionProps {
  key: string
  render: () => React.ReactNode
  permissionKey?: string
}

interface CardProps {
  title: string
  titleAction?: PrimaryActionProps
  primaryAction?: PrimaryActionProps | CustomPrimaryActionProps | Array<PrimaryActionProps | CustomPrimaryActionProps>
  secondaryActions?: SecondaryActionsProps['actions']
  children: React.ReactNode
  color?: Colors
  collapsible?: boolean
  collapsed?: boolean
  onCollapse?: () => void
  'data-testid'?: string
}

const backgroundColorMap: { [key: NonNullable<Colors>]: NonNullable<Colors> } = {
  primary: 'white',
  secondary: 'white',
  success: 'white',
  danger: 'white',
  warning: 'secondary',
  info: 'secondary',
  light: 'secondary',
  dark: 'white'
}

const createRandomKey = (): string => {
  return new Date().toString()
}

const InternalCard: React.FC<CardProps> = (props) => {
  const { titleAction, secondaryActions = [], children, collapsed, collapsible, onCollapse } = props
  const { color = 'white', title } = useCardContext()
  const titleColor = `text-${backgroundColorMap[color]}`
  const borderColor = `border-${backgroundColorMap[color]}`
  const { app: { checkPermissions } } = useAuth()
  const childrenKey = useRef(createRandomKey())
  let primaryActions = props.primaryAction ?? []
  primaryActions = Array.isArray(primaryActions)
    ? primaryActions
    : [primaryActions]

  const resetErrorBoundary = (): void => {
    childrenKey.current = createRandomKey()
  }

  return (
    <BSCard body className={`text-bg-${color}`} style={{ minHeight: '100%' }} data-testid={props['data-testid']}>
      <CardTitle className={`d-flex border-bottom ${borderColor} pb-1 border-opacity-50`}>
        <Col xxl='auto' className={`text-uppercase me-auto fs-6 fw-semibold ${titleColor}`}>
          {collapsible === true && (
            <FontAwesomeIcon icon={collapsed === true ? faCirclePlus : faCircleMinus} onClick={() => onCollapse?.()} className='me-2' role='button' aria-label='Toggle Card' />
          )}
          {title}
          {titleAction !== undefined
            ? (
              <span className='ms-2'><PrimaryAction {...titleAction} /></span>
              )
            : null}
        </Col>
        <Col xxl='auto'>
          <Row className='gx-3 align-items-center'>
            {Array.isArray(primaryActions)
              ? primaryActions.map(p => {
                if (p.permissionKey !== undefined && !checkPermissions(p.permissionKey)) {
                  return null
                }
                return (
                  <Col key={p.key}>
                    {'render' in p
                      ? p.render()
                      : (
                        <PrimaryAction {...p} />
                        )}
                  </Col>
                )
              })
              : null}
            {Array.isArray(secondaryActions) && secondaryActions.length > 0
              ? (
                <Col>
                  <SecondaryActions actions={secondaryActions} />
                </Col>
                )
              : null}
          </Row>
        </Col>
      </CardTitle>
      <ErrorBoundary
        fallbackRender={(fallback) => {
          return <CardError errorBoundary={fallback} title={title} />
        }}
        onReset={resetErrorBoundary}
      >
        {(collapsed === false || collapsed === undefined) && children}
      </ErrorBoundary>
    </BSCard>
  )
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <CardContext title={props.title} color={props.color}>
      <InternalCard {...props} />
    </CardContext>
  )
}

export default Card
