import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Uploaded from './Uploaded'
import Icon from './Icon'
import Menu, { MenuActionProps } from './Menu'

export interface AttachmentProps extends MenuActionProps {
  _id: string
  filename: string
  subtype?: {
    name?: string | null
  } | null
  updatedAt?: string | null
  url: string
  _updatedBy?: {
    name?: string | null
  } | null
  editPermissionKey?: string
}

const Attachment: React.FC<AttachmentProps> = (props) => {
  const { _id, filename, updatedAt, url, _updatedBy, onRemove, onEdit, editPermissionKey } = props
  const subtype = typeof props.subtype?.name === 'string' ? ` - ${props.subtype.name}` : ''

  return (
    <Card className='my-2' data-testid='attachment'>
      <CardBody className='py-1'>
        <Row>
          <Col className='align-self-center'>
            <div className='clearfix'>
              <span className='align-middle'>
                <Icon filename={filename} />
                <a role='link' target='_blank' rel='noopener noreferrer' className='text-reset' href={url}>
                  <b>
                    {filename}{subtype}
                  </b>
                </a>
                <br />
                <Uploaded date={updatedAt} />{', '}
                By: {_updatedBy?.name ?? 'Unknown'}
              </span>
            </div>
          </Col>
          <Col xs='auto' className='align-self-center text-end'>
            <Menu _id={_id} filename={filename} onRemove={onRemove} onEdit={onEdit} editPermissionKey={editPermissionKey} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Attachment
