import { graphql } from 'src/gql'
import { DistributionsOverviewQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { UseGraphqlOptions, useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query distributionsOverview($filter: FilterFindManyDistributionInput, $limit: Int) {
    cat {
      distribution {
        many(filter: $filter, limit: $limit) {
          _id
          _category {
            name
          }
          _subcategory {
            _id
            name
          }
          date_posted
          candidate_count
          _position {
            _id
            id
            title
            bill_rate {
              max
            }
            salary {
              max
            }
            _status {
              _id
              name
            }
            _contract {
              _id
              abbreviation
            }
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type DistributionsOverview = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['distribution']>['many']>

const useDistributionsOverview = (varables?: DistributionsOverviewQueryVariables, options?: UseGraphqlOptions<TData>): DistributionsOverview => {
  const internal = {
    varables: Object.assign({ limit: 1000 }, varables),
    options: Object.assign({}, options)
  }
  const { data } = useGraphQL(document, internal.varables, internal.options)
  return data?.cat?.distribution?.many ?? []
}

export { useDistributionsOverview, document as distributionsOverviewDocument }
