import { graphql } from 'src/gql'
import { CandidatesByNameQuery, CandidatesByNameQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query candidatesByName($filter: FilterFindManyCandidateInput) {
    cat {
      candidate {
        many(filter: $filter) {
          _id
          name {
            first
            last
          }
          _position {
            id
            title
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidatesByName = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['many']>

type Options = Parameters<typeof useGraphQL<CandidatesByNameQuery, CandidatesByNameQueryVariables>>[2]

interface CandidatesByNameReturn {
  data: CandidatesByName
  isLoading: boolean
  isFetched: boolean
  enabled: boolean
}

interface Name {
  first?: string | null
  last?: string | null
}

const useCandidatesByName = (name?: Name | null, options?: Options): CandidatesByNameReturn => {
  const first = (name?.first ?? '').trim()
  const last = (name?.last ?? '').trim()
  const defaultOptions = {
    enabled: first !== '' && last !== ''
  }
  const internalOptions = Object.assign({}, defaultOptions, options)
  const { data, isLoading, isFetched } = useGraphQL(document, {
    filter: {
      _operators: {
        name: {
          first: {
            regex: `/^( *)${first}( *)$/i`
          },
          last: {
            regex: `/^( *)${last}( *)$/i`
          }
        }
      }
    }
  }, internalOptions)
  return {
    data: data?.cat?.candidate?.many ?? [],
    isLoading,
    isFetched,
    enabled: internalOptions.enabled
  }
}

export { useCandidatesByName, document as candidatesByNameDocument }
