import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'
import { SortFindManyContract_StatusInput } from 'src/gql/graphql'

const document = graphql(/* graphql */`
  query ContractStatuses ($sort: SortFindManyContract_StatusInput) {
    lookups {
      Contract_Status {
        many(sort: $sort) {
          _id
          name
          order
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractStatuses = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Contract_Status']>['many']>

const useContractStatuses = (): ContractStatuses => {
  const { data } = useGraphQL(document, { sort: SortFindManyContract_StatusInput.OrderAsc })
  const statuses = data?.lookups?.Contract_Status?.many
  return statuses ?? []
}

export { useContractStatuses, document as ContractStatusDocument }
