import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  DropdownItemProps
} from 'reactstrap'

interface ActionProps extends DropdownItemProps {
  id: string
}

export interface SecondaryActionsProps {
  actions: ActionProps[]
}

const SecondaryActions: React.FC<SecondaryActionsProps> = (props) => {
  const { actions } = props
  const [open, setOpen] = useState(false)
  const toggle = (): void => setOpen(o => !o)
  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle tag='div' role='button' data-testid='card-secondary-action-toggle'>
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </DropdownToggle>
      {open
        ? (
          <DropdownMenu>
            {actions.map((item) => {
              return <DropdownItem {...item} key={item.id} />
            })}
          </DropdownMenu>
          )
        : null}
    </Dropdown>
  )
}

export default SecondaryActions
