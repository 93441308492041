import React from 'react'
import { Card, CardBody, Row, Col, Container } from 'reactstrap'

interface DesktopProps {
  logo: string
  children: React.ReactNode
}

const Desktop: React.FC<DesktopProps> = (props) => {
  const { logo, children } = props
  return (
    <Container fluid>
      <Row className='vh-100 align-items-center justify-content-center'>
        <Col lg={6} xxl={4}>
          <Card className='ml-auto shadow-lg border-0' color='dark'>
            <CardBody>
              <div className='d-flex justify-content-center align-items-center'>
                <img
                  src={logo}
                  alt='Innovative Management Concept Logo'
                  className='w-25'
                />
              </div>
            </CardBody>
            <CardBody className='bg-white rounded-bottom'>
              <div>
                {children}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Desktop
