import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Router from './Router'

import 'bootstrap/dist/css/bootstrap.min.css'
import { AuthProvider } from './hooks/useAuth'
import configureAmplify from './amplify'
import { ErrorBoundary } from 'react-error-boundary'
import ReactErrorBoundary from './components/theme/Errors/ReactErrorBoundary'
import PreloaderSplashScreen from './components/theme/PreloaderSplashScreen'
import QueryProvider from './QueryProvider'

if (process.env.REACT_APP_START_MSW === 'true') {
  const startWorker = async (): Promise<void> => {
    const { worker } = await import('./msw/browser')
    await worker.start()
    console.info('MSW Worker Started!')
  }
  void startWorker()
}

configureAmplify()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <ErrorBoundary
      fallbackRender={(props) => <ReactErrorBoundary splashpage {...props} />}
      onReset={() => {
        window.location.replace(process.env.REACT_APP_PUBLIC_URL ?? '/')
      }}
    >
      <Suspense fallback={<PreloaderSplashScreen />}>
        <QueryProvider>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </QueryProvider>
      </Suspense>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
