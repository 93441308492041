import React from 'react'
import { Attachment, MenuActionProps } from './Attachment'
import ErrorAlert from '../theme/Errors/ErrorAlert'
import { useAttachments, Attachments } from 'src/queries/attachments'

export interface ListProps extends MenuActionProps {
  link: any
  type: string
  editPermissionKey?: string
}

type AttachmentItem = Attachments[0]

interface WithFilenameAndURL extends AttachmentItem {
  filename: string
  url: string
}

const hasFilenameAndUrl = (a: AttachmentItem | null | undefined): a is WithFilenameAndURL => {
  if (a == null || a === undefined) {
    return false
  }
  const hasFilename = a.filename !== undefined && a.filename !== null
  const hasURL = a.url !== undefined && a.url !== null
  return hasFilename && hasURL
}

let List: React.FC<ListProps> = (props) => {
  const { link, type, onRemove, onEdit, editPermissionKey } = props
  const allAttachments = useAttachments({
    filter: {
      link,
      type
    }
  })
  const attachments = allAttachments.filter(hasFilenameAndUrl)
  const missingAttachments = allAttachments.filter((a) => !hasFilenameAndUrl(a))

  return (
    <>
      <div className='text-center'>
        <ErrorAlert
          error={'Some attachments couldn\'t be load because they are missing data. Contact the system administrator for help.'}
          details={`The following attachments could not be loaded: "${missingAttachments.map(a => a._id).join('", "')}"`}
          show={missingAttachments.length > 0}
        />
      </div>
      {attachments.map(a => {
        return <Attachment key={a._id} subtype={a._subtype} _updatedBy={a._updatedBy} {...a} onRemove={onRemove} onEdit={onEdit} editPermissionKey={editPermissionKey} />
      })}
    </>
  )
}
List = React.memo(List)

export default List
