import { graphql } from 'src/gql'
import { SortFindManyDistribution_CategoryInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query distributionCategories($sort: SortFindManyDistribution_CategoryInput) {
    lookups {
      Distribution_Category {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type DistributionCategories = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Distribution_Category']>['many']>

const useDistributionCategories = useGraphQLDropdownOptions(
  document,
  'lookups.Distribution_Category.many',
  {
    sort: SortFindManyDistribution_CategoryInput.NameAsc
  }
)

export { useDistributionCategories, document as distributionCategoriesDocument }
