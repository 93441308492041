import React from 'react'
import { Col, Row } from 'reactstrap'
import UpdateDev from './cards/UpdateDev'
import Token from './cards/Token'
import DataMigrations from './cards/DataMigrations'

const Management: React.FC = (props) => {
  return (
    <>
      <Row className='mb-4'>
        <Col md='6'>
          <UpdateDev />
        </Col>
        <Col md='6'>
          <Token />
        </Col>
      </Row>
      <Row>
        <Col>
          <DataMigrations />
        </Col>
      </Row>
    </>
  )
}

export default Management
