import React, { Suspense } from 'react'
import Card from '../../../../../../components/Card'
import Body from './Body'
import List from './List'

interface PositionRatesProps {
  id: string
}

const PositionRates: React.FC<PositionRatesProps> = (props) => {
  const { id } = props
  return (
    <Card
      title='Position Rates'
    >
      <Suspense fallback={<List candidate={undefined} />}>
        <Body id={id} />
      </Suspense>
    </Card>
  )
}

export default PositionRates
