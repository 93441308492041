import React, { useEffect } from 'react'
import InputBase from './InputBase'
import { FieldHookConfig, useField } from 'formik'
import { Input as BSInput, InputGroup, InputGroupText } from 'reactstrap'
import { useIMask } from 'react-imask'
import { isNumber } from 'lodash'
import { useFieldError } from 'src/helpers/formHelpers'

type CurrencyProps = FieldHookConfig<any> & {
  label: string
  required?: boolean
}

const CURRENCY_MASK_OPTS: Parameters<typeof useIMask>[0] = {
  mask: Number,
  scale: 2,
  thousandsSeparator: ',',
  padFractionalZeros: true,
  radix: '.'
}

const Currency: React.FC<CurrencyProps> = (props) => {
  const { label, required, ...rest } = props
  const [field, meta, helpers] = useField({ type: 'number', ...rest })

  const {
    ref,
    setValue,
    unmaskedValue
  } = useIMask<HTMLInputElement>(CURRENCY_MASK_OPTS)

  useEffect(() => {
    setValue(String(meta.initialValue))
  }, [meta.initialValue])

  useEffect(() => {
    if (field.value !== unmaskedValue) {
      const num = unmaskedValue === '' ? null : Number(unmaskedValue)
      const newVal = isNumber(num) ? num : null
      void helpers.setValue(newVal)
    }
  }, [unmaskedValue, field.value])

  const handleBlur = field.onBlur
  const { error, hasError } = useFieldError(meta)

  return (
    <InputBase label={label} name={field.name} error={error}>
      <InputGroup>
        <InputGroupText>$</InputGroupText>
        <BSInput
          id={field.name}
          name={field.name}
          innerRef={ref}
          onBlur={handleBlur}
          invalid={hasError}
        />
      </InputGroup>
    </InputBase>
  )
}

export default Currency
export { Currency, CURRENCY_MASK_OPTS }
