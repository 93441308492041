import React from 'react'
import { Col, ColProps, Row } from 'reactstrap'

import DateDisplay from 'src/components/DateDisplay'
import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { CandidateCreatedUpdatedById } from 'src/queries'

interface ListProps {
  candidate: CandidateCreatedUpdatedById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { candidate } = props
  const section1: Array<Omit<ItemProps, 'id'>> = [
    {
      title: 'Created',
      value: <DateDisplay id='createdDate' display='distance' hover='system' date={candidate?.createdAt} />
    },
    { title: 'Created By', value: candidate?._createdBy?.name ?? 'Unknown' }
  ]
  const section2 = [
    {
      title: 'Updated',
      value: <DateDisplay id='updatedDate' display='distance' hover='system' date={candidate?.updatedAt} />
    },
    { title: 'Updated By', value: candidate?._updatedBy?.name ?? 'Unknown' }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: candidate === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  const colProps: ColProps = {
    md: 6,
    sm: 12
  }
  return (
    <Row>
      <Col {...colProps}>
        <DescriptionList {...dlProps}>
          {section1.map(item => {
            const key = item.title.toLowerCase().replace(' ', '-')
            return <Item {...item} key={key} id={key} />
          })}
        </DescriptionList>
      </Col>
      <Col {...colProps}>
        <DescriptionList {...dlProps}>
          {section2.map(item => {
            const key = item.title.toLowerCase().replace(' ', '-')
            return <Item {...item} key={key} id={key} />
          })}
        </DescriptionList>
      </Col>
    </Row>
  )
}

export default List
