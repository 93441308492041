import React from 'react'
import { Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap'

interface MobileProps {
  logo: string
  children: React.ReactNode
}

const Mobile: React.FC<MobileProps> = (props) => {
  const { logo, children } = props
  return (
    <>
      <Navbar color='dark' container={false} className='justify-content-center'>
        <NavbarBrand href='/' className='text-center'>
          <img
            src={logo}
            alt='Innovative Management Concept Logo'
            className='w-50'
          />
        </NavbarBrand>
      </Navbar>
      <Container fluid className='m-0'>
        <Row className='d-flex align-items-center' style={{ height: 'calc(100vh - 75px)' }}>
          <Col>
            {children}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Mobile
