import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { Alert, Button } from 'reactstrap'

interface CardErrorProps {
  errorBoundary: FallbackProps
  title: string
}

const CardError: React.FC<CardErrorProps> = (props) => {
  const { errorBoundary: { resetErrorBoundary, error }, title } = props
  const [showDetails, setDetails] = useState(false)
  const toggle = (): void => setDetails(o => !o)

  return (
    <div className='text-center p-4'>
      <FontAwesomeIcon className='text-warning mb-3' icon={faTriangleExclamation} size='6x' />
      <h4>
        There was an issue loading <b>{title}</b>. If this issue persists,
        please contact the administrator.
        <Button color='link' onClick={toggle}>
          <small className='text-link'>
            {showDetails ? 'Hide' : 'View'}{' '}
            Details
          </small>
        </Button>
      </h4>
      {showDetails
        ? (
          <Alert className='mt-2 mb-4' color='warning'>{error.message}</Alert>
          )
        : null}
      <Button color='secondary' block onClick={resetErrorBoundary}>Try again!</Button>
    </div>
  )
}

export default CardError
