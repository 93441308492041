import React, { useState } from 'react'
import { Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'

export interface ErrorAlertProps {
  show: boolean
  error: string
  details?: string
}

const ErrorAlert: React.FC<ErrorAlertProps> = (props) => {
  const { show, error, details } = props
  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = (): void => setShowDetails(o => !o)

  if (!show) {
    return null
  }

  return (
    <Alert color='warning' className='px-sm-5'>
      <FontAwesomeIcon icon={faTriangleExclamation} className='me-2' />
      {error}{' '}
      {details !== undefined
        ? (
          <>
            <span
              onClick={toggleDetails}
              role='link'
              className='text-decoration-underline cursor-pointer'
            >
              <small>
                {showDetails ? 'Hide Details' : 'Show Details'}
              </small>
            </span>
            {showDetails
              ? (
                <Alert color='light' className='mt-3 mb-0'>
                  <em>{details}</em>
                </Alert>
                )
              : null}
          </>
          )
        : null}
    </Alert>
  )
}

export default ErrorAlert
