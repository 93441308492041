import React from 'react'

const Skeleton: React.FC = (props) => {
  return (
    <div className='placeholder-glow'>
      <span className='placeholder w-100 mb-2' data-testid='placeholder' />
      <span className='placeholder w-100 mb-2' data-testid='placeholder' />
      <span className='placeholder w-100 mb-2' data-testid='placeholder' />
      <span className='placeholder w-75 mb-2' data-testid='placeholder' />
    </div>
  )
}

export default Skeleton
