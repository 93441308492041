import { faChevronLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { useCandidatesByName } from 'src/queries'

interface NamesProps {
  toggle: () => void
  isLoading?: boolean
  back: () => void
}

const Names: React.FC<NamesProps> = (props) => {
  const { toggle, back } = props
  const { handleSubmit, values } = useFormikContext<{ name: { first: string, last: string } }>()
  const { data: candidates } = useCandidatesByName(values.name)

  return (
    <>
      <Body error={{
        error: 'There was an issue adding the candidate.',
        show: false
      }}
      >
        <Row>
          <Col>
            There are some candidates with the same name below. Are you sure you still want to create this candidate?
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <ul>
              {candidates.map(c => {
                return (
                  <li key={c._id}>
                    <Link to={`/cat/candidates/${String(c._id)}`} target='_blank'>{c.name?.last}, {c.name?.first}</Link>{' '}
                    <small>({c._position?.id}) {c._position?.title}</small>
                  </li>
                )
              })}
            </ul>
          </Col>
        </Row>
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={[
          {
            text: 'Back',
            icon: faChevronLeft,
            color: 'secondary',
            onAction: back
          },
          {
            text: 'Create',
            icon: faPlus,
            color: 'primary'
          }
        ]}
      />
    </>
  )
}

export default Names
