import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import DateDisplay from 'src/components/DateDisplay'
import { CandidateDetailsById } from 'src/queries'

interface ListProps {
  candidate: CandidateDetailsById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { candidate } = props
  const section: Array<Omit<ItemProps, 'id'>> = [
    { title: 'Status', value: candidate?._status?.name },
    { title: 'Substatus', value: candidate?._substatus?.name }
  ]
  if (candidate?.rejection_date !== undefined && candidate?.rejection_date !== null) {
    section.push({
      title: 'Rejection Date',
      value: <DateDisplay id='candidateRejectionDate' date={candidate?.rejection_date} />
    })
  }
  const dlProps = {
    className: 'row-cols-1',
    suspended: candidate === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
