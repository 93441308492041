import React from 'react'
import DisplayHtml from 'src/components/Forms/RichTextEditor/DisplayHtml'
import { useDistributionNotesById } from 'src/queries'

interface BodyProps {
  id: string
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const distribution = useDistributionNotesById(id)
  return (
    <DisplayHtml value={distribution?.notes ?? ''} />
  )
}

export default Body
