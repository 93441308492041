import React, { useState } from 'react'
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons'
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useAuth } from 'src/hooks/useAuth'

export interface MenuActionProps {
  onRemove: ({ id, filename }: { id: string, filename: string }) => void
  onEdit: (value: string) => void
}

export interface MenuProps extends MenuActionProps {
  _id: string
  filename: string
  editPermissionKey?: string
}

let Menu: React.FC<MenuProps> = (props) => {
  const [open, setOpen] = useState(false)
  const { _id, filename, onRemove, onEdit, editPermissionKey } = props
  const { app: { checkPermissions } } = useAuth()

  const toggle = (): void => setOpen(o => !o)
  const remove = (): void => {
    onRemove({
      id: _id,
      filename
    })
  }
  const edit = (): void => {
    onEdit(_id)
  }

  if (editPermissionKey !== undefined && !checkPermissions(editPermissionKey)) {
    return null
  }

  return (
    <>
      <Dropdown isOpen={open} toggle={toggle}>
        <DropdownToggle
          data-toggle='dropdown'
          className='text-reset'
          color='link'
          size='sm'
          data-testid='attachment-menu-button'
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </DropdownToggle>
        <DropdownMenu end data-testid='attachment-dropdown-menu'>
          <DropdownItem onClick={edit}>
            <FontAwesomeIcon icon={faPencil} /> Edit
          </DropdownItem>
          <DropdownItem onClick={remove}>
            <FontAwesomeIcon icon={faTrash} className='text-danger' /> Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}
Menu = React.memo(Menu)

export default Menu
