import { createColumnHelper } from '@tanstack/react-table'
import DateDisplay from 'src/components/DateDisplay'
import { Attachments } from 'src/queries/attachments/useAttachments'

type TData = Attachments[0]

const columnHelper = createColumnHelper<TData>()

const columns = [
  columnHelper.accessor('filename', {
    header: 'Job Description',
    cell: (info) => {
      const value = info.getValue()
      const url = info.row.original.url
      if (typeof value === 'string' && typeof url === 'string') {
        return <a target='_blank' rel='noopener noreferrer' href={url}>{value}</a>
      }
      return value
    }
  }),
  columnHelper.accessor('_link.id', {
    id: 'positionNumber',
    header: 'Position Number'
  }),
  columnHelper.accessor('_link.title', {
    header: 'Job Title'
  }),
  columnHelper.accessor('_link._contract.abbreviation', {
    header: 'Contract'
  }),
  columnHelper.accessor('_link.keywords', {
    header: 'Keywords'
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Uploaded On',
    cell: (info) => {
      const value = info.getValue()
      if (value !== undefined && value !== null) {
        return (<DateDisplay date={value} display='system' />)
      }
      return null
    }
  })
]

export { columns }
