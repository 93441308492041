import React from 'react'
import { Input, InputGroup, InputGroupText, InputProps } from 'reactstrap'
import { SearchInputProps } from './useSearchField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface SearchFieldProps extends SearchInputProps, Omit<InputProps, keyof SearchInputProps> {}

const SearchField: React.FC<SearchFieldProps> = (props) => {
  return (
    <InputGroup>
      <Input
        bsSize='lg'
        placeholder='Search'
        className='border-end-0'
        {...props}
      />
      <InputGroupText className='text-muted bg-white border-start-0'>
        <FontAwesomeIcon size='lg' icon={faSearch} />
      </InputGroupText>
    </InputGroup>
  )
}

export default SearchField
