import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query positionCreatedUpdatedById($id: Int!) {
    cat {
      position {
        byId(_id: $id) {
          _id
          _createdBy {
            name
          }
          _updatedBy {
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type PositionCreatedUpdatedById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['position']>['byId']>

const usePositionCreatedUpdatedById = (id: number): PositionCreatedUpdatedById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'number'
  })
  const position = data?.cat?.position?.byId ?? undefined
  return position
}

export { usePositionCreatedUpdatedById, document as positionCreatedUpdatedByIdDocument }
