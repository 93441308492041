import { DropdownOption } from 'src/components/Forms'
import { graphql } from 'src/gql'
import { SortFindManyCandidate_SubstatusInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query candidateSubstatuses($sort: SortFindManyCandidate_SubstatusInput, $filter: FilterFindManyCandidate_SubstatusInput) {
    lookups {
      Candidate_Substatus {
        many(sort: $sort, filter: $filter) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateSubstatuses = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Candidate_Substatus']>['many']>

const defaultHandler = useGraphQLDropdownOptions(
  document,
  'lookups.Candidate_Substatus.many',
  {
    sort: SortFindManyCandidate_SubstatusInput.OrderAsc
  }
)

const useCandidateSubstatuses = (status?: string, enabled?: boolean): DropdownOption[] => {
  return defaultHandler(
    { filter: { status } },
    { enabled }
  )
}

export { useCandidateSubstatuses, document as candidateSubstatusesDocument }
