import React, { useEffect } from 'react'
import { Table } from '@imcva/reactstrap-table'
import { Columns } from './columns'
import { useCardContext } from 'src/components/Card/CardContext'
import { useContracts } from 'src/queries'

interface CardBodyProps {
  columns: Columns
}

const Body: React.FC<CardBodyProps> = (props) => {
  const { columns } = props
  const contracts = useContracts({ withPositionCount: true })
  const card = useCardContext()
  const count = contracts.length
  useEffect(() => {
    card.setTitle(`Contracts (${String(count)})`)
  }, [count])
  return (
    <Table storageKey='cat-contracts' options={{ data: contracts, columns, initialState: { sorting: [{ id: 'abbreviation', desc: false }] } }} />
  )
}

export default Body
