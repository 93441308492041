import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { Contracts } from 'src/queries'

type TData = Contracts[0]

const columnHelper = createColumnHelper<TData>()

export type Columns = Array<ReturnType<typeof columnHelper.accessor>>

const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: (info) => {
      const value = info.getValue()
      const id = info.row.original._id
      const name = ['', null, undefined].includes(value) ? '(No Name)' : value
      if (id !== undefined) {
        return (
          <Link to={`/cat/contracts/${String(id)}`}>{name}</Link>
        )
      }
      return value
    }
  }),
  columnHelper.accessor('abbreviation', {
    id: 'abbreviation',
    header: 'Abbreviation'
  }),
  columnHelper.accessor('_portfolio_manager.name', {
    header: 'Portfolio Manager'
  }),
  columnHelper.accessor('_program_manager.name', {
    header: 'Program Manager'
  }),
  columnHelper.accessor('position_count', {
    header: 'Open Positions',
    cell: (info) => {
      const value = info.getValue()
      if (value === null || value === undefined || value === 0) {
        return ''
      }
      return `${String(value) ?? ''} Open`
    }
  })
]

export { columns }
