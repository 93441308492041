import React from 'react'
import { ModalHeader } from 'reactstrap'

export interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = (props) => {
  const { title } = props
  return (
    <ModalHeader>{title}</ModalHeader>
  )
}

export default Header
