import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateCreateCandidateMutation, MutateCreateCandidateMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateCreateCandidate($record: CreateOneCandidateInput!) {
    cat {
      candidate {
        createOne(record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateCreateCandidateReturn = UseMutationResult<
MutateCreateCandidateMutation,
unknown,
MutateCreateCandidateMutationVariables
>

const useMutateCreateCandidate = (): UseMutateCreateCandidateReturn => {
  return useGraphMutation(document)
}

export { useMutateCreateCandidate, document as mutateCreateCandidateDocument }
