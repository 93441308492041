import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateCreateManyDistributionsMutation, MutateCreateManyDistributionsMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateCreateManyDistributions($records: [CreateManyDistributionInput!]!) {
    cat {
      distribution {
        createMany(records: $records) {
          recordIds
        }
      }
    }
  }
`)

export type UseMutateCreateManyDistributionsReturn = UseMutationResult<
MutateCreateManyDistributionsMutation,
unknown,
MutateCreateManyDistributionsMutationVariables
>

const useMutateCreateManyDistributions = (): UseMutateCreateManyDistributionsReturn => {
  return useGraphMutation(document)
}

export { useMutateCreateManyDistributions, document as mutateCreateManyDistributionsDocument }
