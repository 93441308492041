import React from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap'
import ErrorAlert, { ErrorAlertProps } from '../Errors/ErrorAlert'

interface RemoveModalProps {
  children: React.ReactNode
  isOpen: boolean
  onCancel?: () => void
  onRemove: () => void
  title?: string
  toggle: () => void
  error: ErrorAlertProps
}

const RemoveModal: React.FC<RemoveModalProps> = (props) => {
  const {
    children,
    isOpen,
    onCancel,
    onRemove,
    title = 'Delete',
    toggle,
    error: {
      error,
      details,
      show
    }
  } = props

  const onCancelWrapper = (): void => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered data-testid={`${title.split(' ').join('-')}-remove-modal`.toLowerCase()}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className='text-center'>
        <ErrorAlert
          error={error}
          details={details}
          show={show}
        />
        {children}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onCancelWrapper}>
          Cancel
        </Button>
        <Button color='danger' onClick={onRemove}>
          <FontAwesomeIcon icon={faTrash} /> Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RemoveModal
