import React from 'react'

import './preloadersplashscreen.css'

const PreloaderSplashScreen: React.FC = (props) => {
  return (
    <div className='w-100 vh-100 d-flex justify-content-center align-items-center bg-light bg-gradiant'>
      <div>
        <div className='preloader-ellipsis'>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  )
}

export default PreloaderSplashScreen
