import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { UseGraphqlOptions, useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query DevUpdate($id: MongoID!) {
    dev {
      update {
        byId(_id: $id) {
          _id
          status
          database {
            total
            copied
            collections {
              name
              itemCount
              status
            }
          }
          s3 {
            status
            total
            copied
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type DevUpdate = NonNullable<NonNullable<NonNullable<NonNullable<TData>['dev']>['update']>>['byId']

const useDevUpdate = (id: string, options?: UseGraphqlOptions<TData>, enabled: boolean = true): DevUpdate => {
  const { data } = useGraphQL(document, { id }, options)
  return data?.dev?.update?.byId
}

export { useDevUpdate, document as attachmentsDocument }
