import React from 'react'
import { ColProps, Col, Row } from 'reactstrap'
import classnames from 'classnames'

export interface ItemProps {
  id: string
  title: string
  value?: any
  titleProps?: ColProps
  valueProps?: ColProps
  suspended?: boolean
  colWrapper?: boolean
}

const Item: React.FC<ItemProps> = (props) => {
  const { title, titleProps, valueProps, value, suspended = false, colWrapper = true } = props

  const loadingSizes = {
    xxl: suspended ? 1 : valueProps?.xxl,
    lg: suspended ? 3 : valueProps?.lg,
    md: suspended ? 6 : valueProps?.md,
    sm: suspended ? 12 : valueProps?.sm
  }

  const content = (
    <Row className='gx-2'>
      <Col
        {...titleProps}
        data-testid='descriptionlist-title'
        className={classnames('fw-bold', titleProps?.className)}
      >
        {title}:
      </Col>
      <Col
        {...valueProps}
        {...loadingSizes}
        data-testid='descriptionlist-value'
        className={classnames('placeholder-glow', valueProps?.className)}
      >
        {suspended
          ? (
            <Col className='col-12 placeholder' data-testid='placeholder' />
            )
          : value}
      </Col>
    </Row>
  )

  if (colWrapper) {
    return (<Col>{content}</Col>)
  }
  return content
}

export { Item }
