import React, { useState } from 'react'
import { useDevUpdate } from 'src/queries/management/useDevUpdate'
import { Button, FormGroup, Input, Label, ListGroup, ListGroupItem, Progress } from 'reactstrap'
import { useUpdateDev } from 'src/queries/management/useUpdateDev'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface FromTo {
  from: string
  to: string
}

const Body: React.FC = (props) => {
  const [database, setDatabase] = useState<FromTo>({ from: '', to: '' })
  const [bucket, setBucket] = useState<FromTo>({ from: '', to: '' })
  const [id, setId] = useState<string | undefined>(undefined)
  const devUpdate = useDevUpdate(id ?? '', {
    refetchInterval: 3000,
    refetchIntervalInBackground: true,
    enabled: id !== undefined
  })
  const collections = devUpdate?.database?.collections ?? []
  const handleUpdate = (type: 'from' | 'to', setter: React.Dispatch<React.SetStateAction<FromTo>>) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setter((value) => {
        return {
          ...value,
          [type]: e.target.value
        }
      })
    }
  }
  const updateDev = useUpdateDev()
  const run = (): void => {
    updateDev.mutate({
      databaseFrom: database.from,
      databaseTo: database.to,
      bucketFrom: bucket.from,
      bucketTo: bucket.to
    }, { onSuccess: (data) => setId(data?.dev?.update?._id ?? '') })
  }
  if (devUpdate === undefined || devUpdate === null) {
    return (
      <>
        <FormGroup>
          <Label for='databaseFrom'>Database From</Label>
          <Input id='databaseFrom' type='text' value={database.from} onChange={handleUpdate('from', setDatabase)} />
        </FormGroup>
        <FormGroup>
          <Label for='databaseTo'>Database To</Label>
          <Input id='databaseTo' type='text' value={database.to} onChange={handleUpdate('to', setDatabase)} />
        </FormGroup>
        <FormGroup>
          <Label for='bucketFrom'>Bucket From</Label>
          <Input id='bucketFrom' type='text' value={bucket.from} onChange={handleUpdate('from', setBucket)} />
        </FormGroup>
        <FormGroup>
          <Label for='bucketTo'>Bucket To</Label>
          <Input id='bucketTo' type='text' value={bucket.to} onChange={handleUpdate('to', setBucket)} />
        </FormGroup>
        <Button
          block
          onClick={() => run()}
        >
          Update
        </Button>
      </>
    )
  } else {
    const db = {
      total: devUpdate.database?.total ?? 0,
      copied: devUpdate.database?.copied ?? 0,
      get progress () {
        return ((this.copied / this.total) * 100).toFixed(2)
      }
    }
    const s3 = {
      total: devUpdate.s3?.total ?? 0,
      copied: devUpdate.s3?.copied ?? 0,
      get progress () {
        return ((this.copied / this.total) * 100).toFixed(2)
      }
    }
    return (
      <div>
        <Button block onClick={() => setId('')}>Reset</Button>
        <div className='my-3'>
          Database:{' '}
          <Progress color='info' barStyle={{ color: 'black' }} value={db.progress}> {db.copied} / {db.total} ({db.progress}%) </Progress>
        </div>
        <div className='my-3'>
          Bucket:{' '}
          <Progress color='info' barStyle={{ color: 'black' }} value={s3.progress}> {s3.copied} / {s3.total} ({s3.progress}%) </Progress>
        </div>
        <div className='my-3'>
          <ListGroup>
            {collections.map((collection, index) => {
              if (collection === null) {
                return null
              }
              return (
                <ListGroupItem key={index}>
                  {collection.status === 'Complete'
                    ? (
                      <FontAwesomeIcon icon={faCheckCircle} fixedWidth className='text-success me-1' />
                      )
                    : (
                      <span className='me-4'>{' '}</span>
                      )}
                  {collection.name}{' '}
                  <small>({collection.itemCount})</small>{' '}
                </ListGroupItem>
              )
            })}
          </ListGroup>
        </div>
      </div>
    )
  }
}

export default Body
