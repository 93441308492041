import React, { useState } from 'react'
import { Offcanvas } from 'reactstrap'
import Menu from './Menu'
import { SidebarMenu } from './menustructure'

import './sidemenu.css'

interface SidemenuProps {
  menu: SidebarMenu
  isOpen: boolean
  toggleOffcanvas: () => void
}

const Sidemenu: React.FC<SidemenuProps> = (props) => {
  const { menu, isOpen, toggleOffcanvas } = props
  const [openMenu, setOpenMenu] = useState<string | undefined>()
  const toggleMenu = (id: string): void => setOpenMenu(current => {
    return current !== id ? id : undefined
  })

  return (
    <>
      <div id='sidemenu' className='d-none d-lg-flex flex-column flex-shrink-0 px-3 bg-dark text-muted'>
        <Menu menu={menu} open={openMenu} toggle={toggleMenu} />
      </div>
      <Offcanvas isOpen={isOpen} toggle={toggleOffcanvas} className='d-flex w-auto'>
        <div id='sidemenu' className='px-3 bg-dark vh-100 text-muted'>
          <Menu menu={menu} open={openMenu} toggle={toggleMenu} />
        </div>
      </Offcanvas>
    </>
  )
}

export default Sidemenu
