import React, { createContext, useContext } from 'react'
import { UserSelfQuery } from '../../gql/graphql'

import { useCognito } from './useCognito'
import { useGraphQL } from '../useGraphQL'
import { graphql } from '../../gql'

type ExcludeNullUndefined<T> = Exclude<Exclude<T, undefined>, null>

type AppUserQuery = ExcludeNullUndefined<ExcludeNullUndefined<UserSelfQuery['user']>['self']>

export interface AppUser extends Partial<AppUserQuery> {
  isAuthenticated: boolean
}

export interface AppUserContextValue {
  user: AppUser
  checkPermissions: (permissionKey: string) => boolean
  logout: () => void
}

const AppUserContext = createContext<AppUserContextValue>({
  user: { isAuthenticated: false },
  checkPermissions: () => false,
  logout: () => {}
})

const UserSelfQueryDocument = graphql(/* graphql */`
  query UserSelf {
    user {
      self {
        _id
        email
        _role {
          defaultPage
          title
          _permissions {
            _id
            key
          }
        }
        defaultPage
        createdBy
        updatedBy
      }
    }
  }
`)

const AppUserProvider: React.FC<React.PropsWithChildren> = (props) => {
  const cognito = useCognito()
  const shouldQuery = cognito.user.isAuthenticated
  const { data, remove } = useGraphQL(UserSelfQueryDocument, undefined, {
    enabled: shouldQuery
  })
  const userData = data?.user?.self
  const user = { ...userData, isAuthenticated: userData?._id !== undefined }

  const logout = (): void => {
    remove()
  }

  const checkPermissions = (permissionKey: string): boolean => {
    if (permissionKey !== undefined && ((user?._role) != null) && user?._role._permissions !== undefined) {
      const permission = user._role._permissions.find((p: any) => p?.key === permissionKey)
      if (permission !== undefined) {
        return true
      }
    }
    return false
  }

  return (
    <AppUserContext.Provider value={{ user, checkPermissions, logout }}>
      {props.children}
    </AppUserContext.Provider>
  )
}

const useAppUser = (): AppUserContextValue => {
  const value = useContext(AppUserContext)
  if (value === undefined) {
    throw new Error('useAppUser must be used within an AppUserProvider')
  }
  return value
}

export { useAppUser, AppUserProvider, AppUserContext }
