import React, { useEffect, useState } from 'react'
import { useDebounce } from '../../hooks/useDebounce'

export interface SearchInputProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onFocus: React.FocusEventHandler<HTMLInputElement>
  onBlur: React.FocusEventHandler<HTMLInputElement>
  value: string
}

export interface UseSearchFieldReturn {
  inputProps: SearchInputProps
  debouncedValue: string
  hasSearchValue: boolean
  focused: boolean
}

const useSearchField = (defaultSearch: string = '', defaultDebounce: number = 200): UseSearchFieldReturn => {
  const [search, setSearch] = useState(defaultSearch)
  const [focused, setFocused] = useState(false)
  const debouncedValue = useDebounce(search, defaultDebounce)
  const onChange: SearchInputProps['onChange'] = (event) => {
    const value = event.target.value
    setSearch(value)
  }

  useEffect(() => {
    setSearch(defaultSearch)
  }, [defaultSearch])

  const onFocus: SearchInputProps['onFocus'] = (event) => {
    setFocused(true)
  }

  const onBlur: SearchInputProps['onBlur'] = (event) => {
    setFocused(false)
  }

  const hasSearchValue = debouncedValue !== undefined && debouncedValue !== null && debouncedValue !== ''

  return {
    inputProps: {
      onChange,
      value: search,
      onFocus,
      onBlur
    },
    debouncedValue,
    hasSearchValue,
    focused
  }
}

export default useSearchField
