import React from 'react'

import { Navigate } from 'react-router-dom'
import RouteError from '../../helpers/RouteError'
import { useAuth } from '../useAuth'

interface RequireUserProps {
  children: React.ReactNode
  permissionKey?: string
}

const RequireUser: React.FC<RequireUserProps> = (props) => {
  const { children, permissionKey } = props
  const { app: { checkPermissions }, isAuthenticated } = useAuth()

  if (isAuthenticated && permissionKey === undefined) {
    return (
      <>
        {children}
      </>
    )
  }

  if (isAuthenticated && permissionKey !== undefined) {
    if (checkPermissions(permissionKey)) {
      return (
        <>
          {children}
        </>
      )
    }
    throw new RouteError('You are not authorized.', { status: 301 })
  }

  return (
    <Navigate to='login' />
  )
}

export { RequireUser }
