import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { currency } from 'src/helpers/displayhelpers'
import { CandidateRatesById } from 'src/queries'

interface ListProps {
  candidate: CandidateRatesById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { candidate } = props
  const position = candidate?._position
  const section1: Array<Omit<ItemProps, 'id'>> = [
    { title: 'Bill Rate (Min)', value: currency(position?.bill_rate?.min) },
    { title: 'Bill Rate (Max)', value: currency(position?.bill_rate?.max) },
    { title: 'Salary (Min)', value: currency(position?.salary?.min) },
    { title: 'Salary (Max)', value: currency(position?.salary?.max) }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: candidate === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section1.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
