import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { yesno } from 'src/helpers/displayhelpers'
import DateDisplay from '../../../../../../components/DateDisplay'
import { CandidateDetailsById } from 'src/queries'

interface ListProps {
  candidate: CandidateDetailsById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { candidate } = props
  const name = [candidate?.name?.last, candidate?.name?.first].filter(v => typeof v === 'string').join(', ')

  const section: Array<Omit<ItemProps, 'id'>> = [
    { title: 'Name', value: name },
    {
      title: 'Received On',
      value: <DateDisplay id='received_on' date={candidate?.received_on} />
    },
    { title: 'Location', value: candidate?.location },
    { title: 'Type', value: candidate?._type?.name },
    { title: 'Clearance', value: candidate?._clearance?.name },
    { title: 'Reapply', value: yesno(candidate?.reapply) }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: candidate === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
