import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query candidateRatesById($id: MongoID!) {
    cat {
      candidate {
        byId(_id: $id) {
          _id
          bill_rate {
            min
            max
          }
          salary {
            min
            max
          }
          _position {
            bill_rate {
              min
              max
            }
            salary {
              min
              max
            }
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateRatesById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['byId']>

const useCandidateRatesById = (id: string): CandidateRatesById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const candidate = data?.cat?.candidate?.byId ?? undefined
  return candidate
}

export { useCandidateRatesById, document as candidateRatesByIdDocument }
