
import { useIsRestoring } from '@tanstack/react-query'
import React, { PropsWithChildren } from 'react'
import PreloaderSplashScreen from 'src/components/theme/PreloaderSplashScreen'

const IsRestoring: React.FC<PropsWithChildren> = (props) => {
  const restoring = useIsRestoring()
  if (restoring) {
    return <PreloaderSplashScreen />
  }
  return (
    <>
      {props.children}
    </>
  )
}

export default IsRestoring
