import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Details from './cards/Details'
import Status from './cards/Status'
import Rates from './cards/Rates'
import JobDescription from './cards/JobDescription'
import RouteError from '../../../../helpers/RouteError'
import { usePositionDetailsById } from 'src/queries'
import Attachments from '../../../../components/Attachments'
import Notes from './cards/Notes'
import CreatedUpdated from './cards/CreatedUpdated'
import Candidates from './cards/Candidates'
import Distributions from './cards/Distributions'

const Position: React.FC = (props) => {
  const params = useParams()
  const id = Number(params.id)
  if (isNaN(id)) {
    throw new RouteError('Invalid Position ID.', { status: 404 })
  }
  const position = usePositionDetailsById(id)
  if (position === null) {
    throw new RouteError(`Position "${id}" does not exist.`, { status: 404 })
  }
  return (
    <>
      <Row className='mb-4'>
        <Col md='9'>
          <Details id={id} />
        </Col>
        <Col md={3} className='d-none d-md-flex'>
          <Status id={id} />
        </Col>
      </Row>
      <Row className='gy-4'>
        <Col md='7'>
          <Row className='mb-4 gy-4'>
            <Col md='6'>
              <Rates id={id} />
            </Col>
            <Col md='6'>
              <JobDescription id={id} />
            </Col>
          </Row>
          <Row className='mb-4 gy-4'>
            <Col xs='12'>
              <Candidates id={id} />
            </Col>
            <Col xs='12'>
              <Distributions id={id} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className='mb-4 gy-4'>
            <Col xs='12'>
              <Attachments link={id} type='6026a8985391d13c5088002c' model='Position' editPermissionKey='CAT_EDIT' />
            </Col>
            <Col xs='12'>
              <Notes id={id} />
            </Col>
            <Col xs='12'>
              <CreatedUpdated id={id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Position
