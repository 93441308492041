import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'
import { PositionsQueryVariables, SortFindManyPositionInput } from '../../../../gql/graphql'

const document = graphql(/* graphql */`
  query positions($filter: FilterFindManyPositionInput, $limit: Int, $sort: SortFindManyPositionInput) {
    cat {
      position {
        many(filter: $filter, limit: $limit, sort: $sort) {
          _id
          id
          title
          _status {
            name
          }
          _contract {
            abbreviation
          }
          _program_manager {
            name
          }
          bill_rate {
            min
            max
          }
          salary {
            min
            max
          }
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type Positions = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['position']>['many']>

const usePositions = (filter?: PositionsQueryVariables['filter'], enabled: boolean = true): Positions => {
  const { data } = useGraphQL(document, {
    sort: SortFindManyPositionInput.IdDesc,
    filter,
    limit: 2000
  }, {
    enabled
  })
  return data?.cat?.position?.many ?? []
}

export { usePositions, document as positionsDocument }
