import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React, { Suspense } from 'react'
import { DatePicker, Dropdown, DropdownOption } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { useDistributionSubcategories } from 'src/queries'
import { Values } from './FormContext'
import FieldSuspense from 'src/components/Forms/FieldSuspense'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
  categories?: DropdownOption[]
}

const Subcategories: React.FC = () => {
  const { values } = useFormikContext<Values>()
  const subcategories = useDistributionSubcategories(values.category)
  return <Dropdown name='subcategory' label='Subcategory' options={subcategories ?? []} />
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave, categories } = props
  const { handleSubmit, setFieldValue } = useFormikContext()
  const handleStatusChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    void setFieldValue('subcategory', null)
    return e.target.value
  }
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the distribution details.',
        show: hasError ?? false
      }}
      >
        <Dropdown name='category' label='Category' options={categories ?? []} onChange={handleStatusChange} />
        <Suspense fallback={<FieldSuspense label='Subcategory' />}>
          <Subcategories />
        </Suspense>
        <DatePicker type='date' name='date_posted' label='Date Posted/Sent' />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
