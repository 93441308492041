import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { currency } from '../../../../../../helpers/displayhelpers'
import DateDisplay from '../../../../../../components/DateDisplay'
import { isValid } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons'
import { Candidates } from 'src/queries'

const columnHelper = createColumnHelper<Candidates[0]>()

const columns = [
  columnHelper.accessor('attachments_count', {
    header: 'Attachments',
    cell: (info) => {
      const value = info.getValue() ?? 0
      return value > 0
        ? (
          <span className='px-3'>{value} <FontAwesomeIcon size='sm' icon={faPaperclip} /></span>
          )
        : null
    }
  }),
  columnHelper.accessor((r) => {
    const name = [r.name?.last, r.name?.first].filter(v => typeof v === 'string').join(', ')
    return name ?? 'Unknown'
  }, {
    id: 'name',
    header: 'Name',
    cell: (info) => {
      const value = info.getValue()
      const id = info.row.original._id
      if (id !== undefined) {
        return (
          <Link to={`/cat/candidates/${String(id)}`}>{value}</Link>
        )
      }
      return value
    }
  }),
  columnHelper.accessor('_distribution._subcategory.name', {
    header: 'Distribution'
  }),
  columnHelper.accessor('bill_rate.max', {
    header: 'Bill Rate (Max)',
    cell: (info) => {
      const value = info.getValue()
      const num = Number(value)
      return isNaN(num) ? value : currency(num)
    }
  }),
  columnHelper.accessor('salary.max', {
    header: 'Salary (Max)',
    cell: (info) => {
      const value = info.getValue()
      const num = Number(value)
      return isNaN(num) ? value : currency(num)
    }
  }),
  columnHelper.accessor('_substatus.name', {
    header: 'Substatus'
  }),
  columnHelper.accessor('_status.name', {
    header: 'Status'
  }),
  columnHelper.accessor('round_1', {
    header: 'Round 1',
    cell: (info) => {
      const value = info.getValue()
      const date = new Date(value)
      if (isValid(date)) {
        return <DateDisplay date={value} />
      }
      return value
    }
  }),
  columnHelper.accessor('round_2', {
    header: 'Round 2',
    cell: (info) => {
      const value = info.getValue()
      const date = new Date(value)
      if (isValid(date)) {
        return <DateDisplay date={value} />
      }
      return value
    }
  }),
  columnHelper.accessor('round_3', {
    header: 'Round 3',
    cell: (info) => {
      const value = info.getValue()
      const date = new Date(value)
      if (isValid(date)) {
        return <DateDisplay date={value} />
      }
      return value
    }
  })
]

export { columns }
