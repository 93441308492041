import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateCandidateByIdMutation, MutateCandidateByIdMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { candidateCreatedUpdatedByIdDocument } from '../useCandidateCreatedUpdatedById'

const document = graphql(/* graphql */`
  mutation mutateCandidateById($id: MongoID!, $record: UpdateByIdCandidateInput!) {
    cat {
      candidate {
        updateById(_id: $id, record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateCandidateByIdReturn = UseMutationResult<
MutateCandidateByIdMutation,
unknown,
MutateCandidateByIdMutationVariables
>

const useMutateCandidateById = (): UseMutateCandidateByIdReturn => {
  const invalidateCreatedUpdated = useInvalidateQueryByDocument(candidateCreatedUpdatedByIdDocument)
  return useGraphMutation(document, {
    onSuccess: (data) => {
      const id = data.cat?.candidate?.updateById?.recordId
      if (typeof id === 'string') {
        void invalidateCreatedUpdated({ id })
      }
    }
  })
}

export { useMutateCandidateById, document as mutateCandidateByIdDocument }
