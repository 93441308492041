import React, { useEffect } from 'react'
import InputBase from './InputBase'
import { FieldHookConfig, useField } from 'formik'
import { Input as BSInput } from 'reactstrap'
import { useIMask } from 'react-imask'
import { useFieldError } from 'src/helpers/formHelpers'

type PhoneProps = FieldHookConfig<any> & {
  label: string
  required?: boolean
}

const PHONE_MASK_OPTS: Parameters<typeof useIMask>[0] = {
  mask: '(000) 000-0000'
}

const Phone: React.FC<PhoneProps> = (props) => {
  const { label, required, ...rest } = props
  const [field, meta, helpers] = useField({ type: 'number', ...rest })

  const {
    ref,
    setValue,
    value
  } = useIMask<HTMLInputElement>(PHONE_MASK_OPTS)

  useEffect(() => {
    const value = meta.initialValue
    if (![null, undefined, ''].includes(value)) {
      setValue(String(meta.initialValue))
    }
  }, [meta.initialValue])

  useEffect(() => {
    if (field.value !== value) {
      void helpers.setValue(value)
    }
  }, [value, field.value])

  const handleBlur = field.onBlur
  const { error, hasError } = useFieldError(meta)

  return (
    <InputBase label={label} name={field.name} error={error}>
      <BSInput
        id={field.name}
        name={field.name}
        innerRef={ref}
        onBlur={handleBlur}
        invalid={hasError}
      />
    </InputBase>
  )
}

export default Phone
export { Phone, PHONE_MASK_OPTS }
