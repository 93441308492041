import React from 'react'
import Checkbox, { CheckboxProps } from './Checkbox'
import InputBase from './InputBase'

export type Option = CheckboxProps

interface CheckboxesProps {
  label: string
  options: Option[]
  required?: boolean
}

const Checkboxes: React.FC<CheckboxesProps> = (props) => {
  const { options, label, required } = props
  return (
    <InputBase label={label} required={required}>
      {options.map(option => {
        return <Checkbox {...option} key={option.id} />
      })}
    </InputBase>
  )
}

export default Checkboxes
