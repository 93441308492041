import {
  faBold,
  faHighlighter,
  faIndent,
  faItalic,
  faListOl,
  faListUl,
  faOutdent,
  faParagraph,
  faRedo,
  faStrikethrough,
  faUnderline,
  faUndo
} from '@fortawesome/free-solid-svg-icons'
import {
  faH1,
  faH2,
  faH3
} from '@fortawesome/pro-regular-svg-icons'
import { Editor } from '@tiptap/react'
import { RichTextButtonProps } from './Button'

export interface ButtonProps extends Omit<RichTextButtonProps, 'editor'> {}

type Buttons = ButtonProps[][]

type CreateButtons = (editor: Editor) => Buttons

const createButtons: CreateButtons = (editor) => {
  const buttons: Buttons = [
    [
      {
        title: 'Heading 1',
        onClick: ({ chain }) => chain.toggleHeading({ level: 1 }),
        disabled: ({ chain }) => chain.toggleHeading({ level: 1 }),
        icon: faH1,
        active: ['heading', { level: 1 }]
      },
      {
        title: 'Heading 2',
        onClick: ({ chain }) => chain.toggleHeading({ level: 2 }),
        disabled: ({ chain }) => chain.toggleHeading({ level: 2 }),
        icon: faH2,
        active: ['heading', { level: 2 }]
      },
      {
        title: 'Heading 3',
        onClick: ({ chain }) => chain.toggleHeading({ level: 3 }),
        disabled: ({ chain }) => chain.toggleHeading({ level: 3 }),
        icon: faH3,
        active: ['heading', { level: 3 }]
      },
      {
        title: 'Paragraph',
        onClick: ({ chain }) => chain.setParagraph(),
        icon: faParagraph,
        active: 'paragraph'
      }
    ],
    [
      {
        title: 'Bold',
        onClick: ({ chain }) => chain.toggleBold(),
        disabled: ({ chain }) => chain.toggleBold(),
        icon: faBold,
        active: 'bold'
      },
      {
        title: 'Italic',
        onClick: ({ chain }) => chain.toggleItalic(),
        disabled: ({ chain }) => chain.toggleItalic(),
        icon: faItalic,
        active: 'italic'
      },
      {
        title: 'Underline',
        onClick: ({ chain }) => chain.toggleUnderline(),
        disabled: ({ chain }) => chain.toggleUnderline(),
        icon: faUnderline,
        active: 'underline'
      },
      {
        title: 'Strikethrough',
        onClick: ({ chain }) => chain.toggleStrike(),
        disabled: ({ chain }) => chain.toggleStrike(),
        icon: faStrikethrough,
        active: 'strike'
      }
    ],
    [
      {
        title: 'Outdent',
        onClick: ({ chain, editor }) => editor.isActive('listItem') ? chain.liftListItem('listItem') : chain.outdent(),
        // disabled: ({ chain }) => chain.outdent(),
        icon: faOutdent,
        active: 'outdent'
      },
      {
        title: 'Indent',
        onClick: ({ chain, editor }) => editor.isActive('listItem') ? chain.sinkListItem('listItem') : chain.indent(),
        // disabled: ({ chain }) => chain.indent(),
        icon: faIndent,
        active: 'indent'
      }
    ],
    [
      {
        title: 'Ordered List',
        onClick: ({ chain }) => chain.toggleOrderedList(),
        disabled: ({ chain }) => chain.toggleOrderedList(),
        icon: faListOl,
        active: 'orderedList'
      },
      {
        title: 'Bullet List',
        onClick: ({ chain }) => chain.toggleBulletList(),
        disabled: ({ chain }) => chain.toggleBulletList(),
        icon: faListUl,
        active: 'bulletList'
      }
    ],
    [
      {
        title: 'Hightlight',
        onClick: ({ chain }) => chain.toggleHighlight(),
        disabled: ({ chain }) => chain.toggleHighlight(),
        icon: faHighlighter,
        active: 'undo'
      }
    ],
    [
      {
        title: 'Undo',
        onClick: ({ chain }) => chain.undo(),
        disabled: ({ chain }) => chain.undo(),
        icon: faUndo,
        active: 'undo'
      },
      {
        title: 'Redo',
        onClick: ({ chain }) => chain.redo(),
        disabled: ({ chain }) => chain.redo(),
        icon: faRedo,
        active: 'redo'
      }
    ]
  ]
  return buttons
}

export default createButtons
