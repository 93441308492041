import { Formik } from 'formik'
import React, { Suspense, SuspenseProps } from 'react'

const SUSPENDED_FORM_STATUS = 'suspended'

const EmptyFormikWrapper: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props
  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {}}
      status={SUSPENDED_FORM_STATUS}
      initialStatus={SUSPENDED_FORM_STATUS}
    >
      <div className='placeholder-glow'>
        {children}
      </div>
    </Formik>
  )
}

const FormSuspense: React.FC<SuspenseProps> = (props) => {
  const { children, fallback } = props
  return (
    <Suspense fallback={<EmptyFormikWrapper>{fallback}</EmptyFormikWrapper>}>
      {children}
    </Suspense>
  )
}

export default FormSuspense
export { SUSPENDED_FORM_STATUS }
