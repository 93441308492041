import { Formik } from 'formik'
import React from 'react'
import { UseMutateCandidateByIdReturn, candidateDetailsByIdDocument, useCandidateDetailsById, useMutateCandidateById } from 'src/queries'
import Form from './Form'
import { MutateCandidateByIdMutation } from 'src/gql/graphql'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'

type Values = Parameters<UseMutateCandidateByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const candidate = useCandidateDetailsById(id)
  if (candidate === undefined) {
    throw new Error('Could not load candidate!')
  }
  const update = useMutateCandidateById()
  const { email, phone, address } = candidate
  const initialState: Values = {
    email,
    phone,
    address
  }
  const invalidateCandidate = useInvalidateQueryByDocument(candidateDetailsByIdDocument)
  const handleSuccess = (data: MutateCandidateByIdMutation): void => {
    const id = data.cat?.candidate?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateCandidate({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const record = data
        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
