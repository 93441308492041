import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faFile } from '@fortawesome/pro-regular-svg-icons'
import Icon from './Icon'

const Skeleton: React.FC = (props) => {
  return (
    <Card className='my-2' data-testid='attachment-placeholder'>
      <CardBody className='py-1'>
        <Row>
          <Col className='align-self-center'>
            <div className='clearfix'>
              <span className='align-middle placeholder-glow col-12'>
                <Icon icon={faFile} className='text-secondary' />
                <div className='placeholder placeholder-lg col-8' />
                <br />
                <div className='placeholder col-4' />
              </span>
            </div>
          </Col>
          <Col xs='auto' className='align-self-center text-end'>
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Skeleton
