import { Formik } from 'formik'
import React from 'react'
import { candidateDetailsByIdDocument, useCandidateDetailsById, useCandidateStatuses, useCandidateSubstatuses, useMutateCandidateById } from 'src/queries'
import Form from './Form'
import { dates } from 'src/helpers/displayhelpers'
import { parse } from 'date-fns'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { MutateCandidateByIdMutation } from 'src/gql/graphql'

export type Values = Parameters<ReturnType<typeof useMutateCandidateById>['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const candidate = useCandidateDetailsById(id)
  if (candidate === undefined) {
    throw new Error('Could not load candidate!')
  }
  const candidateStatuses = useCandidateStatuses()
  useCandidateSubstatuses(candidate.status, typeof candidate.status === 'string')
  const invalidateCandidate = useInvalidateQueryByDocument(candidateDetailsByIdDocument)
  const update = useMutateCandidateById()
  const { status, substatus } = candidate
  const initialState: Values = {
    status,
    substatus,
    rejection_date: dates.systemDateOnly(candidate.rejection_date)
  }
  const handleSuccess = (data: MutateCandidateByIdMutation): void => {
    const id = data.cat?.candidate?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateCandidate({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const { status, substatus } = data
        const record = {
          status: status === '' ? null : status,
          substatus: substatus === '' ? null : substatus,
          rejection_date: parse(data.rejection_date, 'yyyy-MM-dd', new Date())
        }
        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form candidateStatuses={candidateStatuses} toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
