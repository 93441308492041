import React, { useState } from 'react'
import { ButtonProps, InputProps } from 'reactstrap'
import useDuplicates from 'src/components/Attachments/modals/hooks/useDuplicates'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { attachmentsDocument, useAttachmentSubtypes, useUploadAttachment } from 'src/queries/attachments'

interface UseUploadArgs {
  link: string
  type: string
  model: string
  onSuccess?: () => void
}

interface UseUploadFormProps {
  name: InputProps
  subtype: InputProps
  file: InputProps
  fileButton: ButtonProps
}

interface UseUploadReturn {
  canOverwrite: boolean
  canUpload: boolean
  error: string | undefined
  fileExtension: string | undefined
  fullFilename: string | undefined
  hasDuplicates: boolean
  hasFilename: boolean
  isUploading: boolean
  progress: number | undefined
  props: UseUploadFormProps
  reset: () => void
  subtypes: ReturnType<typeof useAttachmentSubtypes>
  upload: (overwrite?: boolean) => Promise<void>
}

const useUpload = (args: UseUploadArgs): UseUploadReturn => {
  const { link, type, model } = args
  const [file, setFile] = useState<File | undefined>()
  const [subtype, setSubtype] = useState<string | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [name, setName] = useState<string | undefined>('')
  const [fileExtension, setFileExtension] = useState<string | undefined>('')
  const [progress, setProgress] = useState<number | undefined>()

  const fullFilename = [name, fileExtension].filter((v) => v != null).join('.')

  const invalidateList = useInvalidateQueryByDocument(attachmentsDocument)

  const fileRef = React.useRef<HTMLInputElement>(null)

  const { hasDuplicates } = useDuplicates({ link, type, name: fullFilename })
  const subtypes = useAttachmentSubtypes({ type })
  const uploadHook = useUploadAttachment()

  const hasFile = file !== undefined
  const hasFilename = (name !== '' && name !== undefined) || !hasFile
  const isUploading = progress !== undefined
  const canUpload = hasFile && hasFilename && !hasDuplicates && !isUploading
  const canOverwrite = hasFile && hasFilename && hasDuplicates && !isUploading

  const reset = (): void => {
    setFile(undefined)
    setSubtype('')
    setError(undefined)
    setName('')
    setFileExtension('')
    setProgress(undefined)
  }

  const upload = async (overwrite: boolean = false): Promise<void> => {
    try {
      if ((overwrite && canOverwrite) || canUpload) {
        await uploadHook.mutateAsync({
          link,
          type,
          model,
          subtype: subtype !== '' ? subtype : undefined,
          filename: fullFilename,
          overwrite,
          attachment: file,
          onProgress: (percent) => setProgress(percent)
        }, {
          onSuccess: () => {
            if (args.onSuccess != null) {
              args.onSuccess()
            }
            reset()
            void invalidateList({ filter: { link, type } })
          }
        })
      }
    } catch (e) {
      setError('Failed to upload attachment')
    }
  }

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files
    const file = files !== null ? files[0] : undefined
    const extension = file?.name.split('.').pop()
    const name = file?.name.split('.').slice(0, -1).join('.')
    setFile(file)
    setFileExtension(extension)
    setName(name)
  }
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value)
  }
  const handleSubtypeChange: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
    const value = event.target.value
    setSubtype(value)
  }
  const handleFileSelect = (): void => {
    if (fileRef.current != null) {
      fileRef.current.click()
    }
  }

  const props: UseUploadFormProps = {
    name: {
      value: name,
      onChange: handleNameChange,
      invalid: hasDuplicates || !hasFilename
    },
    subtype: {
      value: subtype,
      onChange: handleSubtypeChange
    },
    file: {
      onChange: handleFileChange,
      innerRef: fileRef
    },
    fileButton: {
      disabled: isUploading,
      onClick: handleFileSelect
    }
  }

  return {
    canOverwrite,
    canUpload,
    error,
    fileExtension,
    fullFilename,
    hasDuplicates,
    hasFilename,
    isUploading,
    progress,
    props,
    reset,
    subtypes,
    upload
  }
}

export default useUpload
