import React, { Suspense, useState } from 'react'
import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Modal from '../../../theme/Models/Modal'
import { attachmentsDocument, useMutateUpdateAttachment } from 'src/queries/attachments'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import Skeleton from './Skeleton'
import Content from './Content'

export interface UpdateProps {
  id?: string
  link: any
  type: string
  toggle: () => void
}

const Update: React.FC<UpdateProps> = (props) => {
  const { id, link, type, toggle } = props
  const open = id !== undefined

  const update = useMutateUpdateAttachment()
  const listInvalidation = useInvalidateQueryByDocument(attachmentsDocument)

  const [error, setError] = useState<undefined | string>()
  const [subtype, setSubtype] = useState<undefined | string>()

  const toggleWrapper = (): void => {
    toggle()
    setSubtype(undefined)
  }

  const onUpdate = async (): Promise<void> => {
    try {
      if (id !== undefined) {
        await update.mutateAsync({ id, record: { subtype } }, {
          onSuccess: () => {
            void listInvalidation({ filter: { link, type } })
          }
        })
        toggleWrapper()
      } else {
        throw Error('Missing attachment ID!')
      }
    } catch (e: any) {
      const message = 'message' in e && typeof e.message === 'string' ? e.message : ''
      setError(message)
    }
  }

  return (
    <Modal
      isOpen={open}
      onAction={onUpdate}
      toggle={toggleWrapper}
      error={{
        error: 'There was an error updating the attachment! Please Try Again.',
        details: error,
        show: error !== undefined
      }}
      cancel={{
        disabled: update.isLoading
      }}
      action={{
        text: 'Update',
        icon: update.isLoading ? faSpinner : faSave,
        color: 'primary',
        disabled: update.isLoading
      }}
      title='Update Attachment'
    >
      <Suspense fallback={<Skeleton />}>
        {id !== undefined
          ? (
            <Content id={id} setError={setError} setSubtype={setSubtype} subtype={subtype} />
            )
          : (
            <Skeleton />
            )}
      </Suspense>
    </Modal>
  )
}

export default Update
export { Update }
