import React from 'react'

const Skeleton: React.FC = (props) => {
  return (
    <p className='w-75 placeholder-glow' style={{ height: '1.8rem' }}>
      <span className='w-100 placeholder placeholder-lg' style={{ height: '1.8rem' }} data-testid='placeholder' />
    </p>
  )
}

export default Skeleton
