import { sortBy } from 'lodash'
import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query distributionsByPositionId($filter: FilterFindManyDistributionInput) {
    cat {
      distribution {
        many(filter: $filter) {
          _id
          attachments_count
          _category {
            name
          }
          _subcategory {
            _id
            name
          }
          date_posted
          candidate_count
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type DistributionsByPositionId = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['distribution']>['many']>

const useDistributionsByPositionId = (id?: number): DistributionsByPositionId => {
  const { data } = useGraphQL(document, {
    filter: {
      position: id
    }
  }, {
    enabled: typeof id === 'number'
  })
  const distributions = sortBy(
    data?.cat?.distribution?.many ?? [],
    ['_category.name', '_subcategory.name']
  )
  return distributions
}

export { useDistributionsByPositionId, document as distributionsByPositionIdDocument }
