import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { CandidateDetailsById } from 'src/queries'
import { isEmpty } from 'lodash'

interface ListProps {
  candidate: CandidateDetailsById | undefined
}

const FormatAddress: React.FC<CandidateDetailsById['address']> = (address) => {
  if (isEmpty(address)) {
    return null
  }
  const line1 = address.street
  const stateZip = [address.state, address.zip].filter(Boolean).join(' ')
  const line2 = [address.city, stateZip].filter(Boolean).join(', ')
  return (
    <>
      {line1}
      {line1 !== '' || line2 !== '' ? (<br />) : null}
      {line2}
    </>
  )
}

const List: React.FC<ListProps> = (props) => {
  const { candidate } = props
  const { email, phone, address } = candidate ?? {}

  const section: Array<Omit<ItemProps, 'id'>> = [
    {
      title: 'E-Mail',
      value: typeof email === 'string' ? (<a href={`mailto:${email}`}>{email}</a>) : ''
    },
    { title: 'Phone', value: phone },
    {
      title: 'Address',
      value: (
        <p>
          <FormatAddress {...address} />
        </p>
      )
    }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: candidate === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
