import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query candidateCreatedUpdatedById($id: MongoID!) {
    cat {
      candidate {
        byId(_id: $id) {
          _id
          _createdBy {
            name
          }
          _updatedBy {
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateCreatedUpdatedById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['byId']>

const useCandidateCreatedUpdatedById = (id: string): CandidateCreatedUpdatedById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const candidate = data?.cat?.candidate?.byId ?? undefined
  return candidate
}

export { useCandidateCreatedUpdatedById, document as candidateCreatedUpdatedByIdDocument }
