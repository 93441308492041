import React from 'react'
import { Navbar as BSNavbar, Nav, NavItem, Container, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../../hooks/useAuth'
import InitialsIcon from '../../../InitialsIcon'
import GlobalSearch from './Search'

interface NavbarProps {
  toggleOffcanvas: () => void
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const { toggleOffcanvas } = props
  const { app, logout, cognito, isAuthenticated } = useAuth()

  const handleLogout = (): void => {
    void logout()
  }

  return (
    <BSNavbar className='shadow p-0 border-bottom' container={false}>
      <Container className='justify-content-start pe-0' fluid>
        <Nav className='d-lg-none p-2'>
          <NavItem>
            <Button color='link' onClick={toggleOffcanvas}>
              <FontAwesomeIcon size='2x' className='text-dark text-opacity-75' icon={faBars} />
            </Button>
          </NavItem>
        </Nav>
        {app.checkPermissions('SEARCH')
          ? (
            <GlobalSearch />
            )
          : null}
        {isAuthenticated
          ? (
            <UncontrolledDropdown nav inNavbar tag='div' className='ms-auto d-none d-lg-inline-block'>
              <DropdownToggle
                className='ms-auto border-start fw-bold ps-3 py-2 pe-4 align-self-stretch nav-link'
                tag='a'
                style={{ backgroundColor: 'rgb(245, 245, 245)' }}
                data-testid='navbar-user'
              >
                <div className='d-flex flex-row'>
                  <InitialsIcon name={cognito.user.attributes?.name ?? ''} size={3} color='primary' />
                  <div className='ms-3'>
                    <div>{cognito.user.attributes?.name}</div>
                    <div className='fw-normal text-muted small'>{app.user._role?.title}</div>
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu className='w-100'>
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            )
          : null}
      </Container>
    </BSNavbar>
  )
}

export default Navbar
