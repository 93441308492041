import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateDataMigrationUpMutation, MutateDataMigrationUpMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateDataMigrationUp {
    migrations {
      up {
        name
      }
    }
  }
`)

export type UseMutateDataMigrationUpReturn = UseMutationResult<
MutateDataMigrationUpMutation,
unknown,
MutateDataMigrationUpMutationVariables
>

const useMutateDataMigrationUp = (): UseMutateDataMigrationUpReturn => {
  return useGraphMutation(document)
}

export { useMutateDataMigrationUp, document as mutateDataMigrationUpDocument }
