import { UseMutationResult, useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { omit } from 'lodash'
import { Auth } from 'aws-amplify'

export interface UploadFileVariables {
  link: any
  model: string
  filename: string
  type: string
  overwrite: boolean
  attachment: File
  subtype?: string
  onProgress?: (percent: number) => void
}

const apiPrefix = process.env.REACT_APP_API_URL ?? 'http://localhost:4000'

const uploadFile = async (args: UploadFileVariables): Promise<void> => {
  const session = await Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()
  const axiosInstance = axios.create({
    baseURL: apiPrefix,
    headers: {
      Authorization: token
    }
  })
  const data = omit(args, 'onProgress')
  const formData = new FormData()
  const record = {
    link: data.link,
    model: data.model,
    filename: data.filename,
    type: data.type,
    subtype: data.subtype
  }
  formData.append('attachment', data.attachment)
  formData.append('record', JSON.stringify(record))
  formData.append('overwrite', String(data.overwrite))
  return await axiosInstance.post(
    '/attachments',
    formData,
    {
      onUploadProgress: (progressEvent) => {
        if (typeof args.onProgress === 'function') {
          let percent = -1
          if (progressEvent.loaded !== undefined && progressEvent.total !== undefined) {
            percent = (progressEvent.loaded / progressEvent.total) * 99
          }
          args.onProgress(percent)
        }
      }
    }
  )
}

const useUploadAttachment = (): UseMutationResult<unknown, unknown, UploadFileVariables> => {
  return useMutation<unknown, unknown, UploadFileVariables>({
    useErrorBoundary: false,
    mutationFn: uploadFile
  })
}

export { useUploadAttachment }
