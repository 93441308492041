import React, { useMemo } from 'react'
import InputBase from './InputBase'
import { FieldHookConfig, useField } from 'formik'
import { Input as BSInput } from 'reactstrap'
import { parse } from 'date-fns'
import { dates } from 'src/helpers/displayhelpers'
import { useFieldError } from 'src/helpers/formHelpers'

type DatePickerProps = FieldHookConfig<any> & {
  label: string
}
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { label, type, required, ...rest } = props
  const [field, meta, helpers] = useField({ type, ...rest })
  const { error, hasError } = useFieldError(meta)
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value
    if (val === '' || val === null || val === undefined) {
      void helpers.setValue(undefined)
      return
    }
    const parsed = parse(val, 'yyyy-MM-dd', new Date())

    void helpers.setValue(parsed)
  }

  const value = useMemo(() => {
    return dates.systemDateOnly(field.value)
  }, [field.value])

  return (
    <InputBase label={label} name={field.name} error={error} required={required}>
      <BSInput {...field} invalid={hasError} value={value} onChange={handleChange} id={field.name} type='date' />
    </InputBase>
  )
}

export default DatePicker
export { DatePicker }
