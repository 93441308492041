import { Formik } from 'formik'
import React from 'react'
import { UseMutateContractByIdReturn, contractPopByIdDocument, useMutateContractById, useContractPopById } from 'src/queries'
import Form from './Form'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { MutateContractByIdMutation } from 'src/gql/graphql'
import validationSchema from './validation'

export type Values = Parameters<UseMutateContractByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const contract = useContractPopById(id)
  if (contract === undefined) {
    throw new Error('Could not load contract!')
  }
  const update = useMutateContractById()
  const options = contract.pop?.options
  const initialState: Values = {
    pop: {
      ...contract.pop,
      options: Array.isArray(options) ? options : []
    }
  }
  const invalidateContract = useInvalidateQueryByDocument(contractPopByIdDocument)
  const handleSuccess = (data: MutateContractByIdMutation): void => {
    const id = data.cat?.contract?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateContract({ id })
    }
    toggle()
  }

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={async (data) => {
        update.mutate({ id, record: data }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
