import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React from 'react'
import { Dropdown } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { UseLookupsReturn } from './useLookups'
import { Values } from './FormContext'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
  lookups?: UseLookupsReturn
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave } = props
  const {
    contractStatuses
  } = props.lookups ?? {}
  const { handleSubmit } = useFormikContext<Values>()
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the contract status.',
        show: hasError ?? false
      }}
      >
        <Dropdown name='status' label='Status' options={contractStatuses ?? []} />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
