import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { UpdateDevMutation, UpdateDevMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation updateDev ($databaseFrom: String!, $databaseTo: String!, $bucketFrom: String!, $bucketTo: String!) {
    dev {
      update(databaseFrom: $databaseFrom, databaseTo: $databaseTo, bucketFrom: $bucketFrom, bucketTo: $bucketTo) {
        _id
      }
    }
  }
`)

export type UseUpdateDevReturn = UseMutationResult<
UpdateDevMutation,
unknown,
UpdateDevMutationVariables
>

const useUpdateDev = (): UseUpdateDevReturn => {
  return useGraphMutation(document)
}

export { useUpdateDev, document as mutateCreatePositionDocument }
