import { Extension } from '@tiptap/core'
import { Node } from '@tiptap/pm/model'
import { TextSelection, AllSelection, Transaction } from 'prosemirror-state'

export const clamp = (val: Number, min: Number, max: Number): Number => {
  if (val < min) {
    return min
  }
  if (val > max) {
    return max
  }
  return val
}

const IndentProps = {
  min: 0,
  max: 210,

  more: 30,
  less: -30
}

export function isBulletListNode (node: Node): boolean {
  return node.type.name === 'bullet_list'
}

export function isOrderedListNode (node: Node): boolean {
  return node.type.name === 'order_list'
}

export function isTodoListNode (node: Node): boolean {
  return node.type.name === 'todo_list'
}

export function isListNode (node: Node): boolean {
  return isBulletListNode(node) ||
        isOrderedListNode(node) ||
        isTodoListNode(node)
}

function setNodeIndentMarkup (tr: Transaction, pos: number, delta: number): Transaction {
  if (tr.doc === undefined) {
    return tr
  }

  const node = tr.doc.nodeAt(pos)
  if (node === null) {
    return tr
  }

  const minIndent = IndentProps.min
  const maxIndent = IndentProps.max
  const indentAttr = isNaN(Number(node.attrs.indent)) ? 0 : Number(node.attrs.indent)

  const indent = clamp(
    indentAttr + delta,
    minIndent,
    maxIndent
  )

  if (indent === indentAttr) {
    return tr
  }

  const nodeAttrs = {
    ...node.attrs,
    indent
  }

  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
}

const updateIndentLevel = (tr: Transaction, delta: number): Transaction => {
  const { doc, selection } = tr

  if (doc === undefined || selection === undefined) {
    return tr
  }

  if (!(selection instanceof TextSelection || selection instanceof AllSelection)) {
    return tr
  }

  const { from, to } = selection

  doc.nodesBetween(from, to, (node, pos) => {
    const nodeType = node.type

    if (nodeType.name === 'paragraph' || nodeType.name === 'heading') {
      tr = setNodeIndentMarkup(tr, pos, delta)
      return false
    } if (isListNode(node)) {
      return false
    }
    return true
  })

  return tr
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    indent: {
      /**
       * Set a indent node
       * @param attributes The indent attributes
       * @example editor.commands.setIndent({ level: 1 })
       */
      indent: () => ReturnType
      /**
       * Toggle a indent node
       * @param attributes The indent attributes
       * @example editor.commands.toggleIndent({ level: 1 })
       */
      outdent: () => ReturnType
    }
  }
}

export const Indent = Extension.create({
  name: 'indent',

  addOptions () {
    return {
      types: ['heading', 'paragraph'],
      indentLevels: [0, 30, 60, 90, 120, 150, 180, 210],
      defaultIndentLevel: 0
    }
  },

  addGlobalAttributes () {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: this.options.defaultIndentLevel,
            renderHTML: attributes => {
              const margin = parseInt(attributes.indent)
              if (!isNaN(margin)) {
                return {
                  style: `margin-left: ${Number(attributes.indent)}px`
                }
              }
              return {}
            },
            parseHTML: element => {
              const margin = parseInt(element.style.marginLeft)
              if (!isNaN(margin)) {
                return margin
              }
              return this.options.defaultIndentLevel
            }
          }
        }
      }
    ]
  },

  addCommands () {
    return {
      indent: () => ({ tr, state, dispatch, editor }) => {
        const { selection } = state
        tr = tr.setSelection(selection)
        tr = updateIndentLevel(tr, IndentProps.more)

        if (tr.docChanged) {
          if (typeof dispatch === 'function') {
            dispatch(tr)
          }
          return true
        }

        editor.chain().focus().run()
        return false
      },
      outdent: () => ({ tr, state, dispatch, editor }) => {
        const { selection } = state
        tr = tr.setSelection(selection)
        tr = updateIndentLevel(tr, IndentProps.less)

        if (tr.docChanged) {
          if (typeof dispatch === 'function') {
            dispatch(tr)
          }
          return true
        }

        editor.chain().focus().run()

        return false
      }
    }
  },
  addKeyboardShortcuts () {
    return {
      Tab: () => {
        if (!(this.editor.isActive('bulletList') || this.editor.isActive('orderedList'))) {
          return this.editor.commands.indent()
        }
        return false
      },
      'Shift-Tab': () => {
        if (!(this.editor.isActive('bulletList') || this.editor.isActive('orderedList'))) {
          return this.editor.commands.outdent()
        }
        return false
      }
      // Backspace: () => {
      //     if (!(this.editor.isActive('bulletList') || this.editor.isActive('orderedList'))) {
      //         return this.editor.commands.outdent()
      //     }
      //     return false
      // },
    }
  }

})

export default Indent
