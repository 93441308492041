import { Formik } from 'formik'
import React from 'react'
import { UseMutateDistributionByIdReturn, distributionDetailsByIdDocument, useMutateDistributionById, useDistributionDetailsById, usePositions } from 'src/queries'
import Form from './Form'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { MutateDistributionByIdMutation } from 'src/gql/graphql'
import { isNumber, pick } from 'lodash'
import { DropdownOption } from 'src/components/Forms'

export type Values = Parameters<UseMutateDistributionByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: string
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const distribution = useDistributionDetailsById(id)
  if (distribution === undefined) {
    throw new Error('Could not load distribution!')
  }
  const positions = usePositions()
  const positionOptions: DropdownOption[] = positions.map((p) => {
    return { value: p._id, label: `(${p.id ?? ''}) ${p.title ?? ''} [${p._contract?.abbreviation ?? ''}]` }
  })
  const update = useMutateDistributionById()
  const initialState: Values = pick(distribution, [
    'position'
  ])
  const invalidateDistribution = useInvalidateQueryByDocument(distributionDetailsByIdDocument)
  const handleSuccess = (data: MutateDistributionByIdMutation): void => {
    const id = data.cat?.distribution?.updateById?.recordId
    if (typeof id === 'string') {
      void invalidateDistribution({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const record = { ...data }
        if (!isNumber(record.position)) {
          throw new Error('Invalid type for position')
        }
        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form positions={positionOptions} toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
      )}
    </Formik>
  )
}

export default FormContext
