import React, { Suspense } from 'react'
import Card from '../../../../../../components/Card'
import Body from './Body'
import Skeleton from './Skeleton'

interface StatusProps {
  id: number
}

const Status: React.FC<StatusProps> = (props) => {
  const { id } = props
  return (
    <Card title='Status'>
      <div className='d-flex h-100 w-100 justify-content-center align-items-center'>
        <Suspense fallback={<Skeleton />}>
          <Body id={id} />
        </Suspense>
      </div>
    </Card>
  )
}

export default Status
