import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query candidateInterviewById($id: MongoID!) {
    cat {
      candidate {
        byId(_id: $id) {
          _id
          round_1
          round_2
          round_3
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateInterviewById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['byId']>

const useCandidateInterviewById = (id: string): CandidateInterviewById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const candidate = data?.cat?.candidate?.byId ?? undefined
  return candidate
}

export { useCandidateInterviewById, document as candidateInterviewByIdDocument }
