import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React, { Suspense } from 'react'
import { Dropdown, DropdownOption } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { Values } from './FormContext'
import { useDistributionsByPositionId } from 'src/queries'
import FieldSuspense from 'src/components/Forms/FieldSuspense'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
  positions?: DropdownOption[]
}

const Distributions: React.FC = () => {
  const { values } = useFormikContext<Values>()
  const distributions = useDistributionsByPositionId(values.position ?? undefined)
  const options: DropdownOption[] = distributions.map(d => {
    return { value: d._id, label: `${d._subcategory?.name ?? ''} (${d._category?.name ?? ''})` }
  })
  return <Dropdown name='distribution' label='Distribution' options={options ?? []} />
}

const Form: React.FC<FormProps> = (props) => {
  const { positions, hasError, toggle, isLoading, disableSave } = props
  const { handleSubmit, setFieldValue } = useFormikContext()
  const handlePositionChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    void setFieldValue('distribution', null)
    return Number(e.target.value)
  }
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the candidate.',
        show: hasError ?? false
      }}
      >
        <Dropdown name='position' label='Position' options={positions ?? []} onChange={handlePositionChange} />
        <Suspense fallback={<FieldSuspense label='Distribution' />}>
          <Distributions />
        </Suspense>
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
