import React from 'react'
import Modal, { Header } from 'src/components/theme/Models/Modal'
import FormContext from './FormContext'
import FormSuspense from 'src/components/Forms/FormSuspense'
import Form from './Form'

interface AddModalProps {
  isOpen: boolean
  toggle: () => void
  positionId: number
}

const AddModal: React.FC<AddModalProps> = (props) => {
  const { isOpen, toggle, positionId } = props

  return (
    <Modal
      title='Add Distribution(s)'
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
    >
      <Header title='Add Distribution(s)' />
      <FormSuspense fallback={<Form toggle={toggle} disableSave />}>
        <FormContext positionId={positionId} toggle={toggle} />
      </FormSuspense>
    </Modal>
  )
}

export default AddModal
