import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateRemoveAttachmentMutation, MutateRemoveAttachmentMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateRemoveAttachment($id: MongoID!) {
    attachments {
      removeById(_id: $id) {
        recordId
      }
    }
  }
`)

export type UseMutateRemoveAttachmentReturn = UseMutationResult<
MutateRemoveAttachmentMutation,
unknown,
MutateRemoveAttachmentMutationVariables
>

const useMutateRemoveAttachment = (): UseMutateRemoveAttachmentReturn => {
  return useGraphMutation(document)
}

export { useMutateRemoveAttachment, document as mutateRemoveAttachmentDocument }
