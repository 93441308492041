import { graphql } from 'src/gql'
import { SortFindManyClearanceInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query clearances($sort: SortFindManyClearanceInput) {
    lookups {
      Clearance {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type Clearances = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Clearance']>['many']>

const useClearances = useGraphQLDropdownOptions(
  document,
  'lookups.Clearance.many',
  { sort: SortFindManyClearanceInput.NameAsc }
)

export { useClearances, document as clearancesDocument }
