import React, { Suspense } from 'react'
import Card from 'src/components/Card'
import CardBody from './CardBody'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import useToggleState from 'src/hooks/useToggleState'
import AddModal from 'src/pages/cat/contracts/List/AddModal'

const List: React.FC = (props) => {
  const [isOpen, , handleToggle] = useToggleState()
  return (
    <>
      <Card
        title='Contracts'
        primaryAction={[
          {
            key: 'add-contract',
            icon: faPlus,
            text: 'Add Contract',
            onClick: handleToggle,
            permissionKey: 'CAT_EDIT'
          }
        ]}
      >
        <Suspense fallback={<Table options={{ data: [], columns }} suspend={3} />}>
          <CardBody columns={columns} />
        </Suspense>
      </Card>
      <AddModal isOpen={isOpen} toggle={handleToggle} />
    </>
  )
}

export default List
