import React, { useEffect } from 'react'
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'

import { graphql } from 'src/gql'
import { useAttachmentSubtypes } from 'src/queries/attachments'
import { useGraphQL } from '../../../../hooks/useGraphQL'

const attachmentByIdQueryDocument = graphql(/* graphql */`
  query attachmentById($id: MongoID!) {
    attachments {
      byId(_id: $id) {
        _id,
        filename
        type
        subtype
      }
    }
  }
`)

export interface ContentProps {
  id: string
  subtype?: string
  setSubtype: (value: string | undefined) => void
  setError: (value: string) => void
}

const Content: React.FC<ContentProps> = (props) => {
  const { id, setError, subtype, setSubtype } = props

  const { data } = useGraphQL(attachmentByIdQueryDocument, { id }, {
    onError: (err: any) => {
      const message = 'message' in err ? String(err.message ?? '') : 'Unknown Error.'
      setError(message)
    }
  })
  const attachment = data?.attachments?.byId
  const subtypes = useAttachmentSubtypes(
    { type: attachment?.type },
    attachment !== undefined
  )

  useEffect(() => {
    setSubtype(attachment?.subtype)
  }, [attachment?.subtype])

  const onSubtypeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value
    setSubtype(value)
  }

  return (
    <Form className='text-start fw-bold'>
      <Row>
        <Col>File:</Col>
      </Row>
      <Row className='mb-3'>
        <Col className='fw-normal'>{attachment?.filename ?? ''}</Col>
      </Row>
      {subtypes.length > 0
        ? (
          <FormGroup>
            <Label for='attachmentSubtype'>Type:</Label>
            <Input type='select' id='attachmentSubtype' onChange={onSubtypeChange} value={subtype}>
              <option value={undefined}>Select a Type</option>
              {subtypes.map(s => {
                return (
                  <option key={s._id} value={s._id}>{s.name}</option>
                )
              })}
            </Input>
          </FormGroup>
          )
        : (
          <Row>
            <Col>This attachment does not have any available subtypes to edit.</Col>
          </Row>
          )}
    </Form>
  )
}

export default Content
