import React from 'react'
import Card from '../../../../../../components/Card'
import { GetRowProps, Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useCandidates, Candidates as CandidatesQuery, useDistributionSubcategories } from 'src/queries'
import { useOverviewState } from 'src/pages/cat/view/distributionoverview/useOverviewState'

interface CandidatesProps {
  collapsed: boolean
  onCollapse: () => void
  candidateType: 'other' | 'distribution'
}

const getRowProps: GetRowProps<CandidatesQuery[0]> = (row) => {
  const rowColor = row.original._substatus?.color ?? undefined
  const className = rowColor !== undefined ? `table-${rowColor}` : undefined
  return {
    className
  }
}

const Candidates: React.FC<CandidatesProps> = (props) => {
  const { collapsed, onCollapse, candidateType } = props
  const { position, category, subcategory, candidatesQueryEnabled } = useOverviewState()
  const distributionCategory = useDistributionSubcategories(category).find(c => c.value === subcategory)
  const data = useCandidates({ position }, candidatesQueryEnabled)
  const candidates = data?.filter(d => {
    if (subcategory === undefined) {
      return false
    }
    const isDistribution = d._distribution?._subcategory?._id === subcategory
    return candidateType === 'other' ? !isDistribution : isDistribution
  })
  const countText = candidatesQueryEnabled ? `(${candidates.length})` : ''
  const titlePrefix = candidateType === 'distribution' ? `${distributionCategory?.label ?? ''} ` : 'Other '
  return (
    <>
      <Card
        title={`${titlePrefix} Candidates ${countText}`}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        data-testid='candidates-card'
      >
        {!candidatesQueryEnabled
          ? (
            <h2 className='text-center m-5'>Please select a position</h2>
            )
          : (
            <Table
              storageKey='detailed-position-candidates'
              options={{
                data: candidates,
                columns,
                initialState: {
                  sorting: [{ id: 'name', desc: true }]
                },
                meta: { getRowProps }
              }}
            />
            )}
      </Card>
    </>
  )
}

export default Candidates
