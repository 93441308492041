import React from 'react'
import { Col, Row } from 'reactstrap'
import Positions from './cards/Positions'
import { OverviewContextProvider } from 'src/pages/cat/view/distributionoverview/useOverviewState'
import CategoryDropdown from './CategoryDropdown'
import CandidateCards from 'src/pages/cat/view/distributionoverview/CandidateCards'

const CandidateDetails: React.FC = (props) => {
  return (
    <OverviewContextProvider>
      <Row className='mb-4'>
        <Col md='auto'>
          <CategoryDropdown />
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col md='12'>
          <Positions />
        </Col>
      </Row>
      <CandidateCards />
    </OverviewContextProvider>
  )
}

export default CandidateDetails
