import React, { Fragment } from 'react'
import { Nav } from 'reactstrap'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from '../../../../hooks/useAuth'
import MenuItem from './MenuItem'
import { SidebarMenu } from './menustructure'

interface MenuProps {
  menu: SidebarMenu
  open?: string
  toggle: (id: string) => void
}

const Menu: React.FC<MenuProps> = (props) => {
  const { menu, open, toggle } = props
  const { isAuthenticated, cognito, app } = useAuth()
  const publicUrl = process.env.REACT_APP_PUBLIC_URL ?? ''

  const handleLogout = (): void => {
    void cognito.logout()
  }

  return (
    <>
      <a href={`${publicUrl}/`} className='d-flex justify-content-center mb-4 mt-2'>
        <img
          src={`${publicUrl != null ? publicUrl : ''}/imc_header_logo_box_275px.png`}
          alt='Innovative Management Concept Logo'
          className='w-50 m-auto'
        />
      </a>
      {menu.map(category => {
        if (category.permissions === undefined || app.checkPermissions(category.permissions)) {
          return (
            <Fragment key={category.title}>
              <span className='text-uppercase fw-bold pb-2'>{category.title}</span>
              <Nav vertical className='mb-4'>
                {category.menu.map(navitem => {
                  if (navitem.permissions === undefined || app.checkPermissions(navitem.permissions)) {
                    return (
                      <MenuItem
                        key={navitem.title}
                        open={open === navitem.id}
                        toggle={toggle}
                        menu={navitem}
                      />
                    )
                  }
                  return null
                })}
              </Nav>
            </Fragment>
          )
        }
        return null
      })}
      {isAuthenticated
        ? (
          <div className='d-lg-none'>
            <span className='text-uppercase fw-bold pb-2'>Account</span>
            <Nav vertical className='mb-4'>
              <MenuItem
                open={open === 'user'}
                toggle={toggle}
                menu={{
                  id: 'user',
                  title: cognito.user.attributes?.name ?? 'Unknown',
                  icon: faUser,
                  submenu: [
                    { id: 'logout', title: 'Logout', onClick: handleLogout }
                  ]
                }}
              />
            </Nav>
          </div>
          )
        : null}
    </>
  )
}

export default Menu
