import React from 'react'
import { ColProps, Row } from 'reactstrap'
import { ItemProps } from './Item'

interface DescriptionListProps {
  children: Array<React.ReactElement<ItemProps>> | React.ReactElement<ItemProps>
  titleProps: ColProps
  valueProps: ColProps
  className?: string
  suspended?: boolean
  colWrapper?: boolean
}

const DescriptionList: React.FC<DescriptionListProps> = (props) => {
  const { className, suspended, titleProps, valueProps, colWrapper } = props
  const children = Array.isArray(props.children) ? props.children : [props.children]
  return (
    <Row className={className}>
      {children.map(child => {
        const newChild = React.cloneElement(child, {
          valueProps,
          titleProps,
          suspended,
          colWrapper,
          key: child.props.id,
          ...child.props
        })

        return newChild
      })}
    </Row>
  )
}

export * from './Item'
export { DescriptionList }
