import { Formik } from 'formik'
import React, { useMemo } from 'react'
import { DistributionSubcategoriesAndCategories, distributionsByPositionIdDocument, useDistributionSubcategoriesAndCategories, useDistributionsByPositionId } from 'src/queries'
import Form from './Form'
import { chain, head } from 'lodash'
import { notEmpty } from 'src/helpers/formHelpers'
import { useMutateCreateManyDistributions } from 'src/queries/cat/distribution/useMutateCreateManyDistributions'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { isValid } from 'date-fns'

interface FormContextProps {
  toggle: () => void
  positionId: number
}

type Category = NonNullable<DistributionSubcategoriesAndCategories[0]['_category']>
interface Subcategory {
  value: string | number
  label: string
  disabled?: boolean
}

export interface SubcategoryGroup extends Category {
  subcategories: Subcategory[]
}

export type GroupedSubcategories = SubcategoryGroup[]

interface FormikValues {
  subcategories: {
    [key: string]: string[]
  }
  date_posted: string
  notes: string
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { toggle, positionId } = props
  const create = useMutateCreateManyDistributions()
  const distributions = useDistributionsByPositionId(positionId)
  const subcategories = useDistributionSubcategoriesAndCategories()
  const invalidateDistributions = useInvalidateQueryByDocument(distributionsByPositionIdDocument)

  const grouped = useMemo((): GroupedSubcategories => {
    return chain(subcategories)
      .groupBy('_category._id')
      .map((group) => {
        const category = head(group)?._category ?? { _id: 'unknown', name: 'Unknown' }
        return {
          ...category,
          subcategories: chain(group).map(c => {
            const name = c.name ?? undefined
            const alreadyCreated = distributions.find(d => d._subcategory?._id === c._id) !== undefined
            if (name !== undefined) {
              return {
                value: c._id,
                label: name,
                disabled: alreadyCreated
              }
            }
            return null
          }).filter(notEmpty).sortBy('label').value()
        }
      })
      .filter(c => c.subcategories.length > 0)
      .sortBy('name')
      .value()
  }, [subcategories, distributions])

  const handleSuccess = (): void => {
    toggle()
    void invalidateDistributions({ filter: { position: positionId } })
  }

  return (
    <Formik
      initialValues={{
        subcategories: {},
        date_posted: '',
        notes: ''
      }}
      onSubmit={async (data: FormikValues) => {
        const records = Object.keys(data.subcategories).map((c) => {
          const datePosted = isValid(data.date_posted) ? new Date(data.date_posted) : undefined
          return data.subcategories[c].map((s) => {
            return {
              category: c,
              subcategory: s,
              position: positionId,
              date_posted: datePosted,
              notes: data.notes
            }
          })
        }).flat()

        create.mutate({ records }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form subcategories={grouped} toggle={toggle} isLoading={create.isLoading} hasError={create.isError} />
      )}
    </Formik>
  )
}

export default FormContext
