import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import InputBase from './InputBase'
import { Input, InputGroup, InputGroupText } from 'reactstrap'

type FieldSuspenseProps = React.ComponentProps<typeof InputBase>

const FieldSuspense: React.FC<FieldSuspenseProps> = (props) => (
  <InputBase {...props}>
    <InputGroup>
      <Input className='border-end-0' disabled />
      <InputGroupText className='border-start-0'><FontAwesomeIcon spin icon={faSpinner} /></InputGroupText>
    </InputGroup>
  </InputBase>
)

export default FieldSuspense
