import React from 'react'
import Dropdown, { DropdownProps } from 'src/components/Forms/Dropdown'

type OmitBetterStrict<T, K extends keyof T> = T extends any ? Pick<T, Exclude<keyof T, K>> : never // Fix some weird union stuff with TS
type YesNoProps = OmitBetterStrict<DropdownProps, 'options'>

const YesNo: React.FC<YesNoProps> = (props) => {
  return (
    <Dropdown
      {...props}
      options={[
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
      ]}
      onChange={(event) => {
        const value = event.target.value
        if (value === 'true') {
          return true
        }
        if (value === 'false') {
          return false
        }
        return null
      }}
      formatValue={(value: any) => {
        if (value === true) {
          return 'true'
        }
        if (value === false) {
          return 'false'
        }
        return ''
      }}
    />
  )
}

export default YesNo
