import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import useSearchField from '../../components/GlobalSearch/useSearchField'
import SearchField from '../../components/GlobalSearch/SearchField'
import Results from './Results'

const Search: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const term = new URLSearchParams(location.search).get('s')
  const { inputProps, debouncedValue, hasSearchValue } = useSearchField(term ?? '')

  useEffect(() => {
    navigate({ search: `?s=${encodeURIComponent(debouncedValue)}` }, { replace: true })
  }, [debouncedValue])

  return (
    <Row className='justify-content-center'>
      <Col md='6'>
        <Row>
          <Col>
            <SearchField {...inputProps} />
          </Col>
        </Row>
        {hasSearchValue
          ? (
            <Results term={debouncedValue} />
            )
          : null}
      </Col>
    </Row>
  )
}

export default Search
