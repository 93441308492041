import React, { Suspense } from 'react'
import { Input, Row, Col } from 'reactstrap'
import Category from './Category'
import Subcategory from './Subcategory'

const Fallback: React.FC<{ label: string }> = (props) => {
  return (
    <Input type='select' disabled bsSize='lg'>
      <option value=''>{props.label}</option>
    </Input>
  )
}

const index: React.FC = (props) => {
  return (
    <Row>
      <Col md='auto'>
        <Suspense fallback={<Fallback label='Select A Category' />}>
          <Category />
        </Suspense>
      </Col>
      <Col md='auto'>
        <Suspense fallback={<Fallback label='Select A Subcategory' />}>
          <Subcategory />
        </Suspense>
      </Col>
    </Row>

  )
}

export default index
