import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap'
import {
  faAngleRight,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons'
import { MenuCategory } from './menustructure'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../hooks/useAuth'

interface MenuItemProps {
  menu: MenuCategory['menu'][0]
  open?: boolean
  toggle: (id: string) => void
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { menu, open, toggle } = props
  const { app } = useAuth()

  return (
    <>
      <NavItem data-testid='menu-item' className='fw-semibold' onClick={() => toggle(menu.id)}>
        <NavLink
          tag={'to' in menu && menu.to !== undefined ? Link : 'a'}
          className='text-reset text-decoration-none cursor-pointer p-2 px-0'
          to={'to' in menu && menu.to !== undefined ? menu.to : undefined}
          onClick={() => {
            if (typeof menu.onClick === 'function') {
              menu.onClick()
            }
          }}
        >
          <FontAwesomeIcon icon={menu.icon} className='me-2' fixedWidth />
          {menu.title}
          {'submenu' in menu && menu.submenu !== undefined
            ? (
              <div className='float-end'>
                {open === true
                  ? <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                  : <FontAwesomeIcon icon={faAngleRight} fixedWidth />}
              </div>
              )
            : null}
        </NavLink>
      </NavItem>
      {'submenu' in menu && menu.submenu !== undefined
        ? (
          <Collapse isOpen={open} data-testid={`${menu.title}-submenu`} className='ms-2'>
            <Nav type='unstyled'>
              {menu.submenu.map(item => {
                if (item.permissions === undefined || app.checkPermissions(item.permissions)) {
                  return (
                    <NavItem data-testid='submenu-item' key={item.title} className='fw-semibold'>
                      <NavLink
                        tag={'to' in item && item.to !== undefined ? Link : 'a'}
                        to={'to' in item && item.to !== undefined ? item.to : undefined}
                        href={!('to' in item) ? '#' : undefined}
                        className='text-reset text-decoration-none p-2'
                        onClick={() => {
                          if (typeof item.onClick === 'function') {
                            item.onClick()
                          }
                        }}
                      >
                        {item.title}
                      </NavLink>
                    </NavItem>
                  )
                }
                return null
              })}
            </Nav>
          </Collapse>
          )
        : null}
    </>
  )
}

export default MenuItem
