import { createDropdownOptions } from 'src/helpers/formHelpers'
import { useContractStatuses } from 'src/queries'

export interface UseLookupsReturn {
  contractStatuses: ReturnType<typeof createDropdownOptions>
}

const useLookups = (): UseLookupsReturn => {
  const contractStatuses = useContractStatuses()
  return {
    contractStatuses: createDropdownOptions(contractStatuses)
  }
}

export default useLookups
