import { DropdownOption } from 'src/components/Forms'
import { graphql } from 'src/gql'
import { SortFindManyDistribution_SubcategoryInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query distributionSubcategories($filter: FilterFindManyDistribution_SubcategoryInput, $sort: SortFindManyDistribution_SubcategoryInput) {
    lookups {
      Distribution_Subcategory{
        many(filter: $filter, sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type DistributionSubcategories = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Distribution_Subcategory']>['many']>

const defaultHandler = useGraphQLDropdownOptions(
  document,
  'lookups.Distribution_Subcategory.many',
  { sort: SortFindManyDistribution_SubcategoryInput.NameAsc }
)

const useDistributionSubcategories = (category?: string): DropdownOption[] => {
  return defaultHandler(
    { filter: { category } },
    { enabled: typeof category === 'string' }
  )
}

export { useDistributionSubcategories, document as distributionSubcategoriesDocument }
