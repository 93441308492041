import { Formik } from 'formik'
import React from 'react'
import { useMutateCreatePosition } from 'src/queries'
import Form from './Form'
import useLookups from './useLookups'
import { MutateCreatePositionMutation } from 'src/gql/graphql'
import { emptyStringToNull } from 'src/helpers/formHelpers'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

interface FormContextProps {
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { toggle } = props
  const navigate = useNavigate()
  const lookups = useLookups()
  const create = useMutateCreatePosition()
  const handleSuccess = (data: MutateCreatePositionMutation): void => {
    const id = String(data.cat?.position?.createOne?.recordId ?? '')
    if (id !== '') {
      toggle()
      navigate(`/cat/positions/${id}`)
    } else {
      throw new Error('There was an issue getting the position ID.')
    }
  }
  const validationSchema = Yup.object().shape({
    title: Yup.string().required().label('Title')
  })
  return (
    <Formik
      initialValues={{
        title: '',
        status: '',
        contract: '',
        contract_type: '',
        priority: '',
        program_manager: '',
        hiring_manager: '',
        required_clearance: '',
        location: '',
        direct_hire: undefined,
        backfill: undefined,
        temp_to_perm: undefined,
        keywords: ''
      }}
      validationSchema={validationSchema}
      onSubmit={async (data) => {
        const record = { ...data }
        emptyStringToNull(record, 'contract')
        emptyStringToNull(record, 'status')
        emptyStringToNull(record, 'contract_type')
        emptyStringToNull(record, 'priority')
        emptyStringToNull(record, 'program_manager')
        emptyStringToNull(record, 'hiring_manager')
        emptyStringToNull(record, 'required_clearance')

        create.mutate({ record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form lookups={lookups} toggle={toggle} isLoading={create.isLoading} hasError={create.isError} />
      )}
    </Formik>
  )
}

export default FormContext
