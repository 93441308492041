import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import { currency } from 'src/helpers/displayhelpers'
import DateDisplay from 'src/components/DateDisplay'
import { PositionRatesById } from 'src/queries'

interface ListProps {
  position: PositionRatesById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { position } = props
  const section1: Array<Omit<ItemProps, 'id'>> = [
    {
      title: 'Requested',
      value: <DateDisplay id='requestedRate' date={position?.pricing?.requested} />
    },
    {
      title: 'Received',
      value: <DateDisplay id='recivedRate' date={position?.pricing?.received} />
    },
    { title: 'Bill Rate (Min)', value: currency(position?.bill_rate?.min) },
    { title: 'Bill Rate (Max)', value: currency(position?.bill_rate?.max) },
    { title: 'Salary (Min)', value: currency(position?.salary?.min) },
    { title: 'Salary (Max)', value: currency(position?.salary?.max) }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: position === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section1.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
