import React from 'react'
import { Col, Form, Placeholder, Row } from 'reactstrap'

const Skeleton: React.FC = (props) => {
  return (
    <Form className='text-start fw-bold placeholder-glow' data-testid='attachment-skeleton'>
      <Row>
        <Col>File:</Col>
      </Row>
      <Row className='mb-3'>
        <Col><Placeholder xs={12} /></Col>
      </Row>
      <Row>
        <Col>Type:</Col>
      </Row>
      <Row>
        <Col><Placeholder xs={12} /></Col>
      </Row>
    </Form>
  )
}

export default Skeleton
