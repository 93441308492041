import React, { useEffect } from 'react'
import List from './List'
import { useContractDetailsById } from 'src/queries'
import { useCardContext } from 'src/components/Card/CardContext'

interface BodyProps {
  id: string
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const contract = useContractDetailsById(id)
  const cardContext = useCardContext()
  const abbr = contract?.abbreviation
  useEffect(() => {
    if (abbr !== undefined && abbr !== null) {
      cardContext.setTitle(`Contract - ${abbr}`)
    } else {
      cardContext.setTitle('Contract')
    }
  }, [abbr])
  return (
    <List contract={contract} />
  )
}

export default Body
