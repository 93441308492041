import React, { useEffect } from 'react'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useCardContext } from 'src/components/Card/CardContext'
import { UseFilterStateResults } from './Filters/useFilterState'
import { usePositions } from 'src/queries'

interface CardBodyProps {
  columns: ReturnType<typeof columns>
  queryFilters: UseFilterStateResults['queryFilters']
}

const Body: React.FC<CardBodyProps> = (props) => {
  const { columns, queryFilters } = props
  const positions = usePositions(queryFilters)
  const card = useCardContext()
  const count = positions.length
  useEffect(() => {
    card.setTitle(`Positions (${count})`)
  }, [count])
  return (
    <Table storageKey='cat-positions' options={{ data: positions, columns, initialState: { sorting: [{ id: 'status', desc: false }] } }} />
  )
}

export default Body
