import React, { Suspense } from 'react'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import useToggleState from 'src/hooks/useToggleState'
import Card from 'src/components/Card'
import Body from './Body'
import Skeleton from './Skeleton'
import EditModel from './EditModal'

interface StatusProps {
  id: string
}

const Status: React.FC<StatusProps> = (props) => {
  const { id } = props
  const [isOpen, , handleToggle] = useToggleState()

  return (
    <>
      <Card
        title='Status'
        primaryAction={{
          key: 'edit',
          icon: faPencil,
          onClick: handleToggle,
          permissionKey: 'CAT_EDIT'
        }}
      >
        <div className='d-flex h-100 w-100 justify-content-center align-items-center'>
          <Suspense fallback={<Skeleton />}>
            <Body id={id} />
          </Suspense>
        </div>
      </Card>
      <EditModel id={id} isOpen={isOpen} toggle={handleToggle} />
    </>
  )
}

export default Status
