import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { DatePicker } from 'src/components/Forms'

interface DateRangeFormProps {
  name: string
  label: string
  remove?: () => void
}

const DateRangeForm: React.FC<DateRangeFormProps> = (props) => {
  const { name, label, remove } = props
  const canRemove = typeof remove === 'function'
  return (
    <Row>
      <Col sm='6' xs='6'>
        <DatePicker name={`${name}.start`} label={`${label} Start`} />
      </Col>
      <Col sm='5' xs='6'>
        <DatePicker name={`${name}.end`} label={`${label} End`} />
      </Col>
      <Col sm='1' className='text-danger'>
        <div className='mb-3'>
          <div className='pb-4 mb-3 d-none d-sm-block' />
          {canRemove
            ? (
              <>
                <div className='d-none d-sm-block'>
                  <FontAwesomeIcon icon={faTrash} onClick={remove} role='button' title={`Remove ${label}`} />
                </div>
                <div className='d-sm-none'>
                  <Button block onClick={remove} size='sm' color='light'>
                    <FontAwesomeIcon icon={faTrash} /> Remove {label}
                  </Button>
                </div>
              </>
              )
            : null}
        </div>
      </Col>
    </Row>
  )
}

export default DateRangeForm
