import React, { useEffect } from 'react'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useCardContext } from 'src/components/Card/CardContext'
import { usePositions } from 'src/queries'

interface CardBodyProps {
  contract: string
  columns: ReturnType<typeof columns>
}

const Body: React.FC<CardBodyProps> = (props) => {
  const { columns } = props
  const positions = usePositions({ contract: props.contract })
  const card = useCardContext()
  const count = positions.length
  useEffect(() => {
    card.setTitle(`Positions (${count})`)
  }, [count])
  return (
    <Table storageKey='cat-positions' options={{ data: positions, columns, initialState: { sorting: [{ id: 'status', desc: false }] } }} />
  )
}

export default Body
