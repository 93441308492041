import React, { useEffect } from 'react'
import { GetRowProps, Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { useCardContext } from '../../../../../../components/Card/CardContext'
import { Candidates, useCandidates } from 'src/queries'

interface BodyProps {
  id: string
}

const getRowProps: GetRowProps<Candidates[0]> = (row) => {
  const rowColor = row.original._substatus?.color ?? undefined
  const className = rowColor !== undefined ? `table-${rowColor}` : undefined
  return {
    className
  }
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const candidates = useCandidates({
    distribution: id
  }, typeof id === 'string')
  const card = useCardContext()
  const count = candidates.length
  useEffect(() => {
    card.setTitle(`Candidates (${count})`)
  }, [count])
  return (
    <Table
      storageKey='distribution-candidates'
      options={{
        data: candidates,
        columns,
        initialState: {
          sorting: [{ id: 'name', desc: true }]
        },
        meta: { getRowProps }
      }}
    />
  )
}

export default Body
