import { graphql } from 'src/gql'
import { ContractPositionCountsByIdQueryVariables } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL, UseGraphqlOptions } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query contractPositionCountsById($id: MongoID!) {
    cat {
      contract {
        countByPositionStatus(_id: $id) {
          status
          count
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractPositionCountsById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['contract']>['countByPositionStatus']>

const useContractPositionCountsById = (id: string, variables?: ContractPositionCountsByIdQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): ContractPositionCountsById | undefined => {
  const internalVariables: ContractPositionCountsByIdQueryVariables = Object.assign({
    id
  }, variables)
  const { data } = useGraphQL(document, internalVariables, options)
  return data?.cat?.contract?.countByPositionStatus ?? []
}

export { useContractPositionCountsById, document as contractPositionCountsByIdDocument }
