import React from 'react'
import { useRouteError, isRouteErrorResponse, useNavigate } from 'react-router-dom'
import RouteError from '../../../helpers/RouteError'
import ErrorSplashPage from './ErrorSplashPage'

const RouterError: React.FC = (props) => {
  const error = useRouteError()
  const navigate = useNavigate()
  const isErrorWithCode = isRouteErrorResponse(error) || error instanceof RouteError
  return (
    <ErrorSplashPage errorCode={isErrorWithCode ? error.status : undefined} onReturnHome={() => navigate('/')} />
  )
}

export default RouterError
