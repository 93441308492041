import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import DateDisplay from 'src/components/DateDisplay'
import { CandidateOfferLetterById } from 'src/queries'

interface ListProps {
  candidate: CandidateOfferLetterById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { candidate } = props
  const offerLetter = candidate?.offer_letter
  const section1: Array<Omit<ItemProps, 'id'>> = [
    {
      title: 'Extended',
      value: <DateDisplay id='offerLetterExtendedOn' date={offerLetter?.extended} />
    },
    {
      title: 'Signed',
      value: <DateDisplay id='offerLetterSignedOn' date={offerLetter?.signed} />
    }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: candidate === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section1.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
