import { graphql } from 'src/gql'
import { SortFindManyContract_TypeInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query contractTypes($sort: SortFindManyContract_TypeInput) {
    lookups {
      Contract_Type {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type ContractTypes = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Contract_Type']>['many']>

const useContractTypes = useGraphQLDropdownOptions(
  document,
  'lookups.Contract_Type.many',
  { sort: SortFindManyContract_TypeInput.NameAsc }
)

export { useContractTypes, document as contractTypesDocument }
