import React from 'react'

interface InitialsIconProps {
  name: string
  size?: number
  color?: string
}

const InitialsIcon: React.FC<InitialsIconProps> = (props) => {
  const { name, size = 1, color = 'dark' } = props
  const isAlreadyInitials = name.length === 2
  let inititals = name
  if (!isAlreadyInitials) {
    const nameSplit = name.split(' ')
    const firstInitital = nameSplit[0]?.charAt(0)
    const secondInitial = nameSplit[1]?.charAt(0)
    inititals = `${firstInitital}${secondInitial}`
  }
  return (
    <div className={`text-${color}`}>
      <svg aria-hidden='true' focusable='false' className='d-inline-block' style={{ height: `${size}em` }} role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path fill='currentColor' d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z' />
        <text textAnchor='middle' y='50%' x='50%' dy='0.35em' className='fw-bold' style={{ fontSize: '13em' }} fill='white'>{inititals}</text>
      </svg>

    </div>
  )
}

export default InitialsIcon
