import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutatePositionByIdMutation, MutatePositionByIdMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { positionCreatedUpdatedByIdDocument } from '../usePositionCreatedUpdatedById'

const document = graphql(/* graphql */`
  mutation mutatePositionById($id: Int!, $record: UpdateByIdPositionInput!) {
    cat {
      position {
        updateById(_id: $id, record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutatePositionByIdReturn = UseMutationResult<
MutatePositionByIdMutation,
unknown,
MutatePositionByIdMutationVariables
>

const useMutatePositionById = (): UseMutatePositionByIdReturn => {
  const invalidateCreatedUpdated = useInvalidateQueryByDocument(positionCreatedUpdatedByIdDocument)
  return useGraphMutation(document, {
    onSuccess: (data) => {
      const id = data.cat?.position?.updateById?.recordId
      if (typeof id === 'number') {
        void invalidateCreatedUpdated({ id })
      }
    }
  })
}

export { useMutatePositionById, document as mutatePositionByIdDocument }
