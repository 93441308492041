import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import { ChainedCommands, Editor } from '@tiptap/react'
import { Button } from 'reactstrap'

interface OnClickButtonArgs {
  editor: Editor
  chain: ChainedCommands
}

type ChainFn = (args: OnClickButtonArgs) => ChainedCommands

export interface RichTextButtonProps {
  title: string
  editor: Editor
  onClick: ChainFn
  disabled?: ChainFn
  active: string | [string, Record<string, any>]
  icon: FontAwesomeIconProps['icon']
}

const RichTextButton: React.FC<RichTextButtonProps> = (props) => {
  const { editor, title, onClick, disabled, icon, active } = props
  const isActive = Array.isArray(active)
    ? editor.isActive(...active)
    : editor.isActive(active)
  const disabledResult =
    typeof disabled === 'function'
      ? disabled({
        editor,
        chain: editor.can().chain().focus()
      }).run()
      : undefined
  return (
    <Button
      title={title}
      onClick={() => onClick({ editor, chain: editor.chain().focus() }).run()}
      disabled={disabledResult !== undefined && !disabledResult}
      size='sm'
      color='link'
      className={isActive ? 'text-black' : 'text-secondary'}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

export default RichTextButton
