import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFileExcel, faFileImage, faFilePdf, faFileWord, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import classnames from 'classnames'

interface IconFilenameProps {
  filename: string
  className?: classnames.Argument
}

interface IconIconProps {
  icon: IconDefinition
  className?: classnames.Argument
}

type IconProps = IconFilenameProps | IconIconProps

interface IconMap {
  icon: IconDefinition
  ext: string[]
}

const iconMap: IconMap[] = [
  {
    icon: faFileExcel,
    ext: ['xlsx', 'xls']
  },
  {
    icon: faFileWord,
    ext: ['doc', 'docx']
  },
  {
    icon: faFilePdf,
    ext: ['pdf']
  },
  {
    icon: faFileImage,
    ext: ['png', 'gif', 'jpg', 'jpeg']
  }
]

const getFileIcon = (ext?: string): IconDefinition => {
  if (ext !== undefined) {
    const icon = iconMap.find(i => {
      return i.ext.includes(ext)
    })
    if (icon !== undefined) {
      return icon.icon
    }
  }
  return faFile
}

let Icon: React.FC<IconProps> = (props) => {
  const ext = 'filename' in props ? props.filename.split('.').pop() : undefined
  const icon = 'icon' in props ? props.icon : getFileIcon(ext)
  const className = classnames(
    'float-start me-2 my-1',
    props.className
  )

  return (
    <FontAwesomeIcon
      data-testid='attachment-file-icon'
      icon={icon}
      className={className}
      style={{ fontSize: 'calc(2.2rem + 0.3vw)' }}
    />
  )
}
Icon = React.memo(Icon)

export default Icon
