import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateUpdateAttachmentMutation, MutateUpdateAttachmentMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateUpdateAttachment($id: MongoID!, $record: UpdateByIdAttachmentInput!) {
    attachments {
      updateById(_id: $id, record: $record) {
        recordId
      }
    }
  }
`)

export type UseMutateUpdateAttachmentReturn = UseMutationResult<
MutateUpdateAttachmentMutation,
unknown,
MutateUpdateAttachmentMutationVariables
>

const useMutateUpdateAttachment = (): UseMutateUpdateAttachmentReturn => {
  return useGraphMutation(document)
}

export { useMutateUpdateAttachment, document as mutateUpdateAttachmentDocument }
