import React, { useCallback, useContext, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { useQuery } from '@tanstack/react-query'

export interface CognitoUser {
  isAuthenticated: boolean
  attributes?: {
    name: string
    [key: string]: any
  }
}

export interface CognitoContextValue {
  user: CognitoUser
  login: () => Promise<void>
  logout: () => Promise<void>
}

const CognitoContext = React.createContext<CognitoContextValue | undefined>(undefined)

const getUser = async (): Promise<CognitoUser | null | undefined> => {
  try {
    return await Auth.currentUserInfo()
  } catch (e) {
    if (e instanceof Error && e.message === 'No current user') {
      return undefined
    }
    throw e
  }
}

const CognitoProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { data, remove } = useQuery(['congitoUser'], getUser)
  const user = { ...data, isAuthenticated: data !== undefined && data !== null }

  useEffect(() => {
    const hubListener = Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signedOut') {
        remove()
      }
    })
    return () => {
      hubListener()
    }
  }, [])

  const login = useCallback(async () => {
    try {
      await Auth.federatedSignIn({ customProvider: 'IMCOffice365' })
    } catch (e) {
      throw new Error('User could not be signed in.')
    }
  }, [])

  const logout = useCallback(async () => {
    try {
      remove()
      await Auth.signOut()
    } catch (e) {
      throw new Error('User could not be signed out.')
    }
  }, [])

  return (
    <CognitoContext.Provider value={{ user, login, logout }}>
      {props.children}
    </CognitoContext.Provider>
  )
}

const useCognito = (): CognitoContextValue => {
  const cognito = useContext(CognitoContext)
  if (cognito === undefined) {
    throw new Error('useCognito must be used inside the cognito context provider.')
  }
  return cognito
}

export { CognitoContext, CognitoProvider, useCognito }
