import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React, { Suspense } from 'react'
import { Dropdown, DropdownOption, Input } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { Values } from './FormContext'
import { useCandidateSubstatuses } from 'src/queries'
import FieldSuspense from 'src/components/Forms/FieldSuspense'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  candidateStatuses?: DropdownOption[]
  disableSave?: boolean
}

const Substatus: React.FC = () => {
  const { values } = useFormikContext<Values>()
  const enabled = typeof values.status === 'string' && values.status !== ''
  const substatuses = useCandidateSubstatuses(values.status, enabled)
  return <Dropdown name='substatus' label='Substatus' options={substatuses ?? []} />
}

const Form: React.FC<FormProps> = (props) => {
  const { candidateStatuses, hasError, toggle, isLoading, disableSave } = props
  const { handleSubmit, setFieldValue } = useFormikContext()
  const handleStatusChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    void setFieldValue('substatus', null)
    return e.target.value
  }
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the candidate details.',
        show: hasError ?? false
      }}
      >
        <Dropdown name='status' label='Status' options={candidateStatuses ?? []} onChange={handleStatusChange} />
        <Suspense fallback={<FieldSuspense label='Substatus' />}>
          <Substatus />
        </Suspense>
        <Input type='date' name='rejection_date' label='Rejection On' />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
