import React, { PropsWithChildren } from 'react'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { QueryClient } from '@tanstack/react-query'

import createIDBPersister from './createIDBPersister'
import IsRestoring from './IsRestoring'

const persister = createIDBPersister()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      cacheTime: 1000 * 60 * 60 * 24
    },
    mutations: {
      useErrorBoundary: true
    }
  }
})

const QueryProvider: React.FC<PropsWithChildren> = (props) => {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <IsRestoring>
        {props.children}
      </IsRestoring>
    </PersistQueryClientProvider>
  )
}

export default QueryProvider
