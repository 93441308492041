import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React from 'react'
import { Input } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { Values } from './FormContext'
import DateRangeForm from 'src/pages/cat/contracts/Contract/cards/PoP/EditModal/DateRangeForm'
import Options from 'src/pages/cat/contracts/Contract/cards/PoP/EditModal/Options'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading } = props
  const context = useFormikContext<Values>()
  const { handleSubmit } = context
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the contract dates.',
        show: hasError ?? false
      }}
      >
        <Input type='text' name='pop.status' label='PoP Status' />
        <DateRangeForm name='pop.base' label='Base' />
        <Options />
      </Body>
      <Footer
        toggle={toggle}
        onAction={async () => {
          handleSubmit()
        }}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary'
        }}
      />
    </>
  )
}

export default Form
