import React from 'react'
import List from './List'
import { useCandidateRatesById } from 'src/queries'

interface BodyProps {
  id: string
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const candidate = useCandidateRatesById(id)
  return (
    <List candidate={candidate} />
  )
}

export default Body
