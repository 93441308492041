import React, { useEffect } from 'react'
import { Colors, useCardContext } from '../../../../../../components/Card/CardContext'
import { usePositionDetailsById } from 'src/queries'

interface BodyProps {
  id: number
}

const statusColorMap: { [key: string]: Colors } = {
  Open: 'success',
  Paused: 'secondary',
  Tabled: undefined,
  Filled: undefined
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const position = usePositionDetailsById(id)
  const status = position?._status?.name
  const cardContext = useCardContext()
  useEffect(() => {
    if (typeof status === 'string') {
      cardContext.setColor(statusColorMap[status])
    }
  }, [status])
  return (
    <h2 className='text-uppercase fw-bold'>{status}</h2>
  )
}

export { statusColorMap }
export default Body
