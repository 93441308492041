import { Editor } from '@tiptap/react'
import Group from './Group'
import createButtons from './buttons'

interface ToolbarProps {
  editor: Editor
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { editor } = props
  const groups = createButtons(editor)
  return (
    <>
      {groups.map((group, i) => (
        <Group key={i} buttons={group} editor={editor} separator />
      ))}
    </>
  )
}

export default Toolbar
