import { Formik } from 'formik'
import React from 'react'
import { useMutateCreateContract } from 'src/queries'
import Form from './Form'
import useLookups from './useLookups'
import { MutateCreateContractMutation } from 'src/gql/graphql'
import { emptyStringToNull } from 'src/helpers/formHelpers'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

interface FormContextProps {
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { toggle } = props
  const navigate = useNavigate()
  const lookups = useLookups()
  const create = useMutateCreateContract()
  const handleSuccess = (data: MutateCreateContractMutation): void => {
    const id = String(data.cat?.contract?.createOne?.recordId ?? '')
    if (id !== '') {
      toggle()
      navigate(`/cat/contracts/${id}`)
    } else {
      throw new Error('There was an issue getting the contract ID.')
    }
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    abbreviation: Yup.string().required().label('Abbreviation'),
    teamSize: Yup.number().nullable().label('Team Size')
  })
  return (
    <Formik
      initialValues={{
        name: '',
        abbreviation: '',
        portfolio_manager: '',
        program_manager: '',
        teamSize: '',
        primary_address: {
          street: '',
          city: '',
          state: '',
          zip: ''
        }
      }}
      validationSchema={validationSchema}
      onSubmit={async (data) => {
        const teamSize = data.teamSize !== null || data.teamSize !== '' ? Number(data.teamSize) : null
        if (teamSize !== null && isNaN(teamSize)) {
          throw new Error('Team Size must be a number')
        }
        const record = {
          ...data,
          teamSize
        }
        emptyStringToNull(record, 'portfolio_manager')
        emptyStringToNull(record, 'program_manager')
        emptyStringToNull(record, 'teamSize')

        create.mutate({ record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <Form lookups={lookups} toggle={toggle} isLoading={create.isLoading} hasError={create.isError} />
      )}
    </Formik>
  )
}

export default FormContext
