import { graphql } from 'src/gql'
import { SortFindManyPortfolio_ManagerInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query portfolioManagers($sort: SortFindManyPortfolio_ManagerInput) {
    lookups {
      Portfolio_Manager {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type PortfolioManagers = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Portfolio_Manager']>['many']>

const usePortfolioManagers = useGraphQLDropdownOptions(
  document,
  'lookups.Portfolio_Manager.many',
  { sort: SortFindManyPortfolio_ManagerInput.NameAsc }
)

export { usePortfolioManagers, document as portfolioManagersDocument }
