import React from 'react'
import { Input } from 'reactstrap'
import { useOverviewState } from 'src/pages/cat/view/distributionoverview/useOverviewState'
import { useDistributionSubcategories } from 'src/queries'

const Dropdown: React.FC = (props) => {
  const { category, subcategory, dispatch } = useOverviewState()
  const subcategories = useDistributionSubcategories(category)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({ type: 'setSubcategory', payload: e.target.value })
  }
  return (
    <Input type='select' bsSize='lg' onChange={handleChange} value={subcategory} aria-label='Subcategory'>
      <option value=''>Select a Subcategory</option>
      {subcategories?.map((s, i) => (
        <option key={String(s.value)} value={s.value}>{s.label}</option>
      ))}
    </Input>
  )
}

export default Dropdown
