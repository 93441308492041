import React from 'react'
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import { DatePicker } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import RichTextEditor from 'src/components/Forms/RichTextEditor'
import { GroupedSubcategories } from './FormContext'
import CheckboxGroup from './CheckboxGroup'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
  subcategories?: GroupedSubcategories
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave } = props
  const subcategories = props.subcategories ?? []
  const { handleSubmit } = useFormikContext()
  return (
    <>
      <Body error={{
        error: 'There was an issue adding the position.',
        show: hasError ?? false
      }}
      >
        <DatePicker name='date_posted' label='Date Posted / Sent' />
        <div className='mb-3'>
          <RichTextEditor name='notes' />
        </div>
        <div className='px-1' style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '65vh' }}>
          {subcategories.map(g => {
            return (
              <CheckboxGroup key={g._id} label={g.name ?? ''} _id={g._id} options={g.subcategories} />
            )
          })}
        </div>
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Create',
          icon: isLoading === true ? faSpinner : faPlus,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
