import React, { Suspense } from 'react'
import { faChevronRight, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { Dropdown, DropdownOption, Input } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'
import { useCandidateSubstatuses, useCandidatesByName } from 'src/queries'
import FieldSuspense from 'src/components/Forms/FieldSuspense'
import { Values } from './FormContext'
import { FooterProps } from 'src/components/theme/Models/Modal/Footer'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
  lookups?: {
    statuses: DropdownOption[]
    types: DropdownOption[]
    distributions: DropdownOption[]
  }
  distribution?: string
  next: () => void
}

const Substatus: React.FC = () => {
  const { values } = useFormikContext<{ status?: string }>()
  const enabled = typeof values.status === 'string' && values.status !== ''
  const substatuses = useCandidateSubstatuses(values.status, enabled)
  return <Dropdown name='substatus' label='Substatus' options={substatuses ?? []} />
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave, distribution, next } = props
  const {
    statuses,
    types,
    distributions
  } = props.lookups ?? {}
  const { handleSubmit, validateForm, setTouched, setFieldValue, values } = useFormikContext<Values>()
  const {
    data: candidates,
    isLoading: isCandidatesLoading,
    isFetched: isCandidatesFetched,
    enabled: candidatesEnabled
  } = useCandidatesByName(values.name, { suspense: false })
  const handleStatusChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    void setFieldValue('substatus', null)
    return e.target.value
  }

  const action: FooterProps['action'] = isCandidatesFetched && candidates.length > 0
    ? {
        text: 'Next',
        icon: faChevronRight,
        color: 'primary',
        disabled: disableSave,
        iconPosition: 'right'
      }
    : {
        text: 'Create',
        icon: isLoading === true || (isCandidatesLoading && candidatesEnabled) ? faSpinner : faPlus,
        disabled: isLoading === true || !isCandidatesFetched || disableSave,
        color: 'primary'
      }

  const handleNext = async (): Promise<void> => {
    if (isCandidatesFetched && candidates.length > 0) {
      const errors = await validateForm()
      if (Object.keys(errors).length === 0) {
        next()
      } else {
        void setTouched(setNestedObjectValues<FormikTouched<Values>>(errors, true))
      }
    } else {
      handleSubmit()
    }
  }

  return (
    <>
      <Body error={{
        error: 'There was an issue adding the candidate.',
        show: hasError ?? false
      }}
      >
        <Input type='text' name='name.first' label='First Name' required />
        <Input type='text' name='name.last' label='Last Name' required />
        <Dropdown name='type' label='Type' options={types ?? []} />
        {distribution === undefined
          ? (
            <Dropdown name='distribution' label='Distribution' options={distributions ?? []} required />
            )
          : null}
        <Dropdown name='status' label='Status' options={statuses ?? []} onChange={handleStatusChange} />
        <Suspense fallback={<FieldSuspense label='Substatus' />}>
          <Substatus />
        </Suspense>
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleNext}
        action={action}
      />
    </>
  )
}

export default Form
