import React, { ReactNode } from 'react'
import Body, { BodyProps } from './Body'
import Footer, { FooterProps } from './Footer'
import Header, { HeaderProps } from './Header'
import { Modal as BSModal, ModalProps as BSModalProps } from 'reactstrap'

interface ModalBaseProps {
  isOpen: boolean
  title: string
  toggle: () => void
  size?: BSModalProps['size']
}

type ModalPropsWithoutCustomRender = ModalBaseProps & HeaderProps & BodyProps & FooterProps

interface ModalPropsWithCustomRender extends ModalBaseProps {
  children: ReactNode
}

type ModalProps = ModalPropsWithoutCustomRender | ModalPropsWithCustomRender

const Modal: React.FC<ModalProps> = (props) => {
  const { isOpen, title, toggle, size } = props
  return (
    <BSModal
      isOpen={isOpen}
      toggle={toggle}
      centered
      data-testid={`${title.split(' ').join('-')}-modal`.toLowerCase()}
      size={size}
    >
      {'action' in props
        ? (
          <>
            <Header {...props} />
            <Body {...props} />
            <Footer {...props} />
          </>
          )
        : (<>{props.children}</>)}
    </BSModal>
  )
}

export default Modal
export { Header, Body, Footer }
