import React from 'react'
import { Formik } from 'formik'
import { UseMutatePositionByIdReturn, positionNotesByIdDocument, useMutatePositionById, usePositionNotesById } from 'src/queries'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { MutatePositionByIdMutation } from 'src/gql/graphql'
import Form from './Form'
import parsePlainText from 'src/helpers/parsePlainText'

type Values = Parameters<UseMutatePositionByIdReturn['mutate']>[0]['record']

interface FormContextProps {
  id: number
  toggle: () => void
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const { id, toggle } = props
  const position = usePositionNotesById(id)
  if (position === undefined) {
    throw new Error('Could not load position!')
  }
  const update = useMutatePositionById()
  let notes = position.notes ?? ''
  notes = parsePlainText(notes)
  const initialState: Values = {
    notes
  }
  const invalidatePosition = useInvalidateQueryByDocument(positionNotesByIdDocument)
  const handleSuccess = (data: MutatePositionByIdMutation): void => {
    const id = data.cat?.position?.updateById?.recordId
    if (typeof id === 'number') {
      void invalidatePosition({ id })
    }
    toggle()
  }
  return (
    <Formik
      initialValues={initialState}
      onSubmit={async (data) => {
        const record = { ...data }
        update.mutate({ id, record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => (
        <>
          <Form toggle={toggle} isLoading={update.isLoading} hasError={update.isError} />
        </>
      )}
    </Formik>
  )
}

export default FormContext
