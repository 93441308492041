import { useField } from 'formik'
import React, { Fragment, useMemo } from 'react'
import Checkbox from 'src/components/Forms/Checkbox'
import { Col, FormGroup, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'

enum ParentCheckboxState {
  Checked = 'checked',
  Indeterminate = 'indeterminate',
  Unchecked = 'unchecked'
}

interface IconProps {
  value: ParentCheckboxState
  label: string
  onClick: React.MouseEventHandler<HTMLInputElement | HTMLLabelElement>
}

const ParentCheckbox: React.FC<IconProps> = (props) => {
  const { value, label, onClick } = props
  const checkboxRef = React.createRef<HTMLInputElement | HTMLTextAreaElement>()

  React.useEffect(() => {
    if (checkboxRef.current instanceof HTMLInputElement) {
      if (value === ParentCheckboxState.Checked) {
        checkboxRef.current.checked = true
        checkboxRef.current.indeterminate = false
      } else if (value === ParentCheckboxState.Unchecked) {
        checkboxRef.current.checked = false
        checkboxRef.current.indeterminate = false
      } else if (value === ParentCheckboxState.Indeterminate) {
        checkboxRef.current.checked = false
        checkboxRef.current.indeterminate = true
      }
    }
  }, [value])

  return (
    <FormGroup check inline>
      <Input innerRef={checkboxRef} id={label} type='checkbox' onClick={onClick} />
      <Label check className='fw-bold' for={label} onClick={onClick}>
        {label}
      </Label>
    </FormGroup>
  )
}

interface CheckboxGroupProps {
  label: string
  _id: string
  options: Array<{
    value: string | number
    label: string
    disabled?: boolean
  }>
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const { options, label, _id } = props
  const fieldName = `subcategories.${_id}`
  const [, meta, helpers] = useField(fieldName)
  const checkableOptions = useMemo(() => {
    return options.filter(v => v.disabled !== true)
  }, [options])
  const groupCheckValue = useMemo(() => {
    if (meta.value === undefined) {
      return ParentCheckboxState.Unchecked
    }
    if (meta.value.length === checkableOptions.length) {
      return ParentCheckboxState.Checked
    } else if (meta.value.length > 0) {
      return ParentCheckboxState.Indeterminate
    } else {
      return ParentCheckboxState.Unchecked
    }
  }, [meta.value, options])

  const handleGroupCheck = (): void => {
    switch (groupCheckValue) {
      case ParentCheckboxState.Checked:
        void helpers.setValue([])
        return
      default:
        void helpers.setValue(checkableOptions.map((v) => v.value))
    }
  }

  return (
    <Row className='mb-4'>
      <Col>
        <ParentCheckbox label={label} value={groupCheckValue} onClick={handleGroupCheck} />
        {options.map((v) => {
          const wrapperId = 'checkboxWrapper-' + String(v.value)
          return (
            <Fragment key={wrapperId}>
              <div className='ms-4' id={wrapperId}>
                <Checkbox {...v} name={fieldName} id={v.label} />
              </div>
              {v.disabled === true
                ? (
                  <UncontrolledTooltip target={wrapperId} placement='left'>
                    This distribution has already been created.
                  </UncontrolledTooltip>
                  )
                : null}
            </Fragment>
          )
        })}
      </Col>
    </Row>
  )
}

export default CheckboxGroup
