import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateCreateContractMutation, MutateCreateContractMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateCreateContract($record: CreateOneCat_ContractInput!) {
    cat {
      contract {
        createOne(record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateCreateContractReturn = UseMutationResult<
MutateCreateContractMutation,
unknown,
MutateCreateContractMutationVariables
>

const useMutateCreateContract = (): UseMutateCreateContractReturn => {
  return useGraphMutation(document)
}

export { useMutateCreateContract, document as mutateCreateContractDocument }
