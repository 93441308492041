import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import RouteError from '../../../../helpers/RouteError'
import Details from './cards/Details'
import PositionDetails from './cards/PositionDetails'
import Notes from './cards/Notes'
import CreatedUpdated from './cards/CreatedUpdated'
import Attachments from '../../../../components/Attachments'
import Candidates from './cards/Candidates'
import { useDistributionDetailsById } from 'src/queries'

const Distribution: React.FC = (props) => {
  const { id } = useParams()
  if (id === undefined) {
    throw new RouteError('Distribution was not found.', { status: 404 })
  }
  const distribution = useDistributionDetailsById(id)
  if (distribution === null) {
    throw new RouteError(`Distribution "${id}" was not found.`, { status: 404 })
  }
  return (
    <>
      <Row className='mb-4 gy-4'>
        <Col>
          <Details id={id} />
        </Col>
        <Col>
          <PositionDetails id={id} />
        </Col>
      </Row>
      <Row className='gy-4'>
        <Col md='6'>
          <Candidates id={id} positionId={distribution?._position?._id} />
        </Col>
        <Col>
          <Row className='gy-4'>
            <Col xs='12'>
              <Notes id={id} />
            </Col>
            <Col xs='12'>
              <Attachments link={id} type='605897f29c5e6a11b0083ac1' model='Distribution' editPermissionKey='CAT_EDIT' />
            </Col>
            <Col xs='12'>
              <CreatedUpdated id={id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Distribution
