import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React from 'react'
import { Col, Row } from 'reactstrap'
import { Input } from 'src/components/Forms'
import Phone from 'src/components/Forms/Phone'
import { Body, Footer } from 'src/components/theme/Models/Modal'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave } = props
  const { handleSubmit } = useFormikContext()
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the candidate contact info.',
        show: hasError ?? false
      }}
      >
        <Input type='text' name='email' label='E-Mail' />
        <Phone name='phone' label='Phone' />
        <Input type='text' name='address.street' label='Address' />
        <Row>
          <Col sm={12} md={6}>
            <Input type='text' name='address.city' label='City' />
          </Col>
          <Col sm={12} md={3}>
            <Input type='text' name='address.state' label='State' maxLength={2} />
          </Col>
          <Col sm={12} md={3}>
            <Input type='text' name='address.zip' label='Zip' maxLength={5} />
          </Col>
        </Row>
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
