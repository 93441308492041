import React from 'react'
import { FieldHookConfig, useField } from 'formik'
import { FormGroup, Input, Label } from 'reactstrap'

export type CheckboxProps = FieldHookConfig<any> & {
  label: string
  id: string
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { label, id, ...rest } = props
  if (typeof rest.value !== 'string') {
    throw new Error('Checkbox value must be a string!')
  }
  const [field] = useField({ ...rest, type: 'checkbox' })
  return (
    <FormGroup check>
      <Input {...field} disabled={rest.disabled} id={String(id)} type='checkbox' />
      {' '}
      <Label check for={String(id)}>
        {label}
      </Label>
    </FormGroup>
  )
}

export default Checkbox
