import React from 'react'
import { Progress } from 'reactstrap'

interface UploadingProps {
  percent?: number
  children: React.ReactNode
}

const Uploading: React.FC<UploadingProps> = (props) => {
  const { percent, children } = props

  if (percent === undefined) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <Progress value={percent} style={{ height: '25px' }} color='info' barClassName='text-dark' animated>
      Uploading ({Math.round(percent)}%)...
    </Progress>
  )
}

export default Uploading
