import { FieldArray, useField } from 'formik'
import React from 'react'
import DateRangeForm from './DateRangeForm'
import { ContractPopById } from 'src/queries'
import { add } from 'date-fns'
import { Button, Col, Row } from 'reactstrap'
import isValid from 'date-fns/isValid'

type OptionsValues = NonNullable<NonNullable<ContractPopById['pop']>['options']>

const Options: React.FC = (props) => {
  const [inputProps, meta] = useField<OptionsValues>('pop.options')
  const options = inputProps.value
  const errors = meta.error
  let prevDate = options[options.length - 1]?.end
  prevDate = typeof prevDate === 'string' ? new Date(prevDate) : prevDate
  prevDate = isValid(prevDate) ? prevDate : undefined
  const nextDate = add(prevDate, {
    days: 1
  })
  if (options === undefined) {
    return null
  }

  return (
    <FieldArray
      name='pop.options'
      render={({ push, remove }) => (
        <>
          <div>
            {options.map((_, index) => (
              <DateRangeForm key={index} name={`pop.options[${index}]`} label={`Option ${index + 1}`} remove={() => remove(index)} />
            ))}
            {typeof errors === 'string' && (
              <Row className='text-danger'>
                <Col className='mb-3'>
                  {errors}
                </Col>
              </Row>
            )}
            <Button type='button' color='link' onClick={() => push({ start: nextDate })}>
              + Add Option
            </Button>
          </div>
        </>
      )}
    />
  )
}

export default Options
