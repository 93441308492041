import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'
import { SortFindManyPosition_StatusInput } from 'src/gql/graphql'

const document = graphql(/* graphql */`
  query positionStatuses ($sort: SortFindManyPosition_StatusInput) {
    lookups {
      Position_Status {
        many(sort: $sort) {
          _id
          name
          order
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type PositionStatuses = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Position_Status']>['many']>

const usePositionStatuses = (): PositionStatuses => {
  const { data } = useGraphQL(document, { sort: SortFindManyPosition_StatusInput.OrderAsc })
  const statuses = data?.lookups?.Position_Status?.many
  return statuses ?? []
}

export { usePositionStatuses, document as positionStatusDocument }
