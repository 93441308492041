import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons'

import { useAuth } from '../hooks/useAuth'
import { Navigate } from 'react-router-dom'

const Login: React.FC = () => {
  const { isAuthenticated, cognito } = useAuth()

  if (isAuthenticated) {
    return <Navigate to='/' />
  }

  const handleLogin = (): void => {
    void cognito.login()
  }

  return (
    <div className='d-flex flex-column text-center p-4 align-items-center justify-content-center' style={{ height: '35vh' }}>
      <Row>
        <Col>
          <Button size='lg' color='primary' onClick={handleLogin} className='px-5 py-3'>
            <FontAwesomeIcon icon={faMicrosoft} className='me-3' size='lg' />
            Sign In with Microsoft
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default Login
