import React from 'react'
import { Button, ModalFooter, ButtonProps } from 'reactstrap'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

interface Action {
  text: string
  icon: FontAwesomeIconProps['icon']
  color: ButtonProps['color']
  disabled?: boolean
  onAction?: () => Promise<void> | void
  iconPosition?: 'left' | 'right'
}

export interface FooterProps {
  onCancel?: () => void
  onAction: () => Promise<void> | void
  cancel?: {
    text?: string
    disabled?: boolean
  }
  action: Action | Action[]
  toggle: () => void
}

const Footer: React.FC<FooterProps> = (props) => {
  const {
    onCancel,
    onAction,
    toggle,
    action
  } = props
  const actions = Array.isArray(action) ? action : [action]
  const cancel = Object.assign({ text: 'Cancel' }, props.cancel)

  const onCancelWrapper = (): void => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    toggle()
  }

  const handleAction = (actionFn?: Action['onAction']): void => {
    if (typeof actionFn === 'function') {
      void actionFn()
    } else {
      void onAction()
    }
  }

  return (
    <ModalFooter>
      <Button disabled={cancel.disabled === true} color='secondary' onClick={onCancelWrapper}>
        {cancel.text}
      </Button>
      {actions.map((action) => {
        const {
          disabled,
          color,
          onAction,
          icon,
          text,
          iconPosition = 'left'
        } = action
        return (
          <Button
            disabled={disabled === true}
            color={color}
            onClick={() => handleAction(onAction)}
            key={text}
          >
            {iconPosition === 'left'
              ? (
                <><FontAwesomeIcon icon={icon} />{' '}</>
                )
              : null}
            {text}
            {iconPosition === 'right'
              ? (
                <>{' '}<FontAwesomeIcon icon={icon} /></>
                )
              : null}
          </Button>
        )
      })}
    </ModalFooter>
  )
}

export default Footer
