import { GraphQLClient } from 'graphql-request'
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

const apiPrefix = process.env.REACT_APP_API_URL ?? 'http://localhost:4000'
const url = `${apiPrefix}/graphql`
const client = new GraphQLClient(url, {
  requestMiddleware: async (request) => {
    let user: CognitoUserSession | undefined
    try {
      user = await Auth.currentSession()
    } catch (e) {
      if (e instanceof Error && e.message === 'No current user') {
        return request
      }
    }

    if (user === undefined) {
      throw new Error('User is not authenticated')
    }
    const token = user.getAccessToken().getJwtToken()
    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: token
      }
    }
  }
})

export function useGraphMutation<TResult, TVariables> (
  document: TypedDocumentNode<TResult, TVariables>,
  options?: Parameters<typeof useMutation<TResult, unknown, TVariables>>[2]
): UseMutationResult<TResult, unknown, TVariables> {
  return useMutation<TResult, unknown, TVariables>(async (v) => {
    try {
      return await client.request(
        document,
        v as any
      )
    } catch (e: any) {
      const errors = e?.response?.errors
      const error = Array.isArray(errors) ? errors[0]?.message : undefined
      let message = error ?? ''
      message = message === '' && 'message' in e ? e.message : message
      throw new Error(message)
    }
  },
  options
  )
}
