import { graphql } from 'src/gql'
import { SortFindManyCandidate_TypeInput } from 'src/gql/graphql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQLDropdownOptions } from 'src/queries/cat/lookups/helpers'

const document = graphql(/* graphql */`
  query candidateTypes($sort: SortFindManyCandidate_TypeInput) {
    lookups {
      Candidate_Type {
        many(sort: $sort) {
          _id
          name
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type CandidateTypes = NonNullable<NonNullable<NonNullable<NonNullable<TData>['lookups']>['Candidate_Type']>['many']>

const useCandidateTypes = useGraphQLDropdownOptions(
  document,
  'lookups.Candidate_Type.many',
  { sort: SortFindManyCandidate_TypeInput.NameAsc }
)

export { useCandidateTypes, document as candidateTypesDocument }
