import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'
import { CandidatesQueryVariables } from '../../../../gql/graphql'

const document = graphql(/* graphql */`
  query candidates($filter: FilterFindManyCandidateInput) {
    cat {
      candidate {
        many(filter: $filter) {
          _id
          attachments_count
          name {
            first
            last
          }
          _distribution {
            _category {
              name
            }
            _subcategory {
              _id
              name
            }
          }
          _status {
            name
          }
          _substatus {
            name
            color
          }
          bill_rate {
            min
            max
          }
          salary {
            min
            max
          }
          round_1
          round_2
          round_3
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type Candidates = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['candidate']>['many']>

const useCandidates = (filter: CandidatesQueryVariables['filter'], enabled: boolean = true): Candidates => {
  const { data } = useGraphQL(document, {
    filter
  }, {
    enabled
  })
  return data?.cat?.candidate?.many ?? []
}

export { useCandidates, document as candidatesDocument }
