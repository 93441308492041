import { Editor } from '@tiptap/react'
import Button from './Button'
import { ButtonProps } from './buttons'

interface GroupProps {
  buttons: ButtonProps[]
  editor: Editor
  separator: boolean
}

const Group: React.FC<GroupProps> = (props) => {
  const { buttons, editor, separator } = props
  return (
    <>
      {buttons.map((btnProps) => (
        <Button key={btnProps.title} {...btnProps} editor={editor} />
      ))}
      {separator && <div className='vr my-1 text-black' />}
    </>
  )
}

export default Group
