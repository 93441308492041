import React from 'react'
import { Input } from 'reactstrap'
import { useOverviewState } from 'src/pages/cat/view/distributionoverview/useOverviewState'
import { usePositionStatuses } from 'src/queries'

const Status: React.FC = (props) => {
  const { status, dispatch } = useOverviewState()
  const statuses = usePositionStatuses()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({ type: 'setStatus', payload: e.target.value })
  }
  return (
    <Input type='select' bsSize='sm' onChange={handleChange} value={status} aria-label='Status'>
      <option value=''>Select a Status</option>
      {statuses.map((s, i) => (
        <option key={s._id} value={s._id}>{s.name}</option>
      ))}
      <option value='all'>All</option>
    </Input>
  )
}

export default Status
