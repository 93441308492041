import { Formik } from 'formik'
import React, { Suspense, useState } from 'react'
import { useCandidateStatuses, useCandidateTypes, useDistributionsByPositionId, useMutateCreateCandidate } from 'src/queries'
import Form from './Form'
import { MutateCreateCandidateMutation, MutateCreateCandidateMutationVariables } from 'src/gql/graphql'
import { emptyStringToNull } from 'src/helpers/formHelpers'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Names from 'src/components/SharedModals/AddCandidateModal/Names'
import NamesLoading from 'src/components/SharedModals/AddCandidateModal/NamesLoading'

interface FormContextProps {
  toggle: () => void
  position: number
  distribution?: string
}

export type Values = MutateCreateCandidateMutationVariables['record']

export enum PAGES {
  Form = 'FORM',
  CandidateCheck = 'CANDIDATE_CHECK'
}

const FormContext: React.FC<FormContextProps> = (props) => {
  const [page, setPage] = useState<PAGES>(PAGES.Form)
  const { toggle, position, distribution } = props
  const navigate = useNavigate()
  const create = useMutateCreateCandidate()
  const statuses = useCandidateStatuses()
  const types = useCandidateTypes()
  const distributions = useDistributionsByPositionId(position)
  const lookups = {
    statuses,
    types,
    distributions: distributions.map(d => {
      return { value: d._id, label: `${d._subcategory?.name ?? ''} (${d._category?.name ?? ''})` }
    })
  }
  const handleSuccess = (data: MutateCreateCandidateMutation): void => {
    const id = data.cat?.candidate?.createOne?.recordId ?? undefined
    if (id !== undefined) {
      toggle()
      navigate(`/cat/candidates/${String(id)}`)
    } else {
      throw new Error('There was an issue getting the candidate\'s ID.')
    }
  }
  const validationSchema = Yup.object().shape({
    name: Yup.object().shape({
      first: Yup.string().required().label('First Name'),
      last: Yup.string().required().label('Last Name')
    }),
    distribution: Yup.string().required().label('Distribution')
  })
  return (
    <Formik
      initialValues={{
        name: {
          first: '',
          last: ''
        },
        type: '',
        position,
        distribution: distribution ?? '',
        status: '',
        substatus: ''
      }}
      validationSchema={validationSchema}
      onSubmit={async (data) => {
        const record = { ...data }
        emptyStringToNull(record, 'distribution')
        emptyStringToNull(record, 'type')
        emptyStringToNull(record, 'status')
        emptyStringToNull(record, 'substatus')

        create.mutate({ record }, { onSuccess: handleSuccess })
      }}
    >
      {(props) => {
        if (page === PAGES.Form) {
          return (
            <Form
              toggle={toggle}
              isLoading={create.isLoading}
              hasError={create.isError}
              lookups={lookups}
              distribution={distribution}
              next={() => setPage(PAGES.CandidateCheck)}
            />
          )
        } else if (page === PAGES.CandidateCheck) {
          return (
            <Suspense fallback={<NamesLoading />}>
              <Names toggle={toggle} back={() => setPage(PAGES.Form)} />
            </Suspense>
          )
        }
      }}
    </Formik>
  )
}

export default FormContext
