import React from 'react'
import Desktop from './Desktop'
import Mobile from './Mobile'

interface FullPageProps {
  children: React.ReactNode
}

const FullPage: React.FC<FullPageProps> = (props) => {
  const { children } = props
  const publicUrl = process.env.REACT_APP_PUBLIC_URL
  const logo = `${publicUrl != null ? publicUrl : ''}/imc_header_logo_box_275px.png`
  return (
    <div className='w-100 bg-light bg-gradiant'>
      <div className='d-none d-lg-block'>
        <Desktop logo={logo}>
          {children}
        </Desktop>
      </div>
      <div className='d-lg-none'>
        <Mobile logo={logo}>
          {children}
        </Mobile>
      </div>
    </div>
  )
}

export default FullPage
