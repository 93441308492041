import React, { Suspense } from 'react'
import Card from '../../../../../../components/Card'
import Body from './Body'
import { Table } from '@imcva/reactstrap-table'
import { columns } from './columns'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import useToggleState from 'src/hooks/useToggleState'
import AddCandidateModal from 'src/components/SharedModals/AddCandidateModal'

interface CandidatesProps {
  id: string
  positionId?: number
}

const Candidates: React.FC<CandidatesProps> = (props) => {
  const { id, positionId } = props
  const [isOpen, , handleToggle] = useToggleState()

  return (
    <>
      <Card
        title='Candidates'
        primaryAction={{
          key: 'add',
          icon: faPlus,
          text: 'Add Candidate',
          onClick: handleToggle,
          permissionKey: 'CAT_EDIT'
        }}
      >
        <Suspense fallback={<Table options={{ data: [], columns }} suspend={3} />}>
          <Body id={id} />
        </Suspense>
      </Card>
      {typeof positionId === 'number'
        ? (
          <AddCandidateModal position={positionId} distribution={id} isOpen={isOpen} toggle={handleToggle} />
          )
        : null}
    </>
  )
}

export default Candidates
