import React, { Suspense } from 'react'
import Card from 'src/components/Card'
import Body from './Body'
import List from './List'

interface PositionCountsProps {
  id: string
}

const PositionCounts: React.FC<PositionCountsProps> = (props) => {
  const { id } = props

  return (
    <>
      <Card
        title='Number of Positions'
      >
        <Suspense fallback={<List counts={undefined} />}>
          <Body id={id} />
        </Suspense>
      </Card>
    </>
  )
}

export default PositionCounts
