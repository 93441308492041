import { useMemo } from 'react'
import { notEmpty } from 'src/helpers/formHelpers'
import { useOverviewState } from 'src/pages/cat/view/distributionoverview/useOverviewState'
import { useDistributionsOverview } from 'src/queries'

type Position = NonNullable<ReturnType<typeof useDistributionsOverview>[0]['_position']>

const usePositions = (): Position[] => {
  const { status, positionQueryEnabled, category, subcategory } = useOverviewState()
  const distributions = useDistributionsOverview({
    filter: {
      category,
      subcategory
    }
  }, {
    enabled: positionQueryEnabled
  })
  const positions = useMemo(() => {
    return distributions?.filter((d) => {
      return status === 'all' || d._position?._status?._id === status
    }).map(d => d._position).filter(notEmpty)
  }, [distributions, status])
  return positions
}

export default usePositions
