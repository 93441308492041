import { get, set, del } from 'idb-keyval'
import { PersistedClient, Persister } from '@tanstack/react-query-persist-client'

const createIDBPersister = (idbValidKey: IDBValidKey = 'reactQuery'): Persister => {
  const persister: Persister = {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client)
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey)
    },
    removeClient: async () => {
      await del(idbValidKey)
    }
  }
  return persister
}

export default createIDBPersister
