import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import DateDisplay from '../../../../../../components/DateDisplay'
import { DistributionsByPositionId } from 'src/queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'

const columnHelper = createColumnHelper<DistributionsByPositionId[0]>()

const columns = [
  columnHelper.accessor('attachments_count', {
    header: 'Attachments',
    cell: (info) => {
      const value = info.getValue() ?? 0
      return value > 0
        ? (
          <span className='px-3'>{value} <FontAwesomeIcon size='sm' icon={faPaperclip} /></span>
          )
        : null
    }
  }),
  columnHelper.accessor((r) => {
    return r._subcategory?.name ?? 'Unknown'
  }, {
    header: 'Subcategory',
    cell: (info) => {
      const value = info.getValue()
      const id = info.row.original._id
      if (id !== undefined) {
        return (
          <Link to={`/cat/distributions/${String(id)}`}>{value}</Link>
        )
      }
      return value
    }
  }),
  columnHelper.accessor('date_posted', {
    header: 'Date Posted',
    cell: (info) => {
      const value = info.getValue()
      return (
        <DateDisplay date={value} />
      )
    }
  }),
  columnHelper.accessor('candidate_count', {
    header: '# of Candidates'
  })
]

export { columns }
