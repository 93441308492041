import React, { useRef } from 'react'
import { Tooltip } from 'reactstrap'
import { dates } from 'src/helpers/displayhelpers'
import useToggleState from 'src/hooks/useToggleState'

interface FullDateDisplayProps {
  id?: string
  display?: keyof typeof dates
  hover?: keyof typeof dates
  date: Date | string | undefined | null
}

interface DistanceDisplayDateDisplayProps extends FullDateDisplayProps {
  display: 'distance'
  baseDate?: Date
}

interface DistanceHoverDateDisplayProps extends FullDateDisplayProps {
  hover: 'distance'
  baseDate?: Date
}

export type DateDisplayProps = FullDateDisplayProps | DistanceDisplayDateDisplayProps | DistanceHoverDateDisplayProps

const DateDisplay: React.FC<DateDisplayProps> = (props) => {
  const {
    display = 'dateOnly',
    hover = 'distance',
    date
  } = props

  const [isOpen, , toggle] = useToggleState(false)
  const spanRef = useRef<HTMLElement>(null)

  const displayFn = dates[display]
  const hoverFn = dates[hover]
  const baseDate = 'baseDate' in props ? props.baseDate : undefined

  return (
    <>
      <span ref={spanRef} onMouseEnter={toggle} onMouseLeave={toggle}>
        {
          display === 'distance'
            ? displayFn(date, baseDate)
            : displayFn(date)
        }
      </span>
      {
        spanRef.current !== null && (
          <Tooltip
            isOpen={isOpen}
            target={spanRef}
            toggle={toggle}
          >
            {
              hover === 'distance'
                ? hoverFn(date, baseDate)
                : hoverFn(date)
            }
          </Tooltip>
        )
      }
    </>
  )
}

export default DateDisplay
