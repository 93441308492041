import { DropdownOption } from 'src/components/Forms'
import { UseGraphqlOptions, useGraphQL } from 'src/hooks/useGraphQL'
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import get from 'lodash/get'
import { createDropdownOptions } from 'src/helpers/formHelpers'

type HookHandler<TResult, TVariables> = (
  variables?: TVariables,
  options?: UseGraphqlOptions<TResult>,
) => DropdownOption[]

export function useGraphQLDropdownOptions<TResult, TVariables> (
  document: TypedDocumentNode<TResult, TVariables>,
  itemKey: string,
  defaultVariables?: TVariables,
  defaultOptions?: UseGraphqlOptions<TResult>
): HookHandler<TResult, TVariables> {
  const handler: HookHandler<TResult, TVariables> = (handlerVariable, handlerOptions) => {
    const variables = Object.assign({}, defaultVariables, handlerVariable)
    const options = Object.assign({}, defaultOptions, handlerOptions)
    const { data } = useGraphQL(document, variables, options)
    const items = get(data ?? {}, itemKey)
    if (!Array.isArray(items) && items !== undefined && items !== null) {
      throw new Error(`Did not get an array of items back for ${itemKey}!`)
    }
    return createDropdownOptions(items ?? [])
  }
  return handler
}
