import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React from 'react'
import { Dropdown, DropdownOption, Input } from 'src/components/Forms'
import YesNo from 'src/components/Forms/YesNo'
import { Body, Footer } from 'src/components/theme/Models/Modal'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  candidateTypes?: DropdownOption[]
  clearances?: DropdownOption[]
  disableSave?: boolean
}

const Form: React.FC<FormProps> = (props) => {
  const { candidateTypes, clearances, hasError, toggle, isLoading, disableSave } = props
  const { handleSubmit } = useFormikContext()
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the candidate details.',
        show: hasError ?? false
      }}
      >
        <Input type='text' name='name.first' label='First Name' />
        <Input type='text' name='name.last' label='Last Name' />
        <Input type='date' name='received_on' label='Received On' />
        <Input type='text' name='location' label='Location' />
        <Dropdown name='type' label='Type' options={candidateTypes ?? []} />
        <Dropdown name='clearance' label='Clearance' options={clearances ?? []} />
        <YesNo name='reapply' label='Reapply' />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
