import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { UseGraphqlOptions, useGraphQL } from 'src/hooks/useGraphQL'
import { AllDataMigrationsQueryVariables } from 'src/gql/graphql'

const document = graphql(/* graphql */`
  query AllDataMigrations {
    migrations {
      all {
        name
        path
        status
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type AllMigrations = NonNullable<NonNullable<NonNullable<TData>['migrations']>['all']>

const useAllDataMigrations = (variables?: AllDataMigrationsQueryVariables, options?: UseGraphqlOptions<TData>, enabled: boolean = true): AllMigrations => {
  const internalVariables: AllDataMigrationsQueryVariables = Object.assign({
    limit: 1000
  }, variables)
  const { data } = useGraphQL(document, internalVariables, options)
  return data?.migrations?.all ?? []
}

export { useAllDataMigrations, document as allDataMigrationsDocument }
