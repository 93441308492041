import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useFormikContext } from 'formik'
import React from 'react'
import { Currency, DatePicker } from 'src/components/Forms'
import { Body, Footer } from 'src/components/theme/Models/Modal'

interface FormProps {
  toggle: () => void
  isLoading?: boolean
  hasError?: boolean
  disableSave?: boolean
}

const Form: React.FC<FormProps> = (props) => {
  const { hasError, toggle, isLoading, disableSave } = props
  const { handleSubmit } = useFormikContext()
  return (
    <>
      <Body error={{
        error: 'There was an issue updating the position rates.',
        show: hasError ?? false
      }}
      >
        <DatePicker name='pricing.requested' label='Requested' />
        <DatePicker name='pricing.received' label='Received' />
        <Currency name='bill_rate.min' label='Bill Rate (Min)' />
        <Currency name='bill_rate.max' label='Bill Rate (Max)' />
        <Currency name='salary.min' label='Salary (Min)' />
        <Currency name='salary.max' label='Salary (Max)' />
      </Body>
      <Footer
        toggle={toggle}
        onAction={handleSubmit}
        action={{
          text: 'Save',
          icon: isLoading === true ? faSpinner : faSave,
          color: 'primary',
          disabled: disableSave === true || isLoading
        }}
      />
    </>
  )
}

export default Form
