import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateDistributionByIdMutation, MutateDistributionByIdMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'
import { useInvalidateQueryByDocument } from 'src/hooks/useGraphQL'
import { distributionCreatedUpdatedByIdDocument } from '../useDistributionCreatedUpdatedById'

const document = graphql(/* graphql */`
  mutation mutateDistributionById($id: MongoID!, $record: UpdateByIdDistributionInput!) {
    cat {
      distribution {
        updateById(_id: $id, record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateDistributionByIdReturn = UseMutationResult<
MutateDistributionByIdMutation,
unknown,
MutateDistributionByIdMutationVariables
>

const useMutateDistributionById = (): UseMutateDistributionByIdReturn => {
  const invalidateCreatedUpdated = useInvalidateQueryByDocument(distributionCreatedUpdatedByIdDocument)
  return useGraphMutation(document, {
    onSuccess: (data) => {
      const id = data.cat?.distribution?.updateById?.recordId
      if (typeof id === 'number') {
        void invalidateCreatedUpdated({ id })
      }
    }
  })
}

export { useMutateDistributionById, document as mutateDistributionByIdDocument }
