import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateDataMigrationDownMutation, MutateDataMigrationDownMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateDataMigrationDown {
    migrations {
      down {
        name
      }
    }
  }
`)

export type UseMutateDataMigrationDownReturn = UseMutationResult<
MutateDataMigrationDownMutation,
unknown,
MutateDataMigrationDownMutationVariables
>

const useMutateDataMigrationDown = (): UseMutateDataMigrationDownReturn => {
  return useGraphMutation(document)
}

export { useMutateDataMigrationDown, document as mutateDataMigrationDownDocument }
