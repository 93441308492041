import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import DateDisplay from 'src/components/DateDisplay'
import { PositionJobDescById } from 'src/queries'

interface ListProps {
  position: PositionJobDescById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { position } = props
  const section1: Array<Omit<ItemProps, 'id'>> = [
    {
      title: 'Received',
      value: <DateDisplay id='jdReceived' date={position?.job_desc_received} />
    },
    {
      title: 'Finalized',
      value: <DateDisplay id='jdFinalized' date={position?.job_desc_finalized} />
    },
    {
      title: 'Posted',
      value: <DateDisplay id='jdPosted' date={position?.job_desc_posted} />
    }
  ]
  const dlProps = {
    className: 'row-cols-1',
    suspended: position === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section1.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
