import React from 'react'
import DisplayHtml from 'src/components/Forms/RichTextEditor/DisplayHtml'
import { useContractNotesById } from 'src/queries'

interface BodyProps {
  id: string
}

const Body: React.FC<BodyProps> = (props) => {
  const { id } = props
  const Contract = useContractNotesById(id)
  console.log({ Contract })
  return (
    <DisplayHtml value={Contract?.notes ?? ''} />
  )
}

export default Body
