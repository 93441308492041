import { graphql } from 'src/gql'
import { GetTData } from 'src/gql/helpers'
import { useGraphQL } from 'src/hooks/useGraphQL'

const document = graphql(/* graphql */`
  query distributionCreatedUpdatedById($id: MongoID!) {
    cat {
      distribution {
        byId(_id: $id) {
          _id
          _createdBy {
            name
          }
          _updatedBy {
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`)

type TData = GetTData<typeof document>
export type DistributionCreatedUpdatedById = NonNullable<NonNullable<NonNullable<NonNullable<TData>['cat']>['distribution']>['byId']>

const useDistributionCreatedUpdatedById = (id: string): DistributionCreatedUpdatedById | undefined => {
  const { data } = useGraphQL(document, { id }, {
    enabled: typeof id === 'string'
  })
  const distribution = data?.cat?.distribution?.byId ?? undefined
  return distribution
}

export { useDistributionCreatedUpdatedById, document as distributionCreatedUpdatedByIdDocument }
