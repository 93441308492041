import { UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'src/gql'
import { MutateCreatePositionMutation, MutateCreatePositionMutationVariables } from 'src/gql/graphql'
import { useGraphMutation } from 'src/hooks/useGraphMutation'

const document = graphql(/* graphql */`
  mutation mutateCreatePosition($record: CreateOnePositionInput!) {
    cat {
      position {
        createOne(record: $record) {
          recordId
        }
      }
    }
  }
`)

export type UseMutateCreatePositionReturn = UseMutationResult<
MutateCreatePositionMutation,
unknown,
MutateCreatePositionMutationVariables
>

const useMutateCreatePosition = (): UseMutateCreatePositionReturn => {
  return useGraphMutation(document)
}

export { useMutateCreatePosition, document as mutateCreatePositionDocument }
