import { format, formatDistance, isValid } from 'date-fns'

const yesno = (value: boolean | undefined | null): string => {
  if (value === true) {
    return 'Yes'
  } else if (value === false) {
    return 'No'
  } else {
    return ''
  }
}
type DateFormatTypes = Date | string | undefined | null

const dates = {
  system: (date: DateFormatTypes): string => {
    if (date === null || date === undefined || date === '') {
      return ''
    }
    if (!isValid(new Date(date))) {
      return 'Invalid Date'
    }
    return format(new Date(date), 'Pp')
  },
  dateOnly: (date: DateFormatTypes): string => {
    if (date === null || date === undefined || date === '') {
      return ''
    }
    if (!isValid(new Date(date))) {
      return 'Invalid Date'
    }
    return format(new Date(date), 'P')
  },
  systemDateOnly: (date: DateFormatTypes): string => {
    if (date === null || date === undefined || date === '') {
      return ''
    }
    if (!isValid(new Date(date))) {
      return 'Invalid Date'
    }
    return format(new Date(date), 'yyyy-MM-dd')
  },
  distance: (date: DateFormatTypes, baseDate: Date = new Date()): string => {
    if (date === null || date === undefined || date === '') {
      return ''
    }
    if (!isValid(new Date(date))) {
      return 'Invalid Date'
    }
    return formatDistance(new Date(date), baseDate, { addSuffix: true })
  }
}

const currency = (number: number | null | undefined): string => {
  if (number === null || number === undefined) {
    return ''
  }
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
}

export { yesno, dates, currency }
