import React from 'react'
import { Table } from '@imcva/reactstrap-table'
import Card from 'src/components/Card'
import { columns } from './columns'

interface LoadingProps {
  collapsed: boolean
  onCollapse: () => void
}

const Loading: React.FC<LoadingProps> = (props) => {
  const { collapsed, onCollapse } = props
  return (
    <Card title='Candidates' collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <Table options={{ data: [], columns }} suspend={3} />
    </Card>
  )
}

export default Loading
