import React, { useState } from 'react'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap'

import Modal from '../../../theme/Models/Modal'
import Uploading from './Uploading'
import { FooterProps } from 'src/components/theme/Models/Modal/Footer'
import useUpload from 'src/components/Attachments/modals/hooks/useUpload'

interface CreateProps {
  open: boolean
  link: string
  type: string
  model: string
  toggle: () => void
}

const Create: React.FC<CreateProps> = (props) => {
  const { open, link, type, model, toggle } = props
  const [confirm, setConfirm] = useState<boolean>(false)

  const {
    subtypes,
    canOverwrite,
    canUpload,
    error,
    fileExtension,
    fullFilename,
    hasDuplicates,
    hasFilename,
    isUploading,
    progress,
    props: formProps,
    reset,
    upload
  } = useUpload({
    link,
    type,
    model,
    onSuccess: () => {
      toggle()
      setConfirm(false)
    }
  })

  const toggleWrapper = (): void => {
    toggle()
    setConfirm(false)
    reset()
  }

  const uploadAction: FooterProps['action'] = {
    text: 'Upload',
    icon: faFileUpload,
    color: 'primary',
    disabled: !canUpload
  }
  const overwriteAction = {
    text: 'Overwrite',
    icon: faFileUpload,
    color: 'danger',
    onAction: () => setConfirm(true),
    disabled: !canOverwrite
  }

  return (
    <>
      <Modal
        isOpen={open}
        onAction={async () => await upload()}
        toggle={toggleWrapper}
        error={{
          error: 'There was an error uploading the attachment! Please Try Again.',
          details: error,
          show: error !== undefined
        }}
        cancel={{
          disabled: isUploading
        }}
        action={hasDuplicates ? overwriteAction : uploadAction}
        title='Add Attachment'
      >
        <Uploading percent={progress}>
          <Form className='text-start fw-bold'>
            <FormGroup>
              <Button {...formProps.fileButton} block color='primary'>Select File</Button>
              <Input {...formProps.file} id='attachmentFile' data-testid='attachment-input' type='file' className='d-none' />
            </FormGroup>
            <FormGroup>
              <Label for='attachmentName'>Name:</Label>
              <InputGroup>
                <Input {...formProps.name} id='attachmentName' type='text' />
                {fileExtension !== undefined && fileExtension !== '' &&
                  <InputGroupText>.{fileExtension}</InputGroupText>}
                {hasDuplicates
                  ? (
                    <FormFeedback>File already exists. Please change the file name or select overwrite to replace the document.</FormFeedback>
                    )
                  : null}
                {!hasFilename
                  ? (
                    <FormFeedback>File name is required.</FormFeedback>
                    )
                  : null}
              </InputGroup>
            </FormGroup>
            {subtypes.length > 0
              ? (
                <FormGroup>
                  <Label for='attachmentSubtype'>Type:</Label>
                  <Input {...formProps.subtype} type='select' id='attachmentSubtype'>
                    <option value={undefined}>Select a Type</option>
                    {subtypes.map(s => {
                      return (
                        <option key={s._id} value={s._id}>{s.name}</option>
                      )
                    })}
                  </Input>
                </FormGroup>
                )
              : null}
          </Form>
        </Uploading>
      </Modal>
      <Modal
        isOpen={confirm}
        toggle={() => setConfirm((c) => !c)}
        title='Are you sure?'
        error={{
          error: 'There was an error uploading the attachment! Please Try Again.',
          details: error,
          show: error !== undefined
        }}
        action={{
          text: 'Overwrite',
          color: 'danger',
          icon: faFileUpload,
          onAction: async () => {
            setConfirm(false)
            await upload(true)
          }
        }}
      >
        <p>Are you sure you want to overwrite the following attachment?</p>
        <p><b>{fullFilename}</b></p>
      </Modal>
    </>
  )
}

export default Create
export { Create }
