import React from 'react'

import { DescriptionList, Item, ItemProps } from 'src/components/DescriptionList'
import DateRange from 'src/pages/cat/contracts/Contract/cards/PoP/DateRange'
import { ContractPopById } from 'src/queries'

interface ListProps {
  contract: ContractPopById | undefined
}

const List: React.FC<ListProps> = (props) => {
  const { contract } = props
  const pop = contract?.pop ?? {}
  const section: Array<Omit<ItemProps, 'id'>> = [
    { title: 'PoP Status', value: pop.status },
    {
      title: 'Base',
      value: (
        <DateRange start={pop.base?.start} end={pop.base?.end} />
      )
    }
  ]
  if (Array.isArray(pop.options)) {
    pop.options.forEach((option, index) => (
      section.push({
        title: `Option ${index + 1}`,
        value: (
          <DateRange key={index} start={option?.start} end={option?.end} />
        )
      })
    ))
  }
  const dlProps = {
    className: 'row-cols-1',
    suspended: contract === undefined,
    titleProps: { lg: 'auto' },
    valueProps: { lg: 'auto', className: 'mb-lg-0 mb-md-1 mb-3' }
  }
  return (
    <DescriptionList {...dlProps}>
      {section.map(item => {
        const key = item.title.toLowerCase().replace(' ', '-')
        return <Item {...item} key={key} id={key} />
      })}
    </DescriptionList>
  )
}

export default List
