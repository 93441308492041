import React, { Suspense } from 'react'
import Card from '../../../../../../components/Card'
import Body from './Body'
import List from './List'

interface CreatedUpdatedProps {
  id: string
}

const CreatedUpdated: React.FC<CreatedUpdatedProps> = (props) => {
  const { id } = props
  return (
    <Card
      title='Created / Updated'
    >
      <Suspense fallback={<List candidate={undefined} />}>
        <Body id={id} />
      </Suspense>
    </Card>
  )
}

export default CreatedUpdated
