import { useEffect, useState } from 'react'
import { PositionsQueryVariables } from 'src/gql/graphql'
import { FilterValues, FiltersProps } from '.'
import { ButtonProps } from 'reactstrap'

export interface UseFilterStateResults {
  filterProps: FiltersProps
  queryFilters: PositionsQueryVariables['filter']
  buttonColor: ButtonProps['color']
}

const LOCAL_STORAGE_KEY = 'position-list-filters'

const useFilterState = (): UseFilterStateResults => {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const toggleFilters = (): void => setFiltersOpen(o => !o)
  const storage = localStorage.getItem(LOCAL_STORAGE_KEY)
  const defaultFilters: FilterValues = {
    contract: [],
    status: [],
    contract_type: []
  }
  const filterStorage = storage !== null
    ? JSON.parse(storage)
    : {}
  const [filters, setFilters] = useState<FilterValues>(
    Object.assign(defaultFilters, filterStorage)
  )
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters))
  }, [filters])
  const hasFilterValues = Object.values(filters).some((value) => value.length > 0)
  const buttonColor: ButtonProps['color'] = hasFilterValues ? 'success' : 'light'
  const queryFilters: PositionsQueryVariables['filter'] = { _operators: {} }
  if (filters?.contract.length > 0) {
    queryFilters._operators = queryFilters._operators ?? {}
    queryFilters._operators.contract = {
      in: filters.contract
    }
  }
  if (filters?.status.length > 0) {
    queryFilters._operators = queryFilters._operators ?? {}
    queryFilters._operators.status = {
      in: filters.status
    }
  }
  if (filters?.contract_type.length > 0) {
    queryFilters._operators = queryFilters._operators ?? {}
    queryFilters._operators.contract_type = {
      in: filters.contract_type
    }
  }
  const handleFilterChange: FiltersProps['onSubmit'] = (values) => setFilters(values)
  return {
    filterProps: {
      isOpen: filtersOpen,
      toggleOpen: toggleFilters,
      onSubmit: handleFilterChange,
      initialState: filters
    },
    queryFilters,
    buttonColor
  }
}

export default useFilterState
