import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Details from './cards/Details'
import RouteError from '../../../../helpers/RouteError'
import Candidates from './cards/Candidates'
import { usePositionDetailsById } from '../../../../queries'

const CandidateDetails: React.FC = (props) => {
  const params = useParams()
  const id = Number(params.id)
  if (isNaN(id)) {
    throw new RouteError('Invalid Position ID.', { status: 404 })
  }
  const position = usePositionDetailsById(id)
  if (position === null) {
    throw new RouteError(`Position "${id}" does not exist.`, { status: 404 })
  }
  return (
    <>
      <Row className='mb-4'>
        <Col md='12'>
          <Details id={id} />
        </Col>
      </Row>
      <Row className='gy-4'>
        <Col md='12'>
          <Candidates id={id} />
        </Col>
      </Row>
    </>
  )
}

export default CandidateDetails
