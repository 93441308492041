import React, { PropsWithChildren, createContext, useContext, useState, useEffect, useRef } from 'react'
import { CardProps as BSCardProps } from 'reactstrap'

export type Colors = BSCardProps['color']

export interface CardContextProps extends PropsWithChildren {
  title: string
  color?: Colors
}

export interface CardContextValue extends Omit<CardContextProps, keyof PropsWithChildren> {
  setTitle: React.Dispatch<React.SetStateAction<string>>
  setColor: React.Dispatch<React.SetStateAction<Colors>>
}

const CardContextContext = createContext<CardContextValue | undefined>(undefined)

const CardContext: React.FC<CardContextProps> = (props) => {
  const [title, setTitle] = useState(props.title)
  const [color, setColor] = useState(props.color)

  const titleRef = useRef(title)
  useEffect(() => {
    if (props.title !== titleRef.current) {
      setTitle(props.title)
    }
    titleRef.current = props.title
  }, [props.title])

  useEffect(() => {
    setColor(props.color)
  }, [props.color])

  return (
    <CardContextContext.Provider value={{ title, color, setTitle, setColor }}>
      {props.children}
    </CardContextContext.Provider>
  )
}

const useCardContext = (): CardContextValue => {
  const context = useContext(CardContextContext)
  if (context === undefined) {
    throw new Error('Cannot use card context outside of the card context provider.')
  }
  return context
}

export { useCardContext }
export default CardContext
