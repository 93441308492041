import React, { Suspense, useEffect, useRef, useState } from 'react'
import Sidemenu from './Sidemenu'
import Navbar from './Navbar'
import menu from './Sidemenu/menustructure'
import { ErrorBoundary } from 'react-error-boundary'
import ReactErrorBoundary from '../Errors/ReactErrorBoundary'
import PreloaderSplashScreen from '../PreloaderSplashScreen'
import { useLocation } from 'react-router'

interface PageProps {
  children: React.ReactNode
}

const Page: React.FC<PageProps> = (props) => {
  const [open, setOpen] = useState(false)
  const toggleOffcanvas = (): void => setOpen(s => !s)
  const location = useLocation()
  const pageRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (pageRef.current !== null) {
      pageRef.current.scrollTo(0, 0)
    }
  }, [location])

  return (
    <>
      <Sidemenu menu={menu} isOpen={open} toggleOffcanvas={toggleOffcanvas} />
      <div id='content' className='d-flex flex-column flex-grow-1 bg-light'>
        <Navbar toggleOffcanvas={toggleOffcanvas} />
        <div ref={pageRef} className='container-fluid p-3' style={{ overflow: 'scroll', height: 'calc(100vh - 65px)' }}>
          <ErrorBoundary
            fallbackRender={(props) => {
              return (
                <div className='d-flex align-items-center justify-content-center h-100'>
                  <ReactErrorBoundary {...props} />
                </div>
              )
            }}
          >
            <Suspense fallback={<PreloaderSplashScreen />}>
              {props.children}
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </>
  )
}

export default Page
